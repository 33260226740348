import Vue from 'vue';

export const state = () => ({
  treeStructure: [],
  categories: {},
});

export const utils = {
  ensureTrailingSlash(url) {
    return url.replace(/\/$|$/, '/');
  },
};

export const mutations = {
  saveCategories(state, { categories, tree }) {
    state.treeStructure = tree;
    state.categories = categories;
  },
  saveCategory(state, data) {
    Vue.set(state.categories, data.id, data);
  },
};

export const getters = {
  getHierarchicalCategories: state => {
    return state.treeStructure;
  },
  getCategory: state => id => {
    return state.categories[id];
  },
  getCategoryByUrl: state => url => {
    for (const key in state.categories) {
      if (
        utils.ensureTrailingSlash(state.categories[key].url) ===
        utils.ensureTrailingSlash(url)
      ) {
        return state.categories[key];
      }
    }
    return undefined;
  },
  getCategoryByNameOrSlug: state => val => {
    for (const key in state.categories) {
      if (
        state.categories[key].name === val ||
        state.categories[key].slug === val
      ) {
        return state.categories[key];
      }
    }

    return undefined;
  },
};

export const actions = {
  async getCategory({ commit, getters }, { id }) {
    if (!id) {
      return {
        error: true,
        msg: 'missing category id',
      };
    }
    const url = `/product-categories/${id}`;
    const api_result = await this.$axios.get(url);
    const response = api_result.data;
    if (response.error) {
      return {
        error: true,
        msg: response.msg,
      };
    }
    commit('saveCategory', response.data);
    return getters.getCategory(id);
  },
};
