import { render, staticRenderFns } from "./WordpressBanner.vue?vue&type=template&id=0a56906d&scoped=true&"
import script from "./WordpressBanner.vue?vue&type=script&lang=js&"
export * from "./WordpressBanner.vue?vue&type=script&lang=js&"
import style0 from "./WordpressBanner.vue?vue&type=style&index=0&id=0a56906d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a56906d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Media: require('/home/node/frontend/components/Media.vue').default,XLink: require('/home/node/frontend/components/XLink.vue').default})
