import Vue from 'vue';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Add a rule.
extend('timepicker', (value) => {
  let valid = true;
  value.split(/[ :]+/).forEach(part => {
    if (part === 'hh' || part === 'mm' || part === 'A') {
      valid = false;
    }
  });
  if (valid) {
    return true;
  }
  return 'This is not a valid time format';
});

const isDeliveryValid = (value, [ { isValid, errorMsg } ]) => {
  if (!value || isValid) {
    return true;
  }
  return isValid ? "" : errorMsg;
};

extend('craneValidation', isDeliveryValid);

// Checks to see if ABN is valid by checking if there was an error returned
const isABNValid = (value, [ { isValid, errorMsg } ]) => {
  return isValid ? true : errorMsg;
};

extend('abnValidation', isABNValid);

const isValidSelectedDate = (value, [ { disabledDates } ]) => {

  const to = disabledDates.to;
  const dates = disabledDates.dates;
  const ranges = disabledDates.ranges;
  const dateToCompare = new Date(new Date(value).toDateString());

  if (to && value <= to) {
    return 'Invalid date selected';
  } else if (
    dates &&
    dates.length &&
    dates.find(d => d.toDateString() === value.toDateString())
  ) {
    return 'Invalid date selected';
  } else if (
    ranges &&
    ranges.length &&
    ranges.find(
      r => new Date(new Date(r.from).toDateString()) >= dateToCompare && new Date(new Date(r.to).toDateString()) <= dateToCompare
    )
  ) {
    return 'Invalid date selected';
  }

  return true;
};

extend('disabledDateValidation', isValidSelectedDate);

extend('phone', (value) => {
  let validPhonePrefix =
    [ '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09' ];
  let isPhoneNumber = validPhonePrefix.includes(value.slice(0, 2));
  return isPhoneNumber ? true : 'Must start with 02 to 09';
});

extend('mobilePhone', (value) => {
  let valueClean = value.replace(/\s*/g, "");
  let isMobileNumber = /^04\d{8}$/.test(valueClean);
  return isMobileNumber ? true : 'Must start with 04 and be 10 digits long';
});

extend('password', {
  params: [ 'target' ],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});
