import Vue from 'vue';
export const makeUrl = (base, source, id, filename) => {
  return `${base}/media/${source}/${id}/${filename}`;
};
export const makeQueryKey = ({ source, id, format }) => {
  return `${source}:${id}${format ? `:${format}` : ''}`;
};
export const makeQuery = function(items) {
  // example item: { source: 'source', id: 'id', format: 'format' }
  // filter out items where id is null or false or undefined
  const validItems = Object.values(items).filter(item => item.id);
  // map the items to a query key
  return JSON.stringify(
    Object.values(validItems).map(item => makeQueryKey(item))
  );
};
export const buildMediaId = queryStr => {
  const [source, id, format] = queryStr.split(':');
  return {
    source,
    id,
    format,
  };
};
export const buildMediaData = (base, queryStr, mediaData, timestamp) => {
  const { source, id, format } = buildMediaId(queryStr);
  if (!source || !id || !mediaData) {
    return null;
  }
  try {
    const data = {
      timestamp: timestamp,
      source,
      id,
      format: format || 'default',
      mimeType: mediaData.info.file.mime,
      meta: mediaData.info.meta || {},
      type: mediaData.files.type,
    };
    switch (mediaData.files.type) {
      case 'image':
        data.placeholder = makeUrl(
          base,
          source,
          id,
          mediaData.files.placeholder
        );
        data.default = makeUrl(base, source, id, mediaData.files.default);
        data.width = mediaData.files.width;
        data.height = mediaData.files.height;
        data.formats = mediaData.files.formats.map(format => ({
          ext: format.ext,
          sizes: format.sizes.map(([deviceWidth, imageWidth]) => [
            deviceWidth,
            imageWidth,
            makeUrl(base, source, id, `${imageWidth}.${format.ext}`),
          ]),
        }));
        break;
      case 'svg':
        data.svg = mediaData.files.svg;
        break;
      case 'file':
        data.file = makeUrl(base, source, id, mediaData.files.file);
        break;
      default:
        return null;
    }
    return data;
  } catch (e) {
    console.warn('invalid media data', e);
    console.warn('attempted to process:', mediaData);
    return null;
  }
};
/* Super hacky method to do bulk requests. */
export const state = () => ({
  mediaCount: 0,
  mediaItems: {},
  promises: {},
  timeout: null,
  items: {},
});
export const mutations = {
  addMedia(state, data) {
    if (!data || !data.id) {
      return;
    }
    const fullId = `${data.source}:${data.id}:${data.format || 'default'}`;
    Vue.set(state.items, fullId, data);
    if (state.promises[data.id]) {
      for (const resolve of state.promises[data.id]) {
        resolve();
      }
      delete state.promises[data.id];
    }
  },
  clearQueue(state) {
    state.mediaCount = 0;
    state.mediaItems = {};
    state.timeout = null;
  },
  addToQueue(state, { id, data }) {
    state.mediaCount++;
    state.mediaItems[id] = data;
  },
  addPromise(state, { id, resolve }) {
    if (!state.promises[id]) {
      state.promises[id] = [];
    }
    state.promises[id].push(resolve);
  },
  clearPromises(state) {
    state.promises = {};
  },
  updateTimeout(state, timeout) {
    clearTimeout(state.timeout);
    state.timeout = timeout;
  },
};
export const getters = {
  getMedia: state => ({ source, id, format }) => {
    const fullId = `${source}:${id}:${format || 'default'}`;
    let media = state.items[fullId];
    if (media === undefined) {
      return null;
    }
    return media;
  },
};
export const actions = {
  async getMedia({ getters, dispatch, rootState }, { source, id, format }) {
    let media = getters.getMedia({
      source: source,
      id: id,
      format: format,
    });
    if (media && media.timestamp + rootState.cacheTime >= Date.now()) {
      return media;
    }
    return await dispatch('fetchMedia', {
      source,
      id,
      format,
    });
  },
  async fetchMedia({ state, getters, commit, dispatch }, params) {
    commit('addToQueue', {
      id: params.id,
      data: params,
    });
    let resolve;
    const promise = new Promise(_resolve => {
      resolve = _resolve;
    });
    commit('addPromise', {
      id: params.id,
      resolve: resolve,
    });
    if (state.mediaCount >= 40) {
      dispatch('runMediaRequest');
    }
    commit(
      'updateTimeout',
      setTimeout(() => {
        dispatch('runMediaRequest');
      }, 50)
    );
    await promise;
    let media = getters.getMedia(params);
    return media;
  },
  async runMediaRequest({ state, commit, rootState }) {
    clearTimeout(state.timeout);
    if (Object.keys(state.mediaItems).includes('false')) {
      delete state.mediaItems['false'];
    }
    if (Object.keys(state.mediaItems).length < 1) {
      return;
    }

    const query = makeQuery(state.mediaItems);
    if (!query || query.includes('cms:false:default')) {
      return;
    }

    let url = `/media/details?query=${query}`;
    commit('clearQueue');

    try {
      let result = await this.$axios.get(url);
      let response = result.data;

      if (!response.error && response.data) {
        const now = Date.now();
        const responseDataEntries = Object.entries(response.data);
        if (responseDataEntries.length === 0) {
          throw new Error('No media data returned');
        }

        responseDataEntries.forEach(([key, mediaData]) => {
          if (!mediaData || !mediaData.files) {
            return;
          }

          commit('addMedia', {
            ...buildMediaId(key),
            ...buildMediaData(rootState.storageBase, key, mediaData, now),
          });
        });

        return true;
      }

      commit('clearPromises');
      return false;
    } catch (e) {
      console.error('error fetching media details', e);
      commit('clearPromises');
      return false;
    }
  },
};
