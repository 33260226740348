import { mapGetters, mapActions } from 'vuex';

import product from '~/mixins/product';
import translate from '~/mixins/translate';

export default {
  mixins: [product, translate],
  data() {
    return {
      cartQty: undefined,
      cartUnitPrice: undefined,
      productList: [],
    };
  },
  computed: {
    ...mapGetters({
      getCategoryById: 'category/getCategory',
      getAttributeLabel: 'productsMeta/getAttributeName',
      getCartItemById: 'cart/getItemById',
      getCustomer: 'users/getCustomer',
    }),
    getPathName() {
      let pathName = window.location.pathname;

      if (location.search) {
        pathName += decodeURIComponent(location.search);
      }

      if (location.hash) {
        pathName += location.hash;
      }

      return pathName;
    },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getProductById',
      loadCategory: 'category/getCategory',
    }),
    triggerGTMPurchase(orderDetails, products) {
      const items = [];
      products.forEach(product => {
        const item = this.mapGA4item(product);
        items.push(item);
      });

      this.$gtm.push({
        event: 'eec.purchase',
        ecommerce: {
          purchase: {
            actionField: { ...orderDetails },
            products,
          },
          items,
          value: Number(orderDetails.revenue),
          tax: Number(orderDetails.tax),
          shipping: Number(orderDetails.shipping),
          transaction_id: orderDetails.id,
        },
      });
    },
    triggerProdCardsImpressionView(products) {
      this.$gtm.push({
        event: 'eec.impressionView',
        ecommerce: {
          currencyCode: 'AUD',
          impressions: products,
        },
      });
    },
    forceOptimizeActive() {
      this.$gtm.push({
        event: 'optimize.activate',
      });
    },
    pushToDataLayer(data) {
      // only push object to datalayer
      if (typeof data !== 'object') {
        return;
      }

      this.$gtm.push(data);
    },
    createUserDimensions(customerId, contactId) {
      const customer = this.getCustomer;

      if (Object.keys(customer).length === 0) {
        return;
      }

      let custType = '';
      if (customer.creditLimit && customer.creditLimit > 0) {
        custType = 'Trade with credit';
      } else if (customer.typeAcctAr === 'A') {
        custType = 'Trade';
      } else if (customer.typeAcctAr === 'C') {
        custType = 'Cash';
      }

      return {
        dimension3: customerId,
        dimension4: contactId,
        dimension5: `${customerId}-${contactId}`,
        dimension6: custType,
      };
    },
    AddRefreshUserSessionEvent(customerId, contactId) {
      const dimensions = this.createUserDimensions(customerId, contactId);
      const newEvent = {
        event: 'RefreshUserSession',
        ...dimensions,
      };

      this.$gtm.push(newEvent);
    },
    triggerGTMLogin(customerId, contactId) {
      const dimensions = this.createUserDimensions(customerId, contactId);
      const newEvent = {
        event: 'Login',
        ...dimensions,
      };

      this.$gtm.push(newEvent);
    },
    async generateGTMProductCart(cartItems) {
      const items = cartItems;
      const productCalls = [];

      for (const item in items) {
        if (!item) {
          continue;
        }

        productCalls.push(
          await this.getProductById({
            id: items[item].productId,
          })
        );
      }

      const products = await Promise.all(productCalls);
      const productsResult = [];

      for (const item in items) {
        if (!item) {
          continue;
        }

        const itemDetails = items[item];
        const product = products.find(p => p.id === itemDetails.productId);
        const category = await this.getCategoryPDP(product.categories[0], '');
        const attributes = this.generateProductAttributes(itemDetails);
        const uom = {
          key: 'uom',
          value: itemDetails.uom,
          label: this.$uom(
            itemDetails.uom.toUpperCase(),
            'selector',
            itemDetails.conversionFactor,
            {}
          ),
        };
        const variant = this.getVariant([...attributes, uom]);

        productsResult.push({
          name: product.content.title,
          id: itemDetails.erpId,
          category,
          variant,
          quantity: this.getQuantity(itemDetails.qty),
          price: itemDetails.pricing.unitPrice.toString(),
        });
      }

      return productsResult;
    },
    generateProductAttributes(item) {
      const attributes = [];

      if (item.variation) {
        for (const key in item.variation.attributes) {
          if (!key) {
            continue;
          }

          const itemAttr = item.variation.attributes[key];
          attributes.push({
            key,
            value: itemAttr,
            label: this.setPrefixSuffix(key, itemAttr),
          });
        }
      }

      return attributes;
    },
    async gtmCartUpdate(action, details, previousQty) {
      let currentQty = previousQty ? previousQty : this.cartQty;
      const list = this.getPathName;
      const category = await this.getCategoryPDP(details.categories, '');
      const variant = details.erpId
        ? this.getVariant([...details.attributes, details.uom])
        : '';
      const price =
        details && details.pricing
          ? details.pricing.unitPrice.toString()
          : undefined;

      const itemDetails = {
        name: details.name,
        id: details.erpId,
        category,
        variant,
        quantity: details.qty,
        price,
      };

      let gtmAction = action;
      let eventQty = details.qty;
      if (action === 'update') {
        if (details.qty === currentQty) return;
        gtmAction = currentQty < details.qty ? 'add' : 'remove';
        if (gtmAction === 'add') {
          eventQty = Math.abs(details.qty - currentQty);
        } else {
          eventQty = Math.abs(currentQty - details.qty);
        }
      }

      itemDetails.quantity = this.getQuantity(eventQty);
      this.gtmPushCart(gtmAction, list, itemDetails);
    },
    mapGA4item(product) {
      return {
        item_id: product.id || null,
        item_name: product.name,
        price: Number(product.price),
        quantity: Number(product.quantity),
        item_category: product.category,
        item_variant: product.variant,
      };
    },
    gtmPushCart(action, list, itemDetails) {
      const product = {
        name: itemDetails.name,
        id: itemDetails.id,
        category: itemDetails.category,
        variant: itemDetails.variant,
        quantity: itemDetails.quantity,
        price: itemDetails.price,
      };

      const item = this.mapGA4item(product);
      const value = item.quantity * item.price;

      const ecommerce = {
        items: [item],
        value: Math.round(Number(value) * 100) / 100,
      };

      ecommerce[action] = {
        actionField: {
          list,
        },
        products: [product],
      };

      this.$gtm.push({
        event: `eec.${action}`,
        ecommerce,
      });
    },
    updateGTMCartDataPDP(erpId, attributes) {
      let id = erpId;

      for (const attribute of attributes) {
        if (attribute.value) {
          const value =
            attribute.key === 'uom'
              ? attribute.value.substring(0, attribute.value.indexOf('|'))
              : attribute.value;

          id += '_' + value;
        }
      }

      id = id.toLowerCase();

      const item = this.getCartItemById(id);

      if (item && item.data) {
        this.cartQty = this.getQuantity(item.data.pricing.qty);
        this.cartUnitPrice = item.data.pricing.unitPrice;
      } else {
        this.cartQty = undefined;
        this.cartUnitPrice = undefined;
      }
    },
    updateGTMCartData(qty, unitPrice) {
      this.cartQty = this.getQuantity(qty);
      this.cartUnitPrice = unitPrice;
    },
    getQuantity(qty) {
      let updatedQty = Math.floor(qty);

      if (updatedQty === 0) {
        updatedQty = 1;
      }

      return updatedQty;
    },
    getVariant(attributes) {
      let variant = '';

      for (const attribute of attributes) {
        if (attribute.value) {
          const label =
            attribute.key === 'uom'
              ? 'UOM'
              : this.getAttributeLabel(attribute.key);
          const value = attribute.label;

          variant += ` ${label}: ${value},`;
        }
      }

      variant = variant.slice(0, -1);
      return variant.trim();
    },
    async getCategory(categories) {
      let category;

      if (Array.isArray(categories)) {
        category = await this.getCategoryPDP(categories[0], '');
      } else {
        category = this.getCategoryAlgolia(categories);
      }

      return category;
    },
    async getCategoryPDP(categoryId, pCategoryName) {
      let categoryName;
      let categoryData = this.getCategoryById(categoryId);
      if (!categoryData) {
        categoryData = await this.loadCategory(categoryId);
      }

      if (pCategoryName && categoryData?.name) {
        categoryName = `${categoryData.name} > ${pCategoryName}`;
      } else {
        categoryName = categoryData.name;
      }

      if (categoryData.parentId) {
        categoryName = await this.getCategoryPDP(
          categoryData.parentId,
          categoryName
        );
      }

      return categoryName;
    },
    getCategoryAlgolia(categories) {
      let category;

      for (let x = 5; x > 0; x--) {
        if (categories[`level${x}`].length) {
          category = categories[`level${x}`][0];
          break;
        }
      }

      return category;
    },
    gtmPushCheckout(step, option, products) {
      let ecommerce = {};
      let event;

      if (products) {
        const items = [];
        let subtotal = 0;
        products.forEach(product => {
          const item = this.mapGA4item(product);
          subtotal += Number(item.price * item.quantity);
          items.push(item);
        });

        ecommerce = {
          checkout: {
            actionField: {
              step,
              option,
            },
            products,
          },
          items,
          value: Math.round(subtotal * 100) / 100,
        };

        event = 'eec.checkout';
      } else {
        ecommerce = {
          checkout_option: {
            actionField: {
              step,
              option,
            },
          },
        };

        event = 'eec.checkoutOption';
      }

      this.$gtm.push({
        event,
        ecommerce,
      });
    },
  },
  mounted() {
    if (window && window.dataLayer) {
      // window.dataLayer is an array of objects
      // IF a dataLayer item has a property called "isUsingAppMode" THEN do something
      // ELSE return undefined
      const hasVariable = window.dataLayer.find(
        item => item.isUsingAppMode !== undefined
      );

      if (!hasVariable) {
        const isUsingAppMode =
          window?.navigator?.standalone ||
          window?.matchMedia('(display-mode: standalone)').matches;

        this.pushToDataLayer({ isUsingAppMode });
      }
    }
  },
};
