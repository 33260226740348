export const state = () => ({
  heremapsApiKey: '',
  googleMapKey: '',
  storageBase: '',
  cacheTime: 0,
  storeExpiry: 0
});

export const mutations = {
  heremapsApiKey(state, val) {
    state.heremapsApiKey = val;
  },
  googleMapKey(state, val) {
    state.googleMapKey = val;
  },
  storageBase(state, val) {
    state.storageBase = val;
  },
  cacheTime(state, val) {
    state.cacheTime = val;
  },
  storeExpiry(state, val) {
    state.storeExpiry = val;
  }
};

export const actions = {
  nuxtServerInit({ commit }, { $config }) {
    commit('heremapsApiKey', $config.heremapsApiKey);
    commit('googleMapKey', $config.googleMapKey);
    commit('storageBase', $config.storageBase);
    commit('cacheTime', $config.cacheTime);
    commit('storeExpiry', $config.storeExpiry);
  },
  nuxtClientInit({ commit }, { $config }) {
    commit('heremapsApiKey', $config.heremapsApiKey);
    commit('googleMapKey', $config.googleMapKey);
    commit('storageBase', $config.storageBase);
    commit('cacheTime', $config.cacheTime);
    commit('storeExpiry', $config.storeExpiry);
  }
};
