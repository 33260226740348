export default ({ store }) => {
  window.onNuxtReady(async () => {
    try {
      await store.dispatch('settings/getMaintenanceModeSettings');

      setInterval(async () => {
        await store.dispatch('settings/getMaintenanceModeSettings');
      }, 5 * 60 * 1000);
    } catch (e) {
      console.log(e);
    }
  });
};
