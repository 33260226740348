import { render, staticRenderFns } from "./PageHeaderCart.vue?vue&type=template&id=6c6f249e&scoped=true&"
import script from "./PageHeaderCart.vue?vue&type=script&lang=js&"
export * from "./PageHeaderCart.vue?vue&type=script&lang=js&"
import style0 from "./PageHeaderCart.vue?vue&type=style&index=0&id=6c6f249e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6f249e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonNotification: require('/home/node/frontend/components/includes/ButtonNotification.vue').default,ChrButton: require('/home/node/frontend/components/includes/ChrButton.vue').default})
