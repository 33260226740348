import scssVariables from '../node_modules/@bowenstimber/designsystem/scss/_variables.scss';

Object.keys(scssVariables).forEach((key) => {
  let value = scssVariables[key];
  if (!Number.isNaN(value)) {//Its a number make it a number
    value = parseFloat(value);
  } else if (value.substr(value.length -2) === 'px') { //Value in px make it number
    value = value.substr(0, value.length - 2);
    value = parseFloat(value);
  }
  scssVariables[key] = value;
});

export default scssVariables;
