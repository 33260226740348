//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const OVERLAY_SESSION_VARIABLE = 'bowensAppInstallPreference';
import CloseIcon from '~/static/svg/cross-blue.svg';
import BowensLogoSvg from '~/static/svg/Bowens_logo.svg';
import OptionsIconSvg from '~/static/svg/install-icons_options.svg';
import AddIconSvg from '~/static/svg/install-icons_add.svg';
import PlusIconSvg from '~/static/svg/install-icons_plus.svg';
import NoOneIcon from '~/static/svg/install-icons_no-1.svg';
import NoTwoIcon from '~/static/svg/install-icons_no-2.svg';
import NoThreeIcon from '~/static/svg/install-icons_no-3.svg';

export default {
  components: {
    CloseIcon,
    BowensLogoSvg,
    OptionsIconSvg,
    AddIconSvg,
    PlusIconSvg,
    NoOneIcon,
    NoTwoIcon,
    NoThreeIcon,
  },
  computed: {
    isAppEnabled() {
      return this.$config.featureToggle.toggles.enableAppInstallation ?? false;
    },
    isUIEnabled() {
      return this.$config.featureToggle.toggles.enableAppInstallUI ?? false;
    },
    isModalOpen() {
      return this.showModal;
    },
    isIOSinstructionsOpen() {
      return this.showIOSInstructionsModal;
    },
  },
  data() {
    return {
      showModal: false,
      showIOSInstructionsModal: false,
      deferredPrompt: undefined,
    };
  },
  head() {
    if (!this.isAppEnabled) return {};
    return {
      link: [
        {
          rel: 'manifest',
          href: '/favicon/manifest.json',
        },
      ],
      meta: [
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'default',
        },
        {
          name: 'theme-color',
          content: '#154c97',
        },
      ],
    };
  },
  methods: {
    openModal() {
      if (this.isAppEnabled && this.isUIEnabled) {
        this.showModal = true;
      }
    },
    hasPreference() {
      return localStorage.getItem(OVERLAY_SESSION_VARIABLE);
    },
    chooseYes() {
      if (window.navigator.standalone) {
        console.log('!!! you have already installed this app !!!');
        return;
      }

      // iOS devices dont support the prompt functionality
      // show user how-to modal for "adding to homescreen"
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        // Display instructions for adding to home screen on iOS
        this.showIOSInstructionsModal = true;
        return;
      }

      // Check if the deferredPrompt is available
      if (this.deferredPrompt) {
        // Show the installation prompt
        this.deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }

          localStorage.setItem(OVERLAY_SESSION_VARIABLE, 'true');
          // Reset the deferredPrompt variable
          this.deferredPrompt = null;
          this.showModal = false;
        });
      }
    },
    closeModal() {
      // User Doesnt Want to Download; Hide Modal and Set Preference in LocalStorage
      // localStorage.setItem(OVERLAY_SESSION_VARIABLE, 'true');
      this.showIOSInstructionsModal = false;
    },
    handlePrompt(event) {
      // Prevent the default behavior
      event.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = event;
      // Show the button or take other actions
      // this.openModal();
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt', this.handlePrompt);
  },
  mounted() {
    if (this.isAppEnabled && !window.navigator.standalone) {
      window.addEventListener('beforeinstallprompt', this.handlePrompt);
    }

    // If user is viewing frontpage, and "install-app" query parameter exists, trigger chooseYes()
    // should only trigger on frontpage
    if (this.$route.query['qrcode'] === 'bowens' && this.$route.path === '/') {
      this.chooseYes();
      this.$emit('openMenu');
    }
  },
};
