import algoliasearch from 'algoliasearch';

export const state = () => ({
  term: '',
});

export const api = {
  async retrieveFacets(client, index) {
    const searchIndex = client.initIndex(index);
    let response;

    try {
      response = await searchIndex.search('', {
        facets: ['*'],
      });
    } catch (e) {
      console.error(e);
      return undefined;
    }

    const facets = response.facets;

    return facets;
  },
};

export const mutations = {
  setCurrentSearchTerm(state, term) {
    state.term = term;
  },
};

export const getters = {
  getCurrentSearchTerm: state => {
    return state.term;
  },
};

export const actions = {
  async getAlgoliaFacets(_, { applicationId, key, index }) {
    if (!applicationId || !key || !index) {
      return undefined;
    }

    const client = algoliasearch(applicationId, key);

    return await api.retrieveFacets(client, index);
  },
  getAlgoliaSearchClient(_, { applicationId, key }) {
    if (!applicationId || !key) {
      return undefined;
    }

    const client = algoliasearch(applicationId, key);

    return client;
  },
  getAlgoliaIndex(_, index) {
    if (!index) {
      return undefined;
    }

    return index;
  },
  getHitsPerPage(_, hitsPerPage) {
    return hitsPerPage || 24;
  },
};
