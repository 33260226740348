const AT = '25.2744,133.7751'; // Straya
const LIMIT = 5;
const LANG = 'en';
const IN = 'countryCode:AUS'; // https://unstats.un.org/unsd/tradekb/knowledgebase/country-code

export const util = {
  addStringAtPosition(text, index, string) {
    return text.slice(0, index) + string + text.slice(index);
  }
};

export const api = {
  async getAutosuggest($axios, apiKey, query) {

    /**
     * https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-autosuggest-brief.html
     */
    const AUTOSUGGEST_URL =
      'https://autosuggest.search.hereapi.com/v1/autosuggest';
    let response;

    try {
      response = await $axios.get(AUTOSUGGEST_URL, {
        params: {
          limit: LIMIT,
          lang: LANG,
          at: AT,
          q: query,
          apikey: apiKey
        }
      });
    } catch (e) {
      throw new Error(e);
    }

    if (!response) {
      return [];
    }

    const data = response.data;

    if (!data) {
      return [];
    }

    let results = data.items;

    if (!results) {
      return [];
    }

    return results;
  },
  async getGeocode($axios, apiKey, query) {

    /**
     * https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics-api/code-geocode-examples.html
     */
    const GEOCODE_URL = 'https://geocode.search.hereapi.com/v1/geocode';
    let response;

    try {
      response = await $axios.get(GEOCODE_URL, {
        params: {
          limit: LIMIT,
          lang: LANG,
          q: query,
          apikey: apiKey,
          in: IN
        }
      });
    } catch (e) {
      throw new Error(e);
    }

    if (!response) {
      return [];
    }

    const data = response.data;

    if (!data) {
      return [];
    }

    let results = data.items;

    if (!results) {
      return [];
    }

    return results;
  },
  async getLookup($axios, apiKey, query) {

    /**
     * https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-lookup-brief.html
     */
    const GEOCODE_URL = 'https://lookup.search.hereapi.com/v1/lookup';
    let response;

    try {
      response = await $axios.get(GEOCODE_URL, {
        params: {
          limit: LIMIT,
          lang: LANG,
          q: query,
          apikey: apiKey
        }
      });
    } catch (e) {
      throw new Error(e);
    }

    if (!response) {
      return [];
    }

    const data = response.data;

    if (!data) {
      return [];
    }

    let results = data.items;

    if (!results) {
      return [];
    }

    return results;
  },
  async getDiscover($axios, apiKey, query) {

    /**
     * https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-discover-brief.html
     */
    const GEOCODE_URL = 'https://discover.search.hereapi.com/v1/discover';
    let response;

    try {
      response = await $axios.get(GEOCODE_URL, {
        params: {
          limit: LIMIT,
          lang: LANG,
          q: query,
          at: AT,
          apikey: apiKey
        }
      });
    } catch (e) {
      throw new Error(e);
    }

    if (!response) {
      return [];
    }

    const data = response.data;

    if (!data) {
      return [];
    }

    let results = data.items;

    if (!results) {
      return [];
    }

    return results;
  }
};

export const actions = {
  async getAutoSuggestions({ rootState: { heremapsApiKey } }, { query }) {
    let results = await api.getAutosuggest(this.$axios, heremapsApiKey, query);
    return results;
  },
  async getGeocode({ rootState: { heremapsApiKey } }, { query }) {
    let results = await api.getGeocode(this.$axios, heremapsApiKey, query);
    return results;
  },
  async getLookup({ rootState: { heremapsApiKey } }, { id }) {
    let results = await api.getLookup(this.$axios, heremapsApiKey, id);
    return results;
  },
  async getDiscover({ rootState: { heremapsApiKey } }, { query }) {
    let results = await api.getDiscover(this.$axios, heremapsApiKey, query);
    return results;
  }
};
