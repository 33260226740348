import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getAttributeName: 'productsMeta/getAttributeName',
      getAttributeBySlug: 'productsMeta/getAttributeBySlug',
    }),
  },
  methods: {
    setPrefixSuffix(attr, val) {
      const metaAttribute = this.getAttributeBySlug(attr);
  
      return metaAttribute
        ? `${metaAttribute.prefix}${val}${metaAttribute.suffix}`
        : val;
    },
  }
};