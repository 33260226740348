import Vue from 'vue';

export const state = () => ({
  urls: {},
});

export const mutations = {
  add(state, data) {
    Vue.set(state.urls, data.url, data);
  },
  syncFormStorage(state, data) {
    Vue.set(state, 'urls', data);
  },
};

export const getters = {
  getPage: (state) => (url) => {
    return state.urls[url] ? state.urls[url] : null;
  }
};
