//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap';
import { mapGetters } from 'vuex';
import ArrowRight from '~/static/svg/arrow-right.svg';
import AdhesivesSealantsFillers from '~/static/svg/adhesives-sealants-fillers.svg';
import BuildingEssentials from '~/static/svg/building-essentials.svg';
import Cladding from '~/static/svg/cladding.svg';
import Clearance from '~/static/svg/clearance.svg';
import Decking from '~/static/svg/decking.svg';
import DoorWindowHardware from '~/static/svg/door-window-hardware.svg';
import DoorsJambsFrames from '~/static/svg/doors-jambs-frames.svg';
import Fasteners from '~/static/svg/fasteners.svg';
import Flooring from '~/static/svg/flooring.svg';
import FoundationFormwork from '~/static/svg/foundation-formwork.svg';
import FramingTimber from '~/static/svg/framing-timber.svg';
import GeneralHardware from '~/static/svg/general-hardware.svg';
import IndoorTimber from '~/static/svg/indoor-timber.svg';
import InteriorLining from '~/static/svg/interior-lining.svg';
import OutdoorTimber from '~/static/svg/outdoor-timber.svg';
import PaintsStains from '~/static/svg/paints-stains.svg';
import PlumbingBathroom from '~/static/svg/plumbing-bathroom.svg';
import SafetyWorkwear from '~/static/svg/safety-workwear.svg';
import Sheeting from '~/static/svg/sheeting.svg';
import Tools from '~/static/svg/tools.svg';
import PlaceHolderIcon from '~/static/svg/placeholder-icon.svg';
import ExpoSpecialsIcon from '~/static/svg/expo-specials-icon.svg';
import HomeGardenProducts from '~/static/svg/home-garden-products.svg';
import Timber from '~/static/svg/timber.svg';
import Roofing from '~/static/svg/roofing.svg';
import BulkBuys from '~/static/svg/bulk_buy_new.svg'

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { MEGA_MENU } from '~/util/configSettings';
import InstallModal from '~/components/install-app/InstallModal.vue';

export default {
  name: 'mobile-menu',
  components: {
    ArrowRight,
    'adhesives-sealants-fillers': AdhesivesSealantsFillers,
    'building-essentials': BuildingEssentials,
    cladding: Cladding,
    clearance: Clearance,
    decking: Decking,
    'door-window-hardware': DoorWindowHardware,
    'doors-jambs-frames': DoorsJambsFrames,
    fasteners: Fasteners,
    flooring: Flooring,
    'foundation-formwork': FoundationFormwork,
    'framing-timber': FramingTimber,
    'general-hardware': GeneralHardware,
    'indoor-timber': IndoorTimber,
    'interior-lining': InteriorLining,
    'outdoor-timber': OutdoorTimber,
    'paints-stains': PaintsStains,
    'plumbing-bathroom': PlumbingBathroom,
    'safety-workwear': SafetyWorkwear,
    sheeting: Sheeting,
    'expo-specials': ExpoSpecialsIcon,
    tools: Tools,
    'placeholder-icon': PlaceHolderIcon,
    'home-garden-products': HomeGardenProducts,
    timber: Timber,
    roofing: Roofing,
    'bulk-buys': BulkBuys,
    InstallModal,
  },
  props: {
    menu: {
      type: Array,
    },
    showMenu: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeCat: false,
      activeChildCat: false,
      activeSubChildCat: false,
    };
  },
  computed: {
    ...mapGetters({
      categories: 'category/getHierarchicalCategories',
    }),
  },
  watch: {
    showMenu(val) {
      if (val) {
        this.$refs['mobilemenu'].classList.add('mobile-menu--open');
        disableBodyScroll(this.elem);
      } else {
        this.removeActiveState();
      }
    },
  },
  mounted() {
    disableBodyScroll(this.$el.querySelector('.sidear-menu'), {
      reserveScrollBarGap: true,
    });
  },
  methods: {
    openMenu() {
      this.$emit('openMenu');
    },
    handleClickMenuItem(item, index) {
      if (item.children) {
        this.toggleDropDown(index);
      } else if (item.custom_class === MEGA_MENU) {
        this.toggleFirstLevelCategories(true);
      } else {
        this.dismissMenu();
      }
    },
    isSvgExist(slug) {
      if (this.$options.components[slug]) {
        return true;
      }
      return false;
    },
    handleClickChildrenItem(item, index) {
      if (item.children) {
        this.toggleDropDown(index);
      } else if (item.custom_class === MEGA_MENU) {
        this.toggleThirdLevelCategories(true);
      } else {
        this.dismissMenu();
      }
    },
    removeActiveState() {
      this.$refs['mobilemenu'].classList.remove('mobile-menu--open');
      this.$refs['firstLevelCat'].classList.remove('first-level--open');
      this.$refs['secondLevelCat'].classList.remove('second-level--open');
      this.$refs['thirdLevelCat'].classList.remove('third-level--open');
      this.$refs['fourthLevelCat'].classList.remove('fourth-level--open');
    },
    toggleDropDown(index) {
      const dropdown = this.$refs['dropdown' + index][0];
      dropdown.classList.toggle('mobile-menu__dropdown--open');
      if (dropdown.classList.contains('mobile-menu__dropdown--open')) {
        gsap.to(dropdown, {
          height: dropdown.scrollHeight,
          ease: 'power3.inOut',
          duration: 0.3,
          delay: 0,
        });
      } else {
        gsap.to(dropdown, {
          padding: '0',
          height: 0,
          ease: 'power3.inOut',
          duration: 0.3,
          delay: 0,
        });
      }
    },
    dismissMenu() {
      this.$emit('dismiss');
      clearAllBodyScrollLocks();
    },
    toggleFirstLevelCategories(isOpen) {
      clearAllBodyScrollLocks();
      if (isOpen) {
        disableBodyScroll(
          this.$el.querySelector(
            '.first-level .product-category-dropdown__inner'
          )
        );
        this.$refs['firstLevelCat'].classList.add('first-level--open');
      } else {
        disableBodyScroll(this.$el.querySelector('.sidear-menu'));
        this.elem = this.$el.querySelector('.sidebar-menu');
        this.$refs['firstLevelCat'].classList.remove('first-level--open');
      }
    },
    dismissSecondLevelLayer() {
      clearAllBodyScrollLocks();
      disableBodyScroll(
        this.$el.querySelector('.first-level .product-category-dropdown__inner')
      );
      this.$refs['secondLevelCat'].classList.remove('second-level--open');
    },
    toggleSecondLevelCategories(cat) {
      if (cat.items && cat.items.length > 0) {
        clearAllBodyScrollLocks();
        disableBodyScroll(
          this.$el.querySelector(
            '.second-level .product-category-dropdown__inner'
          )
        );
        this.activeCat = cat;
        this.$refs['secondLevelCat'].classList.toggle('second-level--open');
      } else {
        let categoryRoute = cat.url || '';
        if (!categoryRoute) categoryRoute = '/c/' + cat.slug;

        this.$router.push(categoryRoute);
        this.dismissMenu();
      }
    },
    dismissThirdLevelLayer() {
      clearAllBodyScrollLocks();
      disableBodyScroll(
        this.$el.querySelector(
          '.second-level .product-category-dropdown__inner'
        )
      );
      this.$refs['thirdLevelCat'].classList.remove('third-level--open');
    },
    toggleThirdLevelCategories(cat) {
      if (cat.items && cat.items.length > 0) {
        clearAllBodyScrollLocks();
        disableBodyScroll(
          this.$el.querySelector(
            '.third-level .product-category-dropdown__inner'
          )
        );
        this.activeChildCat = cat;
        this.$refs['thirdLevelCat'].classList.toggle('third-level--open');
      } else {
        let categoryRoute = cat.url || null;
        if (!categoryRoute) categoryRoute = '/c/' + cat.slug;

        this.$router.push(categoryRoute);
        this.dismissMenu();
      }
    },
    dismissFourthLevelLayer() {
      clearAllBodyScrollLocks();
      disableBodyScroll(
        this.$el.querySelector('.third-level .product-category-dropdown__inner')
      );
      this.$refs['fourthLevelCat'].classList.remove('fourth-level--open');
    },
    toggleFourthLevelCategories(cat) {
      if (cat.items && cat.items.length > 0) {
        clearAllBodyScrollLocks();
        disableBodyScroll(
          this.$el.querySelector(
            '.fourth-level .product-category-dropdown__inner'
          )
        );
        this.activeSubChildCat = cat;
        this.$refs['fourthLevelCat'].classList.toggle('fourth-level--open');
      } else {
        let categoryRoute = cat.url || null;
        if (!categoryRoute) categoryRoute = '/c/' + cat.slug;

        this.$router.push(categoryRoute);
        this.dismissMenu();
      }
    },
  },
};
