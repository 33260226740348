const USERS_URL = '/users/';
import Vue from 'vue';
import Persist from '~/util/persist';
import Storage from '~/util/storage';
import { version } from '../package.json';

const storage = process.client ? new Storage(window.localStorage) : undefined;
const persist = new Persist('joblist', storage, version, process.client, {
  isExpirable: false,
});

export const state = () => ({
  joblists: undefined,
  isHydrated: false,
  subTotal: 0,
  subGst: 0,
  totalJobLists: 0,
  selectedJobList: undefined,
  contactDetails: {
    enquiryDetails: {
      contactName: '',
      company: '',
      phone: '',
      email: '',
    },
  },
});

export const mutations = {
  setJobLists(state, data) {
    state.joblists = data;
    state.totalJobLists = data.length;
  },
  setSelectedJobList(state, data) {
    state.selectedJobList = data;
    persist.setItem('joblist', '', state);
  },
  setSubtotal(state, data) {
    state.subTotal = data;
    persist.setItem('joblist', '', state);
  },
  setSubgst(state, data) {
    state.subGst = data;
    persist.setItem('joblist', '', state);
  },
  isHydrated(state) {
    Vue.set(state, 'isHydrated', true);
  },
  updateAll(state, payload) {
    for (const key in payload) {
      if (key) {
        Vue.set(state, key, payload[key]);
      }
    }
  },
  updateContactDetailsField(state, payload) {
    const key = payload.fieldName;
    const value = payload.value;

    const keys = key.split('.');

    state.contactDetails[keys[0]][keys[1]] = value;

    persist.setItem('joblist', '', state);
  },
};

export const getters = {
  getTotalJobLists: state => {
    return state.totalJobLists;
  },
  getJobLists: state => {
    return state.joblists;
  },
  getEnquiryDetails: state => {
    return state.contactDetails.enquiryDetails;
  },
  getSelectedJobList: state => {
    return state.selectedJobList;
  },
  getSubtotal: state => {
    return state.subTotal;
  },
  getSubgst: state => {
    return state.subGst;
  },
};

export const api = {
  // Creating new job list
  async createNewJobList($axios, payload) {
    const url = USERS_URL + 'joblist/create';
    let response;

    try {
      response = await $axios.post(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
  // Add products to joblist
  async addNewProductsToList($axios, payload) {
    const url = USERS_URL + 'joblist/items/' + payload.id + '/update';
    let response;

    try {
      response = await $axios.patch(url, payload.params);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
  // Update joblist name
  async updateJobListName($axios, payload) {
    const url = USERS_URL + 'joblist/name/' + payload.id + '/update';
    let response;

    try {
      response = await $axios.patch(url, payload.params);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
  // Remove item from job list
  async removeJobList($axios, payload) {
    const url = USERS_URL + 'joblist/remove/' + payload.id;
    let response;

    try {
      response = await $axios.patch(url);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
  // Update joblist product quantity
  async updateJobListProductQuantity($axios, payload) {
    const url = USERS_URL + 'joblist/items/qty/' + payload.id + '/update';
    let response;

    try {
      response = await $axios.patch(url, payload.params);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
  // Remove item from job list
  async removeJobListItem($axios, payload) {
    const url = USERS_URL + 'joblist/items/remove/' + payload.id;
    let response;

    try {
      response = await $axios.patch(url);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
  // Editing job list name
  async getJobList($axios) {
    // In this case, id should be passed on from params?
    const url = USERS_URL + 'joblist/all';
    let response;

    try {
      response = await $axios.get(url);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
  // Send job list enquiry emails
  async postJobListEnquiryEmails($axios, payload) {
    const url = USERS_URL + 'joblist/send-email/enquiry';
    let response;

    try {
      response = await $axios.post(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }
    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },

  // Send job list emails
  async postJobListEmails($axios, payload) {
    const url = USERS_URL + 'joblist/send-email';
    let response;

    try {
      response = await $axios.post(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (
      response.data.error ||
      !response ||
      !response.data ||
      !response.data.data
    ) {
      return undefined;
    }

    return response.data.data;
  },
};

export const actions = {
  async hydrate({ state, commit }) {
    let cached = persist.getItem('joblist', '');

    if (cached && !state.isHydrated) {
      commit('updateAll', cached);
    }

    commit('isHydrated');
  },
  async updateContactDetailsField({ commit }, payload) {
    commit('updateContactDetailsField', payload);
    return true;
  },
  async setSelectedJobList({ commit }, payload) {
    commit('setSelectedJobList', payload);
    return true;
  },
  async setSubtotal({ commit }, payload) {
    commit('setSubtotal', payload);
    return true;
  },
  async setSubgst({ commit }, payload) {
    commit('setSubgst', payload);
    return true;
  },
  async createNewUserJobList(_, payload) {
    const response = await api.createNewJobList(this.$axios, payload);

    return response;
  },
  async addNewProductsToJobList(_, payload) {
    const response = await api.addNewProductsToList(this.$axios, payload);

    return response;
  },
  async updateJobListProductQuantity(_, payload) {
    const response = await api.updateJobListProductQuantity(
      this.$axios,
      payload
    );
    return response;
  },
  async changeJobListName(_, payload) {
    const response = await api.updateJobListName(this.$axios, payload);

    return response;
  },
  async deleteJobList(_, payload) {
    const response = await api.removeJobList(this.$axios, payload);

    return response;
  },
  async removeJobListProductItem(_, payload) {
    const response = await api.removeJobListItem(this.$axios, payload);

    return response;
  },
  async sendJobListEnquiryEmail(_, payload) {
    const response = await api.postJobListEnquiryEmails(this.$axios, payload);

    return response;
  },
  async sendJobListEmail(_, payload) {
    const response = await api.postJobListEmails(this.$axios, payload);

    return response;
  },
  async getUserJobLists({ commit }) {
    const response = await api.getJobList(this.$axios);

    if (response && !response.error) {
      let joblists = [];
      response.forEach(joblist => {
        if (!joblist.deleted) {
          joblists.push(joblist);
        }
      });
      commit('setJobLists', joblists);
      return joblists;
    }

    return response;
  },
};
