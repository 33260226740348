import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights';

/**
 * Send Algolia 'Suggestion' or 'Recent Search' text as Search Page Query
 * @param {Object} item - Query Suggestion or Recent Search Item
 * @returns {String} - Search Page URL w/ query
 */
const createSuggestionUrl = function(item) {
  const urlParams = new URLSearchParams();
  urlParams.set('query', item.query);
  if (item.__autocomplete_qsCategory) {
    urlParams.set(
      'hierarchicalMenu[categories.level1][0]',
      item.__autocomplete_qsCategory
    );
  }

  return `/search?${urlParams.toString()}`;
};

const createRecentUrl = function(item) {
  const urlParams = new URLSearchParams();
  urlParams.set('query', item.id);
  if (item.category) {
    urlParams.set('hierarchicalMenu[categories.level1][0]', item.category);
  }

  return `/search?${urlParams.toString()}`;
};

const createCategoryUrl = function(slug) {
  return window.location.origin + `/c/${slug}`;
};

const createProductUrl = function(item) {
  return window.location.origin + `/p/${item.slug}`;
};

const createCMSPageUrl = function(item) {
  return window.location.origin + `${item.url}`;
};

const createCMSImageUrl = function(baseUrl = null, item) {
  if (!baseUrl || !item.imageUrl) return './favicon.png';
  return item.imageUrl;
};

let __assign =
  (this && this.__assign) ||
  function() {
    __assign =
      Object.assign ||
      function(t) {
        for (let s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (let p in s) {
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
          }
        }
        return t;
      };

    return __assign.apply(this, arguments);
  };

const flatten = function(values) {
  return values.reduce(function(a, b) {
    return a.concat(b);
  }, []);
};

const normalizeReshapeSources = function(sources) {
  return flatten(sources).filter(Boolean);
};

const titleToSlug = function(title) {
  let slug;

  //Convert to lower case
  slug = title.toLowerCase();

  //Remove special characters
  //The /gi modifier is used to do a case insensitive search of all occurrences of a regular expression in a string
  slug = slug.replace(
    /`|~|!|@|#|\||\$|%|\^|&|\*|\(|\)|\+|=|,|\.|\/|\?|>|<|'|"|:|;|_/gi,
    ''
  );

  //Replace spaces with dash symbols
  slug = slug.replace(/ /gi, '-');

  //Remove consecutive dash symbols
  slug = slug.replace(/-----/gi, '-');
  slug = slug.replace(/----/gi, '-');
  slug = slug.replace(/---/gi, '-');
  slug = slug.replace(/--/gi, '-');

  //Remove the unwanted dash symbols at the beginning and the end of the slug
  slug = '@' + slug + '@';
  slug = slug.replace(/@-|-@|@/gi, '');
  return slug;
};

const groupBy = function(predicate, options) {
  return function runGroupBy() {
    let rawSources = [];
    for (let _i = 0; _i < arguments.length; _i++) {
      rawSources[_i] = arguments[_i];
    }

    let sources = normalizeReshapeSources(rawSources);
    if (sources.length === 0) {
      return [];
    }

    if (!sources[0].products) return [];

    // Since we create multiple sources from a single one, we take the first one
    // as reference to create the new sources from.
    let referenceSource = sources[0].products;
    let items = (0, flatten)(
      sources.map(function(source) {
        return source.products.getItems();
      })
    );

    let groupedItems = items.reduce(function(acc, item) {
      let key = predicate(item);
      if (!Object.prototype.hasOwnProperty.call(acc, key)) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    return Object.entries(groupedItems).map(function(_a) {
      let groupName = _a[0];
      let groupItems = _a[1];
      let groupSlug = titleToSlug(groupName);
      let userSource = options.getSource({
        name: groupName,
        slug: groupSlug,
        items: groupItems,
        config: sources[0].config,
      });
      return __assign(
        __assign(
          __assign(__assign({}, referenceSource), {
            sourceId: groupName,
            getItems: function() {
              return groupItems;
            },
          }),
          userSource
        ),
        {
          templates: __assign(
            __assign({}, referenceSource.templates),
            userSource.templates
          ),
        }
      );
    });
  };
};

const groupByCategory = groupBy(hit => hit.categories.level1, {
  getSource({ name, slug, items, config }) {
    return {
      getItems() {
        return items.slice(0, config.limits.products);
      },
      templates: {
        header({ createElement }) {
          return createElement(
            'a',
            {
              className: 'aa-ItemLink',
              href: createCategoryUrl(slug),
              title: `View "${name}" Category Page`,
            },
            createElement(
              'span',
              {
                className: 'aa-SourceHeaderTitle',
              },
              name
            ),
            createElement('div', {
              className: 'aa-SourceHeaderLine',
            })
          );
        },
      },
    };
  },
});

/**
 * Insights Plugin for Algolia Autocomplete
 * @param {Object} insightsClient - Initialised Algolia Insights Client
 * @return {Object} - Algolia Insights Plugin
 */
const initAlgoliaInsights = function(insightsClient) {
  return createAlgoliaInsightsPlugin({
    insightsClient,
    onSelect({ insights, insightsEvents }) {
      const events = insightsEvents.map(insightsEvent => {
        switch (insightsEvent.index) {
          case 'instant_search_demo_query_suggestions': {
            return {
              ...insightsEvent,
              eventName: 'Autocomplete: Suggestion Clicked',
            };
          }
          default: {
            return {
              ...insightsEvent,
              eventName: 'Autocomplete: Product Clicked',
            };
          }
        }
      });
      insights.clickedObjectIDsAfterSearch(...events);
    },
  });
};

/**
 * Query Suggestions Plugin for Algolia Autocomplete
 * @param {Object} searchClient - initialised algolia search client
 * @param {Object} config - Autocomplete Config Object
 * @returns {Object} - Algolia Plugin Object
 */
const initQuerySuggestions = function(searchClient, config) {
  const index = config.index;
  const indexName = config.queryIndex;
  const hitsPerPage = config.limits.suggestions;
  const popularLimit = 4;

  return createQuerySuggestionsPlugin({
    searchClient,
    indexName,
    getSearchParams({ state }) {
      return {
        hitsPerPage:
          !state.query || state.query.length <= 0 ? popularLimit : hitsPerPage,
      };
    },
    categoryAttribute: [index, 'facets', 'exact_matches', 'categories.level1'],
    transformSource({ source }) {
      return {
        ...source,
        getItemUrl({ item }) {
          return createSuggestionUrl(item);
        },
        templates: {
          header({ state, createElement }) {
            return createElement(
              'div',
              null,
              createElement(
                'span',
                {
                  className: 'aa-SourceHeaderTitle',
                },
                !state.query || state.query.length <= 0
                  ? 'Popular Searches'
                  : 'Suggestions'
              ),
              createElement('div', {
                className: 'aa-SourceHeaderLine',
              })
            );
          },
          item(params) {
            const { item, createElement } = params;
            return createElement(
              'a',
              {
                className: 'aa-ItemLink',
                href: createSuggestionUrl(item),
              },
              source.templates.item(params)
            );
          },
        },
      };
    },
  });
};

/**
 * Recent Searches Plugin for Algolia Autocomplete
 * @param {Object} config - Autocomplete Config
 * @returns {Object} - Algolia Plugin Object
 */
const initRecentSearches = function(config) {
  const limit = config.limits.recent;

  return createLocalStorageRecentSearchesPlugin({
    key: 'bowens/search/recent',
    limit,
    search({ query, items, limit }) {
      if (!query) return items.slice(0, limit);
      return [];
    },
    transformSource({ source }) {
      return {
        ...source,
        getItemUrl({ item }) {
          return createRecentUrl(item);
        },
        templates: {
          header({ createElement }) {
            return createElement(
              'div',
              null,
              createElement(
                'span',
                {
                  className: 'aa-SourceHeaderTitle',
                },
                'Recent Searches'
              ),
              createElement('div', {
                className: 'aa-SourceHeaderLine',
              })
            );
          },
          item(params) {
            const { item, createElement } = params;
            return createElement(
              'a',
              {
                className: 'aa-ItemLink',
                href: createRecentUrl(item),
              },
              source.templates.item(params)
            );
          },
        },
      };
    },
  });
};

// Debounce function for delaying api result
function debouncePromise(fn, time) {
  let timerId = undefined;

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise(resolve => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}

/**
 * @param {Object} searchClient - initialised algolia search client
 * @param {Object} config - Autocomplete Config
 * @returns {Object} - Products Instant Search Plugin
 */
const initProductInstantSearch = function(searchClient, config) {
  const indexName = config.index;
  const hitsPerPage = config.limits.products;

  const debounced = debouncePromise(items => Promise.resolve(items), 300);

  return {
    getSources({ query }) {
      if (!query) {
        return [];
      }

      return debounced([
        {
          sourceId: 'products',
          getItems() {
            return getAlgoliaResults({
              searchClient: searchClient,
              queries: [
                {
                  indexName,
                  query,
                  params: {
                    hitsPerPage,
                    distinct: 1,
                    clickAnalytics: true,
                    filters: 'status:public',
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return createProductUrl(item);
          },
          templates: {
            item({ item, createElement, components }) {
              return createElement(
                'a',
                {
                  className: 'aa-ItemLink',
                  href: createProductUrl(item),
                },
                createElement(
                  'div',
                  {
                    className: 'aa-ItemWrapper',
                  },
                  createElement(
                    'div',
                    {
                      className: 'aa-ItemContent',
                    },
                    createElement(
                      'div',
                      {
                        className: 'aa-ItemImage',
                      },
                      createElement('img', {
                        className: 'thumbnail',
                        width: 40,
                        src: item.imageUrl
                          ? item.imageUrl
                          : item.variationImageUrl,
                      })
                    ),
                    createElement(
                      'div',
                      {
                        className: 'aa-ItemContentBody',
                      },
                      createElement(
                        'div',
                        {
                          className: 'aa-ItemContentTitle',
                        },
                        createElement(
                          'span',
                          null,
                          components.Highlight({
                            hit: item,
                            attribute: 'title',
                            tagName: 'mark',
                          })
                        )
                      )
                    )
                  )
                )
              );
            },
          },
        },
      ]);
    },
  };
};

/**
 * @param {Object} searchClient - initialised algolia search client
 * @param {Object} config - Autocomplete Config
 * @returns {Object} - Products Instant Search Plugin
 */
const initCMSPageInstantSearch = function(searchClient, config) {
  const indexName = config.cmsPageIndex;
  const hitsPerPage = config.limits.cmsPages;
  const baseImageUrl = config.paths.public;

  const debounced = debouncePromise(items => Promise.resolve(items), 300);

  return {
    getSources({ query }) {
      if (!query) {
        return [];
      }

      return debounced([
        {
          sourceId: 'cmsPages',
          getItems() {
            return getAlgoliaResults({
              searchClient: searchClient,
              queries: [
                {
                  indexName,
                  query,
                  params: {
                    hitsPerPage,
                    clickAnalytics: true,
                    filters: 'status:public',
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return createCMSPageUrl(item);
          },
          templates: {
            header({ createElement }) {
              return createElement(
                'div',
                null,
                createElement(
                  'span',
                  {
                    className: 'aa-SourceHeaderTitle',
                  },
                  'Site Pages & Posts'
                ),
                createElement('div', {
                  className: 'aa-SourceHeaderLine',
                })
              );
            },
            item({ item, createElement, components }) {
              return createElement(
                'a',
                {
                  className: 'aa-ItemLink',
                  href: createCMSPageUrl(item),
                },
                createElement(
                  'div',
                  {
                    className: 'aa-ItemWrapper',
                  },
                  createElement(
                    'div',
                    {
                      className: 'aa-ItemContent',
                    },
                    createElement(
                      'div',
                      {
                        className: 'aa-ItemImage',
                      },
                      createElement('img', {
                        className: 'thumbnail',
                        width: 40,
                        src: createCMSImageUrl(baseImageUrl, item),
                      })
                    ),
                    createElement(
                      'div',
                      {
                        className: 'aa-ItemContentBody',
                      },
                      createElement(
                        'div',
                        {
                          className: 'aa-ItemContentTitle',
                        },
                        createElement(
                          'span',
                          null,
                          components.Highlight({
                            hit: item,
                            attribute: 'title',
                            tagName: 'mark',
                          })
                        )
                      )
                    )
                  )
                )
              );
            },
          },
        },
      ]);
    },
  };
};

export default {
  group: groupByCategory,
  plugins: {
    querySuggestions: initQuerySuggestions,
    recentSearches: initRecentSearches,
    insights: initAlgoliaInsights,
    products: initProductInstantSearch,
    cmsPages: initCMSPageInstantSearch,
  },
};
