//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BbSvg from '~/static/svg/benefit page icons/bb-account.svg';
import TradeSvg from '~/static/svg/benefit page icons/trade-account.svg';

export default {
  components: {
    BbSvg,
    TradeSvg,
  },
  methods: {
    handleClick(title) {
      if (title.includes('Bowens')) {
        return (location.href = this.$t(
          'benefits_and_trade.bowens_location_url'
        ));
      } else if (title.includes('Trade')) {
        return (location.href = this.$t(
          'benefits_and_trade.trade_location_url'
        ));
      }
      return (location.href = this.$t(
        'benefits_and_trade.default_location_url'
      ));
    },
  },
};
