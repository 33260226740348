import { api, utils } from '../order';

/**
 * Post to the API to create an order for Stripe checkout
 * @param {Object} $axios The Axios instance
 * @param {Object} payload The stripe order creation payload
 * @param {String} clientIp The IP of the client calling the API
 * @returns {undefined|Object} The stripe order creation response
 */
export async function createStripeOrderApi($axios, payload, clientIp) {
  let config = {
    headers: {
      CLIENT_IP: clientIp,
    },
    timeout: 60_000, // 60 seconds
  };
  let response;
  try {
    response = await $axios.post('/orders/order/stripe', payload, config);
  } catch (e) {
    console.error(e);
  }

  if (!response) {
    return undefined;
  }

  const data = response.data;

  if (!data) {
    return undefined;
  }

  return data;
}

/**
 * Create eCommerce Order for a Stripe Checkout
 * @param {Object} _ Reference
 * @param {Object} payload The Request payload
 * @returns {Object} The eCommerce Order response
 */
export async function createStripeOrderAction(_, payload) {
  const cIp = payload.clientIp;
  delete payload.clientIp;
  let result = await api.createStripeOrderApi(this.$axios, payload, cIp);

  if (result.error) {
    return result;
  }

  if (!utils.isValidStripeResponse(result)) {
    throw new Error('Paypal payload not valid');
  }

  return result;
}
