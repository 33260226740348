const AT = '25.2744,133.7751';
const LANG = 'en';
const REGION = 'AU';

export const util = {
  addStringAtPosition(text, index, string) {
    return text.slice(0, index) + string + text.slice(index);
  },
};

export const api = {
  async getAutoGoogleSuggest($axios, googleMapKey, query) {
    const AUTOSUGGEST_URL =
      'https://maps.googleapis.com/maps/api/place/autocomplete/json?';
    let response;

    try {
      response = await $axios.get(AUTOSUGGEST_URL, {
        params: {
          region: REGION,
          language: LANG,
          location: AT,
          input: query,
          key: googleMapKey,
        },
      });
    } catch (e) {
      throw new Error(e);
    }

    if (!response) {
      return [];
    }

    const data = response.data;

    if (!data) {
      return [];
    }

    let results = data.predictions;

    if (!results) {
      return [];
    }

    return results;
  },
  async getDetailedAddress($axios, googleMapKey, address) {
    const googleGeocode_URL =
      'https://maps.googleapis.com/maps/api/geocode/json?';
    let response;

    try {
      response = await $axios.get(googleGeocode_URL, {
        params: {
          address: address,
          latlng: AT,
          key: googleMapKey,
        },
      });
    } catch (e) {
      throw new Error(e);
    }

    if (!response) {
      return [];
    }

    const data = response.data;

    if (!data) {
      return [];
    }

    let results = data.results;

    if (!results) {
      return [];
    }

    return results;
  },
};

export const actions = {
  async getAutoGoogleSuggestions({ rootState: { googleMapKey } }, { query }) {
    let results = await api.getAutoGoogleSuggest(
      this.$axios,
      googleMapKey,
      query
    );
    return results;
  },
  async getDetailedAddress({ rootState: { googleMapKey } }, { address }) {
    let results = await api.getDetailedAddress(
      this.$axios,
      googleMapKey,
      address
    );
    return results;
  },
};
