import Vue from 'vue';

export const state = () => ({
  header: {
    isSearchBarVisible: false,
    isMyAccountVisible: true
  }
});

export const mutations = {
  updateIsSearchBarVisible(state, boolean) {
    Vue.set(state.header, 'isSearchBarVisible', boolean);
  },
  updateIsMyAccountVisible(state, boolean) {
    Vue.set(state.header, 'isSearchBarVisible', boolean);
  }
};

export const getters = {
  getIsSearchBarVisible: state => () => {
    return state.header.isSearchBarVisible;
  },
  getIsMyAccountVisible: state => () => {
    return state.header.isSearchBarVisible;
  }
};

export const actions = {
  async updateIsSearchBarVisible({ commit }, isSearchBarVisible) {
    commit('updateIsSearchBarVisible', isSearchBarVisible);
    return isSearchBarVisible;
  },
  async updateIsMyAccountVisible({ commit }, isMyAccountVisible) {
    commit('updateIsMyAccountVisible', isMyAccountVisible);
    return isMyAccountVisible;
  }
};
