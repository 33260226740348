//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    categories: {
      type: Array,
      required: false,
    },
    product: {
      type: Object,
    },
    productCategories: {
      type: Array,
    },
    query: {
      type: String,
    },
    position: {
      type: String,
    },
    cmsBanner: {
      type: String,
    },
    location: {
      type: String,
    },
    extraClass: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      data: null,
      slugs: [],
      isLoggedIn: false,
    };
  },

  computed: {
    ...mapGetters({
      cmsSettings: 'settings/cms',
      getIsLoggedIn: 'users/getIsLoggedIn',
    }),
  },
  watch: {
    query(newQuery, oldQuery) {
      if (oldQuery !== newQuery) {
        this.slugs.shift();
        const item = newQuery + `-${this.position}`;
        this.slugs.unshift(item);
        this.fetchBanner();
      }
    },
  },
  created() {
    this.matchSlug();
  },
  mounted() {
    this.fetchBanner();
    this.isLoggedIn = this.getIsLoggedIn;
  },
  methods: {
    ...mapActions({ getBanner: 'banners/getBanner' }),
    slugify(string) {
      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '')
        .replace(/-+/g, '-')
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
    },
    matchSlug() {
      if (this.categories && this.position) {
        const reversedCats = this.categories.reverse();
        for (const category of reversedCats) {
          const slug = this.slugify(category);
          const item = slug + `-${this.position}`;
          this.slugs.push(item);
        }
      } else if (this.query && this.position) {
        const item = this.query + `-${this.position}`;
        this.slugs.push(item);
      } else if (this.product && this.productCategories) {
        this.slugs.push(this.product.slug);
        this.productCategories
          .reverse()
          .map(categorySlug => this.slugs.push(categorySlug + '-product'));
      } else if (this.cmsBanner) {
        this.slugs.push(this.cmsBanner);
      }
      if (this.location) {
        this.slugs.push(this.location);
      }
    },
    cleanPath(pathname) {
      if (pathname.includes('?')) {
        return pathname.split('?')[0];
      }
      return pathname;
    },
    async fetchBanner() {
      if (this.slugs.length > 0) {
        const banner = await this.getBanner(this.slugs);

        if (banner) {
          this.data = banner;
        }
      }
    },
    shouldShowLoggedInBanner() {
      if (
        this.isLoggedIn &&
        this.data?.would_you_like_a_separate_banner_for_logged_in_users
      ) {
        return true;
      }
      return false;
    },
    shouldShowDefaultBanner() {
      if (this.isLoggedIn && this.data?.dont_show_if_logged_in) {
        return false;
      }
      return true;
    },
  },
};
