import { STORE_DIVIDER } from './configSettings';

export default {
  generateStorageKey() {
    const keys = [];

    for (const argument of [...arguments]) {
      if (argument === undefined || argument === '') {
        continue;
      }

      keys.push(argument);
    }

    return keys.join(STORE_DIVIDER);
  },
  generateExpiry(expiry) {
    const parsed = parseInt(expiry);
    let date = new Date();
    if (isNaN(parsed)) {
      return date.getTime();
    }

    date.setMilliseconds(date.getMilliseconds() + expiry);
    return date.getTime();
  },
  generatePriceExpiry() {
    let date = new Date();

    // if time is before 6pm, set expiry to 6pm
    // if time is after 6pm, set expiry to 6pm tomorrow
    if (date.getHours() >= 18) {
      date = new Date(this.getTomorrow6pm());
    } else {
      date = new Date(this.getToday6pm());
    }

    return date.getTime();
  },
  hasPriceExpired(expiry) {
    const parsed = parseInt(expiry);
    let date = new Date();
    return isNaN(parsed) || date.getTime() > parsed;
  },
  getToday6pm() {
    let date = new Date();
    date.setHours(18, 0, 0, 0); // Set time to 6pm
    return date.getTime();
  },
  getTomorrow6pm() {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(18, 0, 0, 0); // Set time to 6pm
    return date.getTime();
  },
};
