//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import CloseIcon from '~/static/svg/cross-blue.svg';

export default {
  components: {
    CloseIcon,
  },
  data() {
    return {
      windowWidth: '',
    };
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true,
    },
    isChinbar: {
      type: Boolean,
      required: false,
      default: false,
    },
    popUpPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      logoutUser: 'users/logoutUser',
    }),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async handleLogout() {
      await this.logoutUser();
      this.$router.push({
        path: '/',
      });
    },
    handleClose() {
      this.$emit('close');
    },
    closePopup() {
      this.$emit('closePopup');
    },
    handleMyAccountClick() {
      this.closePopup();
    },
  },
  computed: {
    ...mapGetters({
      getCustomer: 'users/getCustomer',
      getContact: 'users/getContact',
    }),
    canUseJobListFeatures() {
      return this.$config.featureToggle.toggles.enableJobListFeature;
    },
    canUseMagicLinkLogin() {
      return this.$config.featureToggle.toggles.enableMagicLinkLogin;
    },
    offsetStyle() {
      if (this.popUpPage) {
        return `right: 0px;`;
      }

      if (this.loggedIn && this.canUseJobListFeatures) {
        let loggedInOffset;
        if (this.windowWidth < 2560) {
          loggedInOffset = 284.16 + 10 + this.windowWidth / 24.39;
        } else {
          loggedInOffset = 400;
        }
        return `right: ${loggedInOffset}px;`;
      }

      let offset;
      if (this.windowWidth < 2560) {
        offset = 131.16 + 10 + this.windowWidth / 24.39;
      } else {
        offset = 246;
      }
      return `right: ${offset}px;`;
    },
    containerStyle() {
      if (this.isChinbar) {
        return `login-account-popup__chinbar ${
          this.loggedIn ? 'login-account-popup__chinbar-lgo' : ''
        }`;
      } else if (this.popUpPage) {
        return `login-account-popup__header-pop-up`;
      } else {
        return `login-account-popup__header`;
      }
    },
    closeBtnStyle() {
      if (this.loggedIn) {
        return 'login-account-popup__close login-account-popup__close-lg';
      } else {
        return 'login-account-popup__close login-account-popup__close-lgo';
      }
    },
  },
  mounted() {
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize);
  },
};
