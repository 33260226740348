//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapGetters } from 'vuex';
import DollarIcon from '~/static/svg/currency.svg';
import ArrowLeftYellow from '~/static/svg/arrow-left-yellow.svg';
import EnquirySvg from '~/static/svg/enquiry-blue.svg';
import LocationSvg from '~/static/svg/location-blue.svg';
import InstagramSvg from '~/static/svg/instagram.svg';
import FacebookSvg from '~/static/svg/facebook.svg';
import YoutubeSvg from '~/static/svg/youtube.svg';
import MastercardSvg from '~/static/svg/mastercard.svg';
import VisaSvg from '~/static/svg/visa.svg';
import PaypalSvg from '~/static/svg/paypal.svg';
import DiscoverySvg from '~/static/svg/discovery.svg';
import DayAccountPaymentSvg from '~/static/svg/credit-account-2.svg';
import AmexSvg from '~/static/svg/amex.svg';
import UnionPaySvg from '~/static/svg/union-pay.svg';
import ZipSvg from '~/static/svg/zip-footer.svg';
import InstallModal from '~/components/install-app/InstallModal.vue';

export default {
  components: {
    DollarIcon,
    InstagramSvg,
    FacebookSvg,
    YoutubeSvg,
    ArrowLeftYellow,
    LocationSvg,
    EnquirySvg,
    MastercardSvg,
    VisaSvg,
    PaypalSvg,
    DiscoverySvg,
    DayAccountPaymentSvg,
    AmexSvg,
    UnionPaySvg,
    ZipSvg,
    InstallModal,
  },
  data() {
    return {
      mobileActiveClass: [],
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      cmsSettings: 'settings/cms',
    }),
    footer() {
      return this.cmsSettings && this.cmsSettings.footer
        ? this.cmsSettings.footer
        : false;
    },
  },
  methods: {
    toggleMenu(index) {
      Vue.set(
        this.mobileActiveClass,
        index - 1,
        !this.mobileActiveClass[index - 1]
      );
    },
    resetAccordion() {
      for (let index = 0; index < 4; index++) {
        this.mobileActiveClass[index] = false;
      }
    },
  },
  mounted() {
    this.resetAccordion();
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      this.isReady = true;
    }
    window.addEventListener('resize', this.resetAccordion);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetAccordion);
  },
};
