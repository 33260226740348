import Vue from 'vue';

export const state = () => ({
  banners: {},
});

export const mutations = {
  addBanner(state, params) {
    Vue.set(state.banners, params.location, params.data);
  },
};

export const getters = {
  getBanner: state => location => {
    return state.banners[location];
  },
};

export const actions = {
  async getBanner(state, slugs) {
    const queryString = slugs.map(slug => `locations=${slug}`).join('&');

    // Handle any api errors gracefully
    try {
      let res = await this.$axios.get('/cms/banner?' + queryString);

      res.data.time_cache = this.$dayjs();
      const data = res.data;

      state.commit('addBanner', {
        location: data.location,
        data,
      });

      return data;
    } catch (e) {
      if (e.response) {
        this.$sentry.captureException(e, {
          level: 'error',
          extra: {
            error: true,
            msg: e.message,
          },
        });
        return;
      }
    }
  },
};
