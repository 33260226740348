//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import ArrowLeft from '~/static/svg/arrow-left.svg';
import ArrowRight from '~/static/svg/arrow-right.svg';
import HomeIcon from '~/static/svg/home-icon.svg';

export default {
  name: 'my-account-header',
  components: {
    ArrowLeft,
    ArrowRight,
    HomeIcon,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      path: this.$route.path,
    };
  },
  async mounted() {
    await this.getUserDetails();
  },
  watch: {
    $route() {
      this.path = this.$route.path;
    },
  },
  computed: {
    ...mapGetters({
      getCustomer: 'users/getCustomer',
      getContact: 'users/getContact',
      getStatementDetails: 'users/getStatementDetails',
      getFrameworksDetails: 'users/getFrameworksDetails',
    }),
    contact() {
      return this.getContact;
    },
    customer() {
      return this.getCustomer;
    },
    frameworks() {
      return this.getFrameworksDetails;
    },
    breadcrums() {
      const links = this.path.split('/');
      // given path "/my-account/quotes/payment"
      // after slipting, we have ['', 'my-account', 'quote', 'payment']
      // index 0 is the root, index 1 is the root of this branch
      // the last element is the current page
      // the link to the root is hardcode in this component
      // no need to create a link to the current page
      // we will need to create links for anything between the
      //  second element (index 1) and the last element
      if (links.length <= 3) {
        return [];
      }
      return links.slice(2, links.length - 1);
    },
  },
  methods: {
    ...mapActions({
      getUserDetails: 'users/getUserDetails',
    }),
  },
};
