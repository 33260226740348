import { STORE_NAME, STORE_EXPIRY } from '../util/configSettings';
import utils from './persist-utils';

export default class Persist {
  /**
   * @param {string} - The base key to arrow seperation
   */
  application = STORE_NAME;

  /**
   * @param {boolean} - This is required as localstorage should be
   * client only
   */
  isClient = false;

  /**
   * @param {boolean} - Is expirations added
   */
  isExpirable = true;

  /**
   * @param {number} - The future number of seconds to set
   * the key to expire at in milliseconds
   */
  expiry = STORE_EXPIRY;

  /**
   * @param {number} - The difference in now and the item to know if we should
   * invalidate the local storage. Ideally 0 but we can say it should last
   * another 1 hr before its forced to expire
   */
  minimumExpiryDifference = 0;

  constructor(name, storage, version, isClient, options) {
    this.storage = storage;
    this.name = name;
    this.version = version;
    this.isClient = isClient;

    if (!this.isClient) {
      return;
    }

    if (options && options.isExpirable !== undefined) {
      this.isExpirable = options.isExpirable;
    }

    if (options && options.expiry !== undefined) {
      this.expiry = options.expiry;
    }

    if (options && options.minimumExpiryDifference !== undefined) {
      this.minimumExpiryDifference = options.minimumExpiryDifference;
    }
  }

  demetatizeItem(item) {
    if (!item) {
      return item;
    }

    return item.data;
  }

  metatizeItem(value) {
    let item = {
      data: value,
    };

    if (!this.isClient) {
      return item;
    }

    if (this.isExpirable) {
      //Created name was not great, not sure what this means for exisiting local storage
      item.expires = utils.generateExpiry(this.expiry);
    }

    return item;
  }

  metatizePriceItem(value) {
    let item = {
      data: value,
    };

    if (!this.isClient) {
      return item;
    }

    if (this.isExpirable) {
      //Created name was not great, not sure what this means for exisiting local storage
      item.expires = utils.generatePriceExpiry();
    }

    return item;
  }

  getItem(stateKey, subkey) {
    if (!this.isClient) {
      return;
    }

    const storageKey = utils.generateStorageKey(
      this.application,
      this.name,
      stateKey,
      subkey
    );

    const item = this.storage.getItem(storageKey);

    if (item && item.isHydrated !== undefined) {
      delete item.isHydrated;
    }

    return item;
  }

  setItem(stateKey, subkey, item) {
    if (item && item.isHydrated !== undefined) {
      delete item.isHydrated;
    }

    if (!this.isClient) {
      return;
    }

    const storageKey = utils.generateStorageKey(
      this.application,
      this.name,
      stateKey,
      subkey
    );

    this.storage.setItem(storageKey, item);

    return item;
  }

  removeItem(stateKey, subkey) {
    if (!this.isClient) {
      return;
    }

    const storageKey = utils.generateStorageKey(
      this.application,
      this.name,
      stateKey,
      subkey
    );
    this.storage.removeItem(storageKey);

    return;
  }

  isItemCorrectVersion(item) {
    if (!item || !item.version) {
      return false;
    }

    return item.version === this.version;
  }

  isValidExpiration(item) {
    const expiryDifference = this.minimumExpiryDifference;

    if (expiryDifference < 0) {
      return false;
    }

    if (!item || !item.expires || item.data?.error === true) {
      return false;
    }

    const timestamp = item.expires;
    const nowTimestamp = new Date().getTime();

    if (nowTimestamp - timestamp > expiryDifference) {
      return false;
    }

    return true;
  }

  getFromStorageAndCache(getters, id) {
    let pricing;

    if (this.isClient) {
      let item = this.getItem('pricings', id);

      if (this.isValidExpiration(item)) {
        pricing = item.data;
      } else {
        this.removeItem('pricings', id);
      }
    }

    if (!pricing) {
      let item = getters.getPricingItemById(id);

      if (this.isValidExpiration(item)) {
        pricing = item.data;
      }
    }

    return pricing;
  }
}
