import { mapGetters, mapActions } from 'vuex';
import gtmMixin from '~/mixins/gtm';

// Make get local storage asynchronous
const asyncLocalStorage = {
  async getItem(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  },
};

export default {
  mixins: [gtmMixin],
  computed: {
    ...mapGetters({
      getIsLoggedIn: 'users/getIsLoggedIn',
    }),
  },
  methods: {
    ...mapActions({
      login: 'users/authenticateLoginCredentials',
      rememberMeLogin: 'users/rememberMeLogin',
      bustPriceCache: 'pricing/bustPriceCache',
      clearOrderContactDetails: 'checkout/clearOrderContactDetails',
      validateCompany: 'users/validateCompany',
      getUserJobLists: 'joblist/getUserJobLists',
    }),
    async isRefreshAutoLoginSuccess() {
      const isLoggedIn = this.getIsLoggedIn;

      if (!isLoggedIn) {
        const email = await asyncLocalStorage.getItem('user_remember_me_email');

        if (email) {
          const params = { email: email };
          return await this.autoLoginRememberMe(params);
        }
        return false;
      }
    },
    async autoLoginRememberMe(details) {
      const resp = await this.rememberMeLogin(details);
      if (!resp || resp.error) {
        return false;
      }
      this.loginErrorMsg = null;

      if (resp.companies) {
        if (resp.companies.length > 1) {
          const storedCompany = await asyncLocalStorage.getItem('user_remember_me_company');
          // if (this.$cookies.get('selectedSessionCompany'))
          if (storedCompany) {
            // const selectedCompany = this.$cookies.get('selectedSessionCompany') incase of rollback
            const params = { customerId: storedCompany };
            await this.skipCompanyPage(params);
            return true;
          } else {
            this.$router.push({
              path: '/my-account/select-company/',
              query: {
                redirect: this.redirectTo,
              },
            });
            return true;
          }
        }

        await this.skipCompanyPage(resp.companies[0]);
        return true;
      }
    },
    async skipCompanyPage(company) {
      const payload = {
        contactId: company.contactId,
        customerId: company.customerId,
      };

      const companyResp = await this.validateCompany(payload);

      if (!companyResp || companyResp.error) {
        this.$router.push({
          path: '/my-account/login/',
          query: {
            redirect: this.$route.query.redirect,
            error: 'company',
          },
        });
        return;
      }

      await this.bustPriceCache();
      await this.clearOrderContactDetails();
      await this.getUserJobLists();

      this.AddRefreshUserSessionEvent(company.customerId, company.contactId);

      this.twoFaErrorMsg = null;
      this.$router.push({
        path: this.$route.query.redirect,
      });
    },
  },
};
