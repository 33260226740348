//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import translate from '~/mixins/translate';

export default {
  name: 'BasePricing',
  mixins: [translate],
  props: {
    sku: {
      type: String,
      required: true,
    },
    basePricing: {
      type: Object,
      required: true,
    },
    priceQuery: {
      type: Object,
      required: true,
    },
    customPricing: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      customPricing: undefined,
      fromLabel: this.$t('product.from'),
    };
  },
  computed: {
    hasBasePricing() {
      return (
        this.basePricing &&
        this.isValidPriceNumber(this.basePricing.skuRetailIncGst)
      );
    },
    hasDefaultPricing() {
      return this.hasBasePricing || this.hasLowestPrice;
    },
    hasCustomPricing() {
      return (
        this.customPricing &&
        this.isValidPriceNumber(this.customPricing.unitPrice)
      );
    },
    hasLowestPrice() {
      if (!this.basePricing?.webProductLowest) {
        return false;
      }

      return this.isValidPriceNumber(this.basePricing.webProductLowest.price);
    },
    hasHighestPrice() {
      if (!this.basePricing?.webProductHighest) {
        return false;
      }

      return this.isValidPriceNumber(this.basePricing.webProductHighest.price);
    },
    hasRangePrice() {
      if (!this.hasLowestPrice || !this.hasHighestPrice) {
        return false;
      }

      const lowest = this.basePricing.webProductLowest.price;
      const highest = this.basePricing.webProductHighest.price;

      return lowest !== highest;
    },
    basePrice() {
      if (this.hasBasePricing) {
        return this.formatPrice(this.basePricing.skuRetailIncGst);
      }

      return '';
    },
    lowestPrice() {
      if (this.hasLowestPrice) {
        return this.formatPrice(this.basePricing.webProductLowest.price);
      }

      return '';
    },
    hasDiscountedPrice() {
      if (!this.hasCustomPricing || !this.customPricing.unitPrice) {
        return false;
      }

      if (this.customPricing.unitPrice === this.customPricing.baseUnitPrice) {
        return false;
      }

      return this.isValidPriceNumber(this.customPricing.unitPrice);
    },
    displayUom() {
      // find sku of current price (base or lowest)
      let sku = this.hasLowestPrice
        ? this.basePricing.webProductLowest.sku
        : this.sku;

      const uom = this.findSkuUom(sku);
      return uom ? this.$uom(uom, 'productCard', 1, {}) : '';
    },
  },
  methods: {
    isValidPriceNumber(value) {
      return value && typeof value === 'number' && value > 0;
    },
    formatPrice(value) {
      return `$${value.toFixed(2)}`;
    },
    findSkuUom(sku) {
      return this.priceQuery.find(item => item.sku === sku)?.uom || '';
    },
  },
};
