import { AlgoliaRecommend, filterRecommendations } from '~/util/recommend';

export default {
  data() {
    return {
      error: null,
      isRecommending: false,
    };
  },
  methods: {
    configureAlgolia(indexType) {
      if (indexType === 'trending') {
        return {
          appId: this.$config.trending.appId,
          appKey: this.$config.trending.appKey,
          indexName: this.$config.trending.indexName,
          indexType,
        };
      } else if (indexType === 'together') {
        return {
          appId: this.$config.frequentlyBoughtTogether.appId,
          appKey: this.$config.frequentlyBoughtTogether.appKey,
          indexName: this.$config.frequentlyBoughtTogether.indexName,
          indexType,
        };
      }

      return {
        appId: this.$config.algoliaApplicationId,
        appKey: this.$config.algoliaSearchKey,
        indexName: this.$config.algoliaIndex,
        indexType,
      };
    },
    async getAlgoliaRecommendations(indexType, params) {
      if (this.isRecommending) return;
      this.isRecommending = true;

      let recommendations = [];
      const config = this.configureAlgolia(indexType);
      const AlgoliaApi = new AlgoliaRecommend(config);
      if (AlgoliaApi) {
        recommendations = await AlgoliaApi.getRecommendations(params);
      }
      this.isRecommending = false;
      return recommendations;
    },
    async getProductRecommendations({ products, variationIds, params }) {
      const recommendations = await this.getAlgoliaRecommendations(
        'related',
        params
      );

      // 2. Filter down refined results based on business criteria
      const filteredRecommendations = await filterRecommendations(
        recommendations,
        products,
        variationIds
      );

      return filteredRecommendations;
    },
  },
};
