import { render, staticRenderFns } from "./Media.vue?vue&type=template&id=217204dc&"
import script from "./Media.vue?vue&type=script&lang=js&"
export * from "./Media.vue?vue&type=script&lang=js&"
import style0 from "./Media.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZoomMediaInner: require('/home/node/frontend/components/ZoomMediaInner.vue').default,MediaInner: require('/home/node/frontend/components/MediaInner.vue').default})
