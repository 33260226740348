export default ({ store }) => {
  window.onNuxtReady(async () => {
    const maybeRefreshToken = async () => {
      try {
        await store.dispatch('users/maybeRefeshAccessToken');
      } catch (e) {
        console.log(e);
      }
    };

    maybeRefreshToken();
    
    window.addEventListener('click', maybeRefreshToken);

    // window.addEventListener('scroll', maybeRefreshToken);

    window.addEventListener('popstate', maybeRefreshToken);
  });
};
