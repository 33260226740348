//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EspotMixins from '~/mixins/espotBanners';

export default {
  mixins: [
    EspotMixins
  ],
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    current() {
      let path = this.$route.path;
      if (path.charAt(path.length - 1) !== '/') path += '/';
      return this.$store.getters['sitemap/getPage'](path);
    },
    statusCode () {
      return (this.error && this.error.statusCode) || 500;
    },
    message () {
      return this.error.message;
    },
  },
  head () {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0'
        }
      ]
    };
  }
};
