export default class Storage {

  constructor(storage) {
    this.storage = storage;
  }

  setItem(key, value) {
    if (!this.storage) {
      return undefined;
    }

    try {
      if (value?.data?.unitPrice !== null) {
        this.storage.setItem(key, JSON.stringify(value));
      }
    } catch (e) {
      console.warn(e);
      return undefined;
    }

    return value;
  }

  getItem(key) {
    if (!this.storage) {
      return undefined;
    }

    let value;

    try {
      value = this.storage.getItem(key);
    } catch (e) {
      console.warn(e);
      return undefined;
    }

    if (typeof value === 'object') {
      return value;
    }
    
    let parsed;
    try {
      parsed = JSON.parse(value);
    } catch (e) {
      return undefined;
    }

    return parsed;
  }

  removeItem(key) {
    try {
      this.storage.removeItem(key);
    } catch (e) {
      console.warn(e);
      return undefined;
    }

    return true;
  }

}