//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import EspotMixins from '~/mixins/espotBanners';
import autoLoginMixins from '~/mixins/autoLogin';

export default {
  mixins: [EspotMixins, autoLoginMixins],
  watch: {
    $route(to) {
      this.updateIsSearchBarVisible(to.path);
    },
  },
  beforeMount() {
    this.updateIsSearchBarVisible(this.$router.currentRoute.path);
    this.updateIsMyAccountVisible(true);
  },
  async mounted() {
    await this.isRefreshAutoLoginSuccess();
  },
  methods: {
    ...mapActions({
      updateIsMyAccountVisible: 'layout/updateIsMyAccountVisible',
    }),
    updateIsSearchBarVisible(path) {
      this.$store.dispatch(
        'layout/updateIsSearchBarVisible',
        !(path === '/search/')
      );
    },
  },

  head() {
    return {
      htmlAttrs: {
        lang: 'en',
      },
      link: [
        {
          rel: 'preconnect',
          href: 'https://www.googletagmanager.com',
        },
        {
          rel: 'preconnect',
          href: 'https://www.google-analytics.com',
        },
      ],
    };
  },
};
