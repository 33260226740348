import sharedVariables from '~/util/shared-variables.js';
import Vue from 'vue';
import { mapGetters } from 'vuex';

/**
 * @returns {null} Sets the screen size in the body class for espot
 */
function setScreenSizeClass() {
  let screen = 'xx-large';
  const width = window.innerWidth;

  if (width <= sharedVariables.sm_max) {
    screen = 'small';
  } else if (width <= sharedVariables.md_max) {
    screen = 'medium';
  } else if (width <= sharedVariables.lg_max) {
    screen = 'large';
  } else if (width <= sharedVariables.xl_max) {
    screen = 'x-large';
  }

  document.body.classList.remove('small', 'medium', 'large', 'x-large', 'xx-large');
  document.body.classList.add(screen);
}

/**
 * @param {object} customer customer object with relevant details from stores
 * @returns {null} Sets the trade values for espots in body class
 */
export function setTradeEspotClasses(customer) {
  document.body.classList.remove('logged-out', 'logged-in', 'has-credit', 'trade', 'cash');

  if (customer.typeAcctAr) {
    document.body.classList.add('logged-in');
    if (customer.creditLimit && customer.creditLimit > 0) {
      document.body.classList.add('has-credit');
    }
    if (customer.typeAcctAr === 'A') {
      document.body.classList.add('trade');
    } else if (customer.typeAcctAr === 'C') {
      document.body.classList.add('cash');
    }
  } else {
    document.body.classList.add('logged-out');
  }
}

export default () => {
  window.onNuxtReady(() => {
    setScreenSizeClass();
  });

  window.onresize = () => setScreenSizeClass();
};

Vue.mixin({
  computed: {
    ...mapGetters({
      getCustomer: 'users/getCustomer'
    }),
  },
  watch: {
    '$store.state.users.customer': {
      async handler() {
        setTradeEspotClasses(this.getCustomer);
      }
    }
  },
});