export const BRANCH_ID_PREFIX = '1-';

export const debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * @param {object} object Object to clone
 *
 * @returns {object} Object
 */
export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

/**
 * @param {number} amount Amount eg. 10.111111111
 *
 * @returns {number} Floated amount
 */
export function round(amount) {
  return parseFloat(amount.toFixed(2));
}


/**
 * Formats a number as a dollar amount.
 *
 * @param {number} number - The number to format.
 * @returns {string} The formatted dollar amount.
 */
export function formatNumberAsDollarAmount(number) {
  return Number(parseFloat(number).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2,
  });
}
