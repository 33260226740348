export const SALESORDER_URL = '/users';

export const api = {
  async getSalesOrderByOrderNumber($axios, orderquoteNumber) {
    // Retrieve the quote/order by its number from the backend
    let response;
    let params = {
      orderNo: orderquoteNumber,
    };

    try {
      response = await $axios.get(
        `${SALESORDER_URL}/details/salesorder/listone`,
        {
          params,
        }
      );
    } catch (error) {
      // Network error in this case
      return {
        hasError: true,
        error,
      };
    }

    if (!response) {
      // Unknown error here
      // the users service will always response with
      // either data or error data
      return {
        hasError: true,
        error: new Error('Unknown Error'),
      };
    }

    if (response.status !== 200) {
      return {
        hasError: true,
        error: new Error(response.statusText),
      };
    }

    if (!response.data) {
      // At this point, response indicates no error
      // but we are not getting any data
      return {
        hasError: true,
        error: new Error('Unknown Error'),
      };
    }

    const data = response.data;
    if (data.error) {
      return {
        hasError: true,
        error: new Error(data.msg),
      };
    }

    return {
      hasError: false,
      data: data.data,
    };
  },

  async getSalesOrders($axios, params) {
    let response;

    try {
      response = await $axios.get(SALESORDER_URL + '/details/salesorder/list', {
        params,
      });
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;
    if (!data) {
      return undefined;
    }

    return data.data;
  },
  async updateSalesOrder($axios, params) {
    let response;
    const postData = params;

    try {
      response = await $axios.post(
        SALESORDER_URL + '/details/salesorder/update',
        {
          ...postData,
        }
      );
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }
    const data = response.data;
    if (!data) {
      return undefined;
    }
    return data.data;
  },
  async sendSalesOrderEmail($axios, payload) {
    let response;
    try {
      // #TODO: better url?
      response = await $axios.post(
        SALESORDER_URL + '/salesorder/email',
        payload
      );
    } catch (e) {
      // #TODO: handle error returned?
      console.error(e);
      return e;
    }

    return response.data;
  },
  async sendSalesOrderDownload($axios, payload) {
    let response;
    try {
      // #TODO: better url?
      response = await $axios.post(
        SALESORDER_URL + '/salesorder/stream',
        payload
      );
    } catch (e) {
      // #TODO: handle error returned?
      console.error(e);
      return e;
    }

    return response.data;
  },
};

export const actions = {
  async getSalesOrderByOrderNumber({ commit }, orderquoteNumber) {
    // Retrieve a single quote/order by its number
    let result = await api.getSalesOrderByOrderNumber(
      this.$axios,
      orderquoteNumber
    );
    if (!result.hasError) {
      commit('addSalesOrder', result.data);
    }

    // Return the fetched data or error
    // the return value should only be consumed internally in this slice of state
    return result;
  },

  async getSalesOrders({ commit }, { params }) {
    let fetchedSalesorders = await api.getSalesOrders(this.$axios, params);
    if (fetchedSalesorders) {
      commit('saveSalesOrders', fetchedSalesorders);
    }
    return fetchedSalesorders;
  },

  // This action update the salesorder on frameworks
  async updateSalesOrder(context, params) {
    let updatedsalesorder = await api.updateSalesOrder(this.$axios, params);

    return updatedsalesorder;
  },

  // This action update the salesorder in the vuex store only
  // payload is an array of salesorder
  async updateSalesOrdersLocal({ commit }, payload) {
    commit('updateSalesOrders', payload);
  },

  async updateQuoteById({ commit }, payload) {
    commit('updateQuoteById', payload);
  },

  // #TODO: is there a better way to handle this?
  async sendSalesOrderEmail(context, payload) {
    //eslint-disable-line no-unused-vars
    let result = await api.sendSalesOrderEmail(this.$axios, payload);

    return result;
  },
  async sendSalesOrderDownload(context, payload) {
    //eslint-disable-line no-unused-vars
    let result = await api.sendSalesOrderDownload(this.$axios, payload);

    return result;
  },

  async selectOneQuoteForPayment({ commit, dispatch }, quoteNo) {
    // Set the state to fetching
    commit('resetFetchingSelectedQuoteStatus');
    commit('setFetchingSelectedQuoteStatusToFetching');
    const result = await dispatch('getSalesOrderByOrderNumber', quoteNo);
    // If result is an error
    // set the error in the status
    if (result.hasError) {
      commit('setFetchingSelectedQuoteStatusError', result.error);
    }
    commit('setFetchingSelectedQuoteStatusToDone');
    commit('addSelectedQuotesForPayment', [quoteNo]);
  },

  async unselectOneQuoteForPayment({ commit }, quoteNo) {
    commit('removeSelectedQuotesForPayment', [quoteNo]);
  },

  clearSelectedQuotesForPayment({ commit }) {
    commit('clearSelectedQuotesForPayment');
  },
};

export const mutations = {
  saveSalesOrders(state, payload) {
    state.salesorders = payload;
  },

  // Payload contains an array of salesorders
  updateSalesOrders(state, payload) {
    payload.forEach(item => {
      const index = state.salesorders.findIndex(
        salesorder => salesorder.orderNo === item.orderNo
      );
      if (index !== -1) {
        state.salesorders[index] = item;
      }
    });
  },

  updateQuoteById(state, storedQuote) {
    const index = state.salesorders.findIndex(
      item => item.orderNo === storedQuote.id
    );

    if (index === -1) return;
    const quote = state.salesorders[index];
    if (storedQuote.newPo) {
      quote.customerOrderNo = storedQuote.newPo;
      quote.newPo = storedQuote.newPo;
    }

    if (storedQuote.newInstructions) {
      quote.instructions = storedQuote.newInstructions;
      quote.newInstructions = storedQuote.newInstructions;
    }

    if (storedQuote.newContactName) {
      quote.contactName = storedQuote.newContactName;
      quote.newContactName = storedQuote.newContactName;
    }

    if (storedQuote.newContactPhone) {
      quote.contactPhone = storedQuote.newContactPhone;
      quote.newContactPhone = storedQuote.newContactPhone;
    }

    state.salesorders[index] = quote;
  },

  // Add a single salesorder to the list
  // - update the salesorder if it already exists
  addSalesOrder(state, salesorder) {
    const index = state.salesorders.findIndex(
      item => item.orderNo === salesorder.orderNo
    );
    if (index === -1) {
      state.salesorders.push(salesorder);
    } else {
      state.salesorders[index] = salesorder;
    }
  },

  // Payload contains an array of ONLY the quoteNos
  addSelectedQuotesForPayment(state, payload) {
    payload.forEach(quoteNo => {
      if (state.selectedQuotesForPayment.includes(quoteNo)) return;

      state.selectedQuotesForPayment.push(quoteNo);
    });
  },

  // Payload is an array of quoteNos
  removeSelectedQuotesForPayment(state, payload) {
    // Get the quoteNos not in the removal list
    const filteredQuoteNos = state.selectedQuotesForPayment.filter(
      quoteNo => !payload.includes(quoteNo)
    );

    state.selectedQuotesForPayment = filteredQuoteNos;
  },

  // Set the selectedQutoesForPayment to empty
  clearSelectedQuotesForPayment(state) {
    state.selectedQuotesForPayment = [];
  },

  setFetchingSelectedQuoteStatus(state, { isFetching, hasError, error }) {
    state.fetchingSelectedQuoteStatus.isFetching = isFetching;
    state.fetchingSelectedQuoteStatus.hasError = hasError;
    state.fetchingSelectedQuoteStatus.error = error;
  },

  setFetchingSelectedQuoteStatusToFetching(state) {
    state.fetchingSelectedQuoteStatus.isFetching = true;
  },

  setFetchingSelectedQuoteStatusToDone(state) {
    state.fetchingSelectedQuoteStatus.isFetching = false;
  },

  setFetchingSelectedQuoteStatusError(state, error) {
    state.fetchingSelectedQuoteStatus.hasError = true;
    state.fetchingSelectedQuoteStatus.error = error;
  },

  resetFetchingSelectedQuoteStatus(state) {
    state.fetchingSelectedQuoteStatus.isFetching = false;
    state.fetchingSelectedQuoteStatus.hasError = false;
    state.fetchingSelectedQuoteStatus.error = null;
  },
};

export const getters = {
  getSalesOrders: state => state.salesorders,

  // Return a single item
  getSalesOrderByOrderNumber: state => orderNo => {
    return state.salesorders.find(salesorder => salesorder.orderNo === orderNo);
  },

  getSelectedQuotesForPayment: state => {
    return state.salesorders.filter(salesorder =>
      state.selectedQuotesForPayment.includes(salesorder.orderNo)
    );
  },

  getFetchingSelectedQuoteStatus: state => {
    return state.fetchingSelectedQuoteStatus;
  },
};

export const state = () => ({
  //#TODO
  // should use object with
  //  - The key being the quoteNo/orderNo
  //  - The value being an object with all the details
  // this way, we can directly access a quote/order without looping
  // through the array
  salesorders: [],

  // This contains an array of ONLY the quoteNos
  selectedQuotesForPayment: [],

  // When a quote is selected for payment
  // we first fetch the quote details from the frameworks-api
  // then the fetched quote will be added to the salesorders[]
  // then the selected quote No. will be added to the selectedQuotesForPayment[]
  // error can occur while fetching
  // - network error: exception will be thrown
  // - quote not found: server will return 200 status along with error data
  //
  // this piece of state record the status of the fetching process
  fetchingSelectedQuoteStatus: {
    isFetching: false,
    hasError: false,
    error: null,
  },
});
