export const STATEMENTS_URL = '/users';

export const api = {
  async getStatements($axios, params) {
    let response;

    try {
      response = await $axios.get(
        STATEMENTS_URL + '/details/statement/list',
        params
      );
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data.data;
  },
  async sendStatementEmail($axios, payload) {
    let response;
    try {

      // #TODO: better url..
      response = await $axios.post(
        STATEMENTS_URL + '/statement/email',
        payload
      );
    } catch (e) {

      // #TODO: handle error returned?
      console.error(e);
      return e;
    }

    return response.data;
  },
  async sendStatementDownload($axios, payload) {
    let response;
    try {

      // #TODO: better url?
      response = await $axios.post(
        STATEMENTS_URL + '/statement/stream',
        payload
      );
    } catch (e) {

      // #TODO: handle error returned?
      console.error(e);
      return e;
    }

    return response.data;
  },
};

export const actions = {
  async getStatements({ commit }, payload) {
    const params = { params: payload };
    let result = await api.getStatements(this.$axios, params);

    commit('setStatements', result);
    return result;
  },
  async sendStatementEmail(_, statementDate) {
    let result = await api.sendStatementEmail(this.$axios, statementDate);

    return result;
  },
  async sendStatementDownload(_, payload) {
    let result = await api.sendStatementDownload(this.$axios, payload);

    return result;
  },
};

export const mutations = {
  setStatements(state, payload) {
    state.statements = payload;
  },
};

export const getters = {
  getStatements: state => {
    return state.statements;
  },
};
export const state = () => ({
  statements: [],
});
