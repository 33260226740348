export const TIMBER_TALLY_ATTRIBUTE = 'length-meters';
export const UOM = 'uom';
export const IMAGE_SOURCE = 'internal';
export const IMAGE_FORMAT = 'default';
export const STOCK_STATUS = {
  ORDER_IN: 'order-in',
  IN_STOCK: 'in-stock',
  LIMITED_STOCK: 'limited-stock',
};
export const SAFE_DECIMAL_MULTIPLIER = 10000000;
export const STICK = 'STICK';
export const LINEAL_METER = 'LM';
export const LINEAL_METER_CONVERSION_FACTOR = 1;

/**
 * @param {array} choices Choices for user select
 * @param {string} newChoice The newly built choice for the dropdown select
 *
 * @returns {boolean} Boolean
 */
export function isChoiceUnique(choices, newChoice) {
  return !choices.some(choice => choice.value === newChoice);
}

/**
 *
 * @param {array} choices Choices for user selection
 * @param {string} value The new value which is likely taken from the query params
 *
 * @returns {boolean} Boolean
 */
export function isChoiceValid(choices, value) {
  return choices.some(choice => {
    return choice.value.toString() === value.toString();
  });
}

/**
 * @param {number} attrIndex attribute order number of the attr being checked
 * @param {string[]} attrVals values of the attributes
 * @param {Object} product attributes of the current product
 * @param {object} variation current variation
 * @param {Object} timberTallyLengths timber tally lengths of the erp sku, if any
 *
 * @return {boolean} Boolean
 */
export function isValidAttrChoice(
  attrIndex,
  attrVals,
  product,
  variation,
  timberTallyLengths
) {
  if (attrIndex > 0) {
    //To determine which dropdown should we check for timber tally lengths as well if the values don't match
    const lengthIndex = product.attributes.indexOf(TIMBER_TALLY_ATTRIBUTE);

    //Checking for values of the dropdowns
    for (let x = 0; x < attrIndex; x++) {
      const key = product.attributes[x];
      if (!key) continue;
      if (String(variation.attributes[key]) !== String(attrVals[x])) {
        if (lengthIndex === x && timberTallyLengths[variation.sku]) {
          if (
            !timberTallyLengths[variation.sku].lengths.includes(attrVals[x])
          ) {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }

  return true;
}

/**
 *
 * @param {object} product Product
 * @param {object} attribute1Model Atribute 1 model for forms
 * @param {object} attribute2Model Atribute 2 model for forms
 * @param {object} attribute3Model Atribute 3 model for forms
 * @param {object} attribute4Model Atribute 4 model for forms
 * @param {string[]} timberTallyLengths timber tally lengths of the erp sku, if any
 *
 * @returns {object|undefined} Erp from the product
 */
export function getErpChoice(
  product,
  attribute1Model,
  attribute2Model,
  attribute3Model,
  attribute4Model,
  timberTallyLengths
) {
  if (!product || !product.variations || !product.variations.length) {
    return undefined;
  }

  const attrVals = [
    attribute1Model.value,
    attribute2Model.value,
    attribute3Model.value,
    attribute4Model.value,
  ];

  const erps = product.variations;

  for (const erp of erps) {
    if (isValidAttrChoice(4, attrVals, product, erp, timberTallyLengths)) {
      return erp;
    }
  }

  return undefined;
}

/**
 * @param {object} variation Variation
 * @param {array} attributeModels Array of models
 * @param {object} lengthModel The length model
 *
 * @return {boolean} Is it valid
 */
export function isValidVariation(variation, attributeModels, lengthModel) {
  for (const attributeModel of attributeModels) {
    if (!attributeModel.key) {
      continue;
    }

    if (
      String(attributeModel.value) !==
      String(variation.attributes[attributeModel.key])
    ) {
      return false;
    }
  }

  if (lengthModel.value && variation.flags && variation.flags.timberTally) {
    if (
      String(variation.attributes[TIMBER_TALLY_ATTRIBUTE]) !==
      String(lengthModel.value)
    ) {
      return false;
    }
  }

  return true;
}

/**
 * @param {object} product Product
 * @param {object} attribute1Model Atribute 1 model for forms
 * @param {object} attribute2Model Atribute 2 model for forms
 * @param {object} attribute3Model Atribute 3 model for forms
 * @param {object} attribute4Model Atribute 4 model for forms
 * @param {object} lengthModel Length model
 *
 * @return {object} Variation
 */
export function mapVariationFromProductToCartProduct(
  product,
  attribute1Model,
  attribute2Model,
  attribute3Model,
  attribute4Model,
  lengthModel
) {
  if (!product || !product.variations || !product.variations.length) {
    return undefined;
  }

  const attributeModels = [
    attribute1Model,
    attribute2Model,
    attribute3Model,
    attribute4Model,
  ];

  for (const variation of product.variations) {
    if (isValidVariation(variation, attributeModels, lengthModel)) {
      return variation;
    }
  }

  return undefined;
}

/**
 * Map a product to a Cart Item Product
 * @param {object} product - The Product object
 * @returns {object} The Cart Item Product
 */
export function mapProductToCartItemProduct(product) {
  if (!product) {
    return undefined;
  }

  return {
    id: product.id,
    state: product.state,
    slug: product.slug,
    content: product.content,
    attributes: product.attributes,
    categories: product.categories,
  };
}

/**
 * @param {object} erp Erp erp product
 * @param {number} selectUom Uom object
 *
 * @returns {object|undefined} Taly object from the erp erp
 */
export function getUomByUom(erp, selectUom) {
  if (!erp || !erp.tallies) {
    return undefined;
  }

  const uom = erp.tallies.find(uom => {
    return (
      uom.conversionFactor === selectUom.conversionFactor &&
      uom.uom === selectUom.uom
    );
  });

  if (!uom) {
    return undefined;
  }

  return uom;
}

/**
 * @param {object} uom The uom containing the uom and conversion factor
 *
 * @returns {boolean} Boolean
 */
export function isUomValidForChoice(uom) {
  let isValid = true;

  const parsed = parseFloat(uom.conversionFactor);

  if (isNaN(parsed) || parsed === 0) {
    isValid = false;
  }

  return isValid;
}

/**
 * @param {object} erp Erp erp product
 * @param {number} existingUom Uom
 *
 * @returns {boolean} Boolean
 */
export function isUomValid(erp, existingUom) {
  if (!erp || !erp.tallies || !existingUom) {
    return false;
  }

  const found = erp.tallies.some(uom => {
    if (
      uom.conversionFactor === existingUom.conversionFactor &&
      uom.uom === existingUom.uom
    ) {
      return true;
    }

    return false;
  });

  return found;
}

/**
 * @param {array} choices Choices for the pruoduct page
 * @param {boolean} isNumeric if the sorting should be treated as numeric
 *
 * @returns {void}
 */
export function sortChoices(choices, isNumeric) {
  let isArrayAllNumeric = !choices.some(c => isNaN(c.value));

  choices.sort((a, b) => {
    if (isNumeric && isArrayAllNumeric) {
      return parseFloat(a.value) - parseFloat(b.value);
    }

    return a.value.toString().localeCompare(b.value, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
}

/**
 * Removes empty groups and attributes. The database might return groups that have only
 * one attribute with an empty value, or groups with a mix of valid attributes and empty
 * attributes. Strip them all out here.
 *
 * @param {array} specifications array of groups and attributes from a product
 *
 * @returns {array} list of valid specifications
 */
export function excludeEmptyGroupsAndAttributes(specifications) {
  const filteredSpecs = [];
  if (specifications) {
    for (let i = 0; i < specifications.length; i++) {
      if (specifications[i].attrs) {
        const filteredAttributes = specifications[i].attrs.filter(
          attr => attr.values.filter(value => value).length > 0
        );
        if (filteredAttributes.length > 0) {
          filteredSpecs.push({
            ...specifications[i],
            attrs: filteredAttributes,
          });
        }
      }
    }
  }
  return filteredSpecs;
}

/**
 * @param {object} uom Uom
 *
 * @return {string} Uom as a string eg. KG|50, LM|1
 */
export function convertUomToUomString(uom) {
  if (!uom) {
    return undefined;
  }

  if (uom.uom === undefined || uom.conversionFactor === undefined) {
    return undefined;
  }

  return uom.uom + '|' + uom.conversionFactor;
}

/**
 * @param {string} string Uom as a string eg. KG|50, LM|1
 *
 * @returns {object} Uom
 */
export function convertUomStringToUom(string) {
  if (!string) {
    return undefined;
  }

  const array = string.split('|');

  if (array.length !== 2) {
    return undefined;
  }

  const uom = array[0];
  const conversionFactor = parseFloat(array[1]);

  if (!uom || !conversionFactor || isNaN(conversionFactor)) {
    return undefined;
  }

  return {
    uom: uom,
    conversionFactor: conversionFactor,
  };
}

/**
 * @param {number} qty The qty
 * @param {number} conversionFactor The conversion factor
 *
 * @returns {number} The converted total qty
 */
export function calcalateTotalQty(qty, conversionFactor) {
  const safeQty = parseInt(parseFloat(qty) * SAFE_DECIMAL_MULTIPLIER);
  const safeConversionFactor = parseInt(
    parseFloat(conversionFactor) * SAFE_DECIMAL_MULTIPLIER
  );

  return parseInt(Math.floor(safeQty / safeConversionFactor));
}

/**
 * @param {number} totalLength The total length
 * @param {number} baseLength The length
 *
 * @returns {number} Rounded up total length based on the length inserted
 */
export function roundLinealMeter(totalLength, baseLength) {
  if (totalLength === undefined || baseLength === undefined) {
    return undefined;
  }

  if (isNaN(parseFloat(totalLength))) {
    return undefined;
  }

  if (isNaN(parseFloat(baseLength))) {
    return undefined;
  }

  if (parseFloat(totalLength) < parseFloat(baseLength)) {
    return baseLength;
  }

  const safeTotalLength = parseInt(
    parseFloat(totalLength) * SAFE_DECIMAL_MULTIPLIER
  );
  const safeBaseLength = parseInt(
    parseFloat(baseLength) * SAFE_DECIMAL_MULTIPLIER
  );

  let newTotal = safeTotalLength;

  if (safeTotalLength % safeBaseLength > 0) {
    newTotal =
      safeBaseLength +
      safeBaseLength * Math.floor(safeTotalLength / safeBaseLength);
  }

  return parseInt(newTotal) / SAFE_DECIMAL_MULTIPLIER;
}

/**
 *
 * @param {string} value The uom from the option select eg. STICK|1
 *
 * @return {Boolean} is a custom stick uom
 */
export function isStickByUomString(value) {
  if (!value) {
    return false;
  }

  const uom = convertUomStringToUom(value);

  if (uom && uom.uom === STICK) {
    return true;
  }

  return false;
}

/**
 *
 * @param {string} value The uom from the option select eg. STICK
 *
 * @return {Boolean} is a custom stick uom
 */
export function isStickByUom(value) {
  if (!value) {
    return false;
  }

  return value === STICK;
}

/**
 *
 * @param {object|any} erp The erp product object
 * @param {object|any} uom The uom from the option select eg. STICK
 *
 * @return {Boolean} is a custom stick uom
 */
export function isLinealMeterPossible(erp, uom) {
  if (uom === STICK) {
    return false;
  }

  return erp.isTally;
}

/**
 * @param {object} product - erp product object
 *
 * @return {array} of erpIds
 */
export function getUniqueErpIdsFromProduct(product) {
  if (!product || !product.id) {
    return [];
  }

  if (!product.variations || !product.variations.length) {
    return [product.id];
  }

  const erpIds = product.variations.map(variation => {
    return variation.sku;
  });

  return [...new Set(erpIds)];
}

/**
 * Get an image ID from a product
 *
 * @param {object} product The product
 *
 * @return {string|null} the first product image slug
 */
export function getImageIdFromProduct(product) {
  let imageId = null;
  const productImages = product.images || null;
  if (productImages) {
    const mainImg = productImages.main[0] || null;
    if (!mainImg && productImages.variations) {
      const variations = Object.keys(productImages.variations) || null;
      if (variations?.length > 0) {
        variations.forEach(variantId => {
          const variantImages = productImages.variations[variantId];
          if (variantImages.length > 0 && !imageId) {
            imageId = productImages.variations[variantId][0];
          }
        });
      }
    } else {
      imageId = productImages.main[0];
    }
  }
  return imageId;
}

/**
 * Checks if the Length property is okay to display on the frontend
 * @param {string} length The Length property of the product
 * @returns {boolean} If the length value can be displayed on the frontend
 */
export function isLengthVisible(length) {
  if (String(length) === String(LINEAL_METER_CONVERSION_FACTOR)) {
    return false;
  }

  return true;
}

/**
 * Is Attribute Choice Visible for this ERP product
 * @param {*} erp - product object
 * @param {array} choices - available options
 * @returns {boolean} is choice visible?
 */
export function isAttributeChoiceVisible(erp, choices) {
  if (
    erp &&
    erp.isRandomLength &&
    choices.length === 2 &&
    choices[1] &&
    String(choices[1].value) === String(LINEAL_METER_CONVERSION_FACTOR)
  ) {
    return false;
  }

  return true;
}

/**
 * Is UOM Choice Visible for this ERP product
 * @param {array} choices - available options
 * @returns {boolean} is choice visible?
 */
export function isUomChoiceVisible(choices) {
  if (choices.length === 2 && choices[0].value === '') {
    return false;
  }

  return true;
}
