//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EspotMixins from '~/mixins/espotBanners';

export default {
  mixins: [EspotMixins],
};
