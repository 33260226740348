import { render, staticRenderFns } from "./BasePricing.vue?vue&type=template&id=5cda76c4&scoped=true&"
import script from "./BasePricing.vue?vue&type=script&lang=js&"
export * from "./BasePricing.vue?vue&type=script&lang=js&"
import style0 from "./BasePricing.vue?vue&type=style&index=0&id=5cda76c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cda76c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BdAnimatedLoadingIcon: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/BdAnimatedLoadingIcon.vue').default})
