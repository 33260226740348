//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ErrorPageSvg from '~/static/svg/error-page.svg';

export default {
  components: {
    ErrorPageSvg,
  },
};
