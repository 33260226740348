export const PAYMENT_URL = '/orders/order/payment';
export const ORDER_URL = '/orders/order';
export const ABANDONED_CART_EMAIL_URL = '/orders/abandoned-cart/send-email';
export const DELIVERY_URL = '/orders/delivery';
export const NATIONAL_DELIVERY_URL = '/orders/national-delivery';
export const ORDER_TRADE_CREDIT_URL = '/orders/order/tradecredit';
export const CREATE_ABANDONED_CART_URL = '/orders/abandoned-cart/create';
export const GET_ABANDONED_CART_URL = '/orders/abandoned-cart/id/';
export const REQUIRED_NAB_KEYS = [
  'EPS_ACTIONURL',
  'EPS_MERCHANT',
  'EPS_TXNTYPE',
  'EPS_REFERENCEID',
  'EPS_AMOUNT',
  'EPS_TIMESTAMP',
  'EPS_FINGERPRINT',
  'EPS_RESULTURL',
  'EPS_REDIRECT',
];
import {
  createStripePaymentIntentApi,
  createStripePaymentIntentAction,
} from './order/createPaymentIntent';
import {
  updateStripePaymentIntentApi,
  updateStripePaymentIntentAction,
} from './order/updatePaymentIntent';
import {
  createStripeOrderApi,
  createStripeOrderAction,
} from './order/createStripeOrder';
import zip from './order/paymentApi/zip';

const SIXTY_SECONDS = 60_000;

/**
 * Throw Any Delivery Method Errors to Sentry
 * @param {*} $sentry - Sentry instance
 * @param {*} payload - payload sent to the delivery endpoint
 * @param {*} errResponse - errors prop from the response
 *
 * @returns {void}
 */
function throwDeliveryCalcErrorsToSentry($sentry, payload, errResponse) {
  if (!errResponse) return;

  // merge arrays of errResponse.local and errResponse.national
  const allErrors = (errResponse.local || []).concat(
    errResponse.national || []
  );

  $sentry.setTag('apiPath', DELIVERY_URL);
  $sentry.setTag('pageType', 'checkout');
  $sentry.setTag('checkoutForm', 'delivery');

  // for each item in allErrors array, throw the same sentry code as the if statement above in this method
  allErrors.forEach(error => {
    const extra = {
      payload: JSON.stringify(payload),
      response: errResponse,
    };

    if (error.message) {
      extra.errorMessage = error.message;
    }

    if (error.code) {
      extra.errorCode = error.code;
    }

    if (error.sku) {
      extra.errorSku = error.sku;
    }

    $sentry.captureMessage('Checkout: API Delivery calculations failed', {
      level: 'error',
      extra,
    });
  });
}

export const api = {
  async getPayment($axios, paymentId) {
    let response;

    try {
      response = await $axios.get(PAYMENT_URL + '/id/' + paymentId);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },

  async getInvoicePayment($axios, paymentId) {
    let response;

    try {
      response = await $axios.get(PAYMENT_URL + '/id/invoice/' + paymentId);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },

  async getOrder($axios, orderId) {
    let response;

    try {
      response = await $axios.get(ORDER_URL + '/id/' + orderId);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },
  async postOrder($axios, payload, clientIp) {
    let config = {
      headers: {
        CLIENT_IP: clientIp,
      },
    };
    let response;
    try {
      response = await $axios.post(ORDER_URL, payload, config);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },

  // Endpoint for creating & verifying order prior to payment capture
  async postPaypalOrder($axios, payload, clientIp) {
    let config = {
      headers: {
        CLIENT_IP: clientIp,
      },
      timeout: SIXTY_SECONDS,
    };
    let response;
    try {
      response = await $axios.post('/orders/order/paypal', payload, config);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },

  // Endpoint for Creating a Stripe eCommerce order
  createStripeOrderApi,

  // Endpoint for creating a Stripe Payment Intent
  createStripePaymentIntentApi,

  // Endpoint for updating a Stripe Payment Intent
  updateStripePaymentIntentApi,

  // Endpoint for verifying paypal order, post-payment
  async postVerifyPaypalOrder($axios, payload, clientIp) {
    let config = {
      headers: {
        CLIENT_IP: clientIp,
      },
      timeout: SIXTY_SECONDS,
    };
    let response;
    try {
      response = await $axios.post(
        '/orders/payments/paypal/confirm',
        payload,
        config
      );
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },
  async postAbandonedEmails($axios, payload) {
    let response;
    try {
      response = await $axios.post(ABANDONED_CART_EMAIL_URL, payload);
    } catch (e) {
      console.error(e);
    }
    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },
  async postOrderTradeCredit($axios, payload) {
    let response;
    try {
      response = await $axios.post(ORDER_TRADE_CREDIT_URL, payload);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data;
  },
  async postDeliveryCalc($axios, $sentry, payload) {
    let response;
    try {
      response = await $axios.post(DELIVERY_URL, payload);
    } catch (e) {
      // check for standard catch error response
      if (e.error && !e.data && e.msg) {
        return {
          errors: {
            local: [
              {
                code: 'API_ERROR',
                message: e.msg,
              },
            ],
          },
        };
      }

      // check for delivery validation errrors
      if (e.response && e.response.status === 400) {
        // does data response exist?
        if (e.response.data.data && e.response.data.data.length > 0) {
          const firstErr = e.response.data.data[0];
          return {
            errors: {
              local: [
                {
                  code: 'POSTCODE_ERROR',
                  message: firstErr.message,
                },
              ],
            },
          };
        }
      }

      // No customised error responses, throw generic error
      if (!e.response?.data?.data?.errors) {
        $sentry.setTag('apiPath', DELIVERY_URL);
        $sentry.setTag('pageType', 'checkout');
        $sentry.setTag('checkoutForm', 'delivery');
        $sentry.captureMessage('Checkout: API Delivery calculations failed', {
          level: 'error',
          extra: {
            payload: JSON.stringify(payload),
            response: e?.response?.data || 'no response',
          },
        });

        return undefined;
      }

      // for each customised error - throw to sentry
      const errResponse = e.response.data.data.errors;

      // throw errors to sentry
      throwDeliveryCalcErrorsToSentry($sentry, payload, errResponse);

      return e.response.data.data;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    // Some Errors throw a 400 response, some come back in 200 responses
    if (response && response.data.data.errors) {
      // throw errors to sentry
      throwDeliveryCalcErrorsToSentry(
        $sentry,
        payload,
        response.data.data.errors
      );
    }

    return response.data.data;
  },
  async postNationalDeliveryCheck($axios, payload) {
    let response;

    try {
      response = await $axios.post(NATIONAL_DELIVERY_URL, payload);
    } catch (e) {
      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
};

export const utils = {
  mapDataToOrderFormData(total, checkout, items) {
    if (!items || !Object.keys(items).length) {
      throw new Error('No items');
    }

    return {
      total,
      cart: items,
      ...checkout,
    };
  },
  isValidNabResponse(result) {
    if (!result) {
      return false;
    }

    const data = result.data;

    if (!data) {
      return false;
    }

    const nab = data.nab;

    if (!nab) {
      return false;
    }

    const keys = REQUIRED_NAB_KEYS;
    for (const key of keys) {
      if (!nab[key]) {
        return false;
      }
    }

    return true;
  },

  // Check that pre-payment order creation succeeded
  isValidPaypalResponse(result) {
    if (!result) {
      return false;
    }

    const data = result.data;

    if (!data) {
      return false;
    }

    const paypal = data.paypal;

    if (!paypal) {
      return false;
    }

    return true;
  },

  // Check that Creating a Stripe eCommerce order succeeded
  isValidStripeResponse(result) {
    if (!result?.data?.stripe) {
      return false;
    }

    return true;
  },

  // Check that post-payment order verification succeeded
  isValidPaypalVerifyResponse(result) {
    if (!result) {
      return false;
    }

    return true;
  },
};

export const actions = {
  async getOrder(_, payload) {
    const { id } = payload;

    let result = await api.getOrder(this.$axios, id);

    return result;
  },

  // Request Stripe Payment Intent
  createStripePaymentIntentAction,

  // Update Stripe Payment Intent
  updateStripePaymentIntentAction,

  // Create bowens order and start zip checkout workflow
  async makeAnOrderWithZip(_, payload) {
    const cIp = payload.clientIp;
    delete payload.clientIp;

    let result = await zip.submitCreateOrderRequest(this.$axios, payload, cIp);
    if (result?.error) {
      return result;
    }

    if (!zip.isValidCreateOrderResponse(result)) {
      throw new Error('Zip Order payload not valid');
    }

    return result;
  },

  // Create order and verify it prior to payment capture
  async makeAnOrderWithPaypal(_, payload) {
    const cIp = payload.clientIp;
    delete payload.clientIp;
    let result = await api.postPaypalOrder(this.$axios, payload, cIp);

    if (result.error) {
      return result;
    }

    if (!utils.isValidPaypalResponse(result)) {
      throw new Error('Paypal payload not valid');
    }

    return result;
  },

  // Zip checkout was approved
  // verify an order and then capture payment
  async verifyAnOrderWithZip(_, payload) {
    const cIp = payload.clientIp;
    delete payload.clientIp;

    let result = await zip.submitConfirmOrderRequest(this.$axios, payload, cIp);

    if (result?.error) {
      return result;
    }

    if (!zip.isValidConfirmOrderResponse(result)) {
      result.error = true;
      result.data.error = new Error('Order verification was invalid');
    }

    return result;
  },

  // Action to create an Order for Stripe checkout
  createStripeOrderAction,

  // Verify paypal order, post-payment
  async verifyAnOrderWithPaypal(_, payload) {
    let result = await api.postVerifyPaypalOrder(this.$axios, payload);

    if (result.error) {
      return result;
    }

    if (!utils.isValidPaypalVerifyResponse(result)) {
      throw new Error('Paypal payment is not valid');
    }

    return result;
  },
  async makeAnOrderWithNab(_, payload) {
    const cIp = payload.clientIp;
    delete payload.clientIp;
    let result = await api.postOrder(this.$axios, payload, cIp);

    if (result.error) {
      return result;
    }

    if (!utils.isValidNabResponse(result)) {
      throw new Error('Nab payload not valid');
    }

    return result;
  },
  async createAbandonedCart(_, payload) {
    const { cartPayload, emailPayload } = payload;
    let response;
    try {
      response = await this.$axios.post(CREATE_ABANDONED_CART_URL, cartPayload);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data || !data.data || !data.data.id) {
      return undefined;
    }

    const abandonedCartId = data.data.id || null;

    if (!abandonedCartId) {
      return undefined;
    }

    try {
      response = await api.postAbandonedEmails(this.$axios, {
        id: abandonedCartId,
        email: emailPayload.email,
        subTotal: emailPayload.subTotal,
        items: emailPayload.items,
      });
    } catch (e) {
      console.error(e);
    }

    return data;
  },
  async getAbandonedCart(_, id) {
    if (!id) {
      return undefined;
    }

    let response;
    try {
      response = await this.$axios.get(`${GET_ABANDONED_CART_URL}${id}`);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }
    return data;
  },
  async reclaimAbandonedCart(_, id) {
    if (!id) {
      return undefined;
    }

    let response;
    try {
      response = await this.$axios.patch(
        `${GET_ABANDONED_CART_URL}${id}/reclaim`
      );
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }
    return data;
  },
  async makeAnOrderWithTradeCredit(_, payload) {
    let result = await api.postOrderTradeCredit(this.$axios, payload);

    return result;
  },
  async postDeliveryCalc(_, payload) {
    const response = await api.postDeliveryCalc(
      this.$axios,
      this.$sentry,
      payload
    );

    return response;
  },
  async postNationalDeliveryCheck(_, payload) {
    const response = await api.postNationalDeliveryCheck(this.$axios, payload);

    return response;
  },

  async getPayment(_, payload) {
    const { id } = payload;
    let result = await api.getPayment(this.$axios, id);
    return result;
  },

  async getInvoicePayment(_, payload) {
    const { id } = payload;
    let result = await api.getInvoicePayment(this.$axios, id);
    return result;
  },
};
