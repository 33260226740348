import { mapGetters } from 'vuex';
import { setTradeEspotClasses } from '~/plugins/espot-banners';

const espotMixin = {
  computed: {
    ...mapGetters({
      getCustomer: 'users/getCustomer'
    }),
  },
  async mounted() {
    setTradeEspotClasses(this.getCustomer);
  }
};

export default espotMixin;
