import dayjs from 'dayjs'

dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

dayjs.tz.setDefault('Australia/Melbourne')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
