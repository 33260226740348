import {
  STORE_DIVIDER,
  STORE_NAME,
  STORE_VERSION
} from '~/util/configSettings';
import Storage from '~/util/storage';

const META_URL = '/healthz/meta';
const STORE_VERSIONS = {
  pricing: STORE_VERSION
};
const BASE_KEY = STORE_NAME + STORE_DIVIDER + 'persist-history' + STORE_DIVIDER;

export const clearHistoryPerStoreByVersion = (currentVersion) => {
  const storage = new Storage(window.localStorage);

  for (const store in STORE_VERSIONS) {
    if (currentVersion === STORE_VERSIONS[store]) {
      continue;
    }

    const key = BASE_KEY + store;
    const history = storage.getItem(key);

    if (history) {
      for (const id of history) {
        storage.removeItem(id);
      }

      storage.setItem(key, []);
    }
  }
};

export const isAnyVersionDifferent = (currentVersion) => {
  return Object.values(STORE_VERSIONS).some(value => value !== currentVersion);
};

export const isTimeToCheck = (timestamp) => {
  return ((Date.now() - timestamp) / 1000) > 30;
};

let lastChecked = Date.now();
export default async ({ $axios, $config, route, redirect }) => {
  if (process.client && isTimeToCheck(lastChecked)) {
    let response;

    try {
      response = await $axios.get($config.baseUrl + META_URL);
      lastChecked = Date.now();
    } catch (e) {
      lastChecked = Date.now();
    }
    
    if (
      response &&
      response.data &&
      response.data.storeVersion &&
      isAnyVersionDifferent(response.data.storeVersion)
    ) {
      clearHistoryPerStoreByVersion(response.data.storeVersion);
      const url = $config.baseUrl + route.fullPath;
      redirect(url);
    }
  }
};
