export const state = () => ({
  isAddressModalOpen: false,
});

export const getters = {
  getAddressModal: state => {
    return state.isAddressModalOpen;
  },
};

export const mutations = {
  setAddressModal(state, payload) {
    state.isAddressModalOpen = payload;
  },
};
