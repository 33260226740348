//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    layout: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMaintenanceMode: false,
      text: '',
      timeLeft: '',
      intervalTimer: undefined,
      bannerTimeout: undefined,
      textTimeout: undefined,
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.updateMaintenance(this.getMaintenanceSettings);
    });
  },
  computed: {
    ...mapGetters({
      getMaintenanceSettings: 'settings/maintenance',
    }),
    displayText() {
      return this.getMaintenanceSettings.startTime === 'now'
        ? this.text
        : this.text.replace(/COUNTDOWN/g, this.timeLeft);
    },
  },
  methods: {
    ...mapActions({
      updatedMaintenanceSettings: 'settings/getMaintenanceModeSettings',
    }),
    async checkResize() {
      const banner = document.querySelectorAll('.page-banner');
      let bannerHeight = 0;

      if (banner.length) {
        bannerHeight = banner[0].offsetHeight;
      }

      if (this.layout === 'default') {
        const header = document.querySelectorAll('.header')[0];
        const headerSpacing = document.querySelectorAll('.header-spacing')[0];

        if (!header || !headerSpacing) {
          await new Promise(r => setTimeout(r, 250));
          this.checkResize();
          return;
        }

        header.style.top = bannerHeight + 'px';
        headerSpacing.style.marginTop =
          header.offsetHeight + bannerHeight + 'px';
      } else if (this.layout === 'checkout') {
        const cartHeader = document.querySelectorAll('.cart-header')[0];
        const cartHeaderSpacing = document.querySelectorAll('.spacer')[0];

        if (!cartHeader || !cartHeaderSpacing) {
          await new Promise(r => setTimeout(r, 250));
          this.checkResize();
          return;
        }

        cartHeader.style.top = bannerHeight + 'px';
        cartHeaderSpacing.style.paddingTop =
          cartHeader.offsetHeight + bannerHeight + 'px';
      }
    },
    setCountdown(endTime) {
      this.intervalTimer = setInterval(() => {
        const timeLeft = this.getTimeDiff(
          this.$dayjs(),
          this.convertToDateTime(endTime)
        );

        if (timeLeft <= 0) {
          this.timeLeft = '';
          clearInterval(this.intervalTimer);
          return;
        }

        this.setTimeLeft(timeLeft);
      }, 1000);
    },
    setText(settings) {
      if (settings.startTime === 'now') {
        this.text = settings.forcedMsg;
        return;
      }

      const endTime = this.computeEndTime(
        settings.startTime,
        settings.duration
      );
      let text = settings.upcoming ? settings.upcomingMsg : settings.activeMsg;

      text = text.replace(/START_TIME/g, settings.startTime);
      text = text.replace(/END_TIME/g, endTime);
      text = text.replace(/DURATION/g, settings.duration);

      if (text.indexOf('COUNTDOWN') > -1) {
        const countdownTime = settings.upcoming ? settings.startTime : endTime;
        this.setCountdown(countdownTime);
      }

      this.text = text;
    },
    setTimeLeft(timeLeft) {
      this.timeLeft = this.$dayjs.duration(timeLeft).format('HH:mm:ss');
    },
    computeEndTime(startTime, duration) {
      const convertedStartTime = this.$dayjs(
        this.convertToDateTime(startTime),
        'HH:mm:ss',
        true
      );

      return convertedStartTime.add(duration, 'minute').format('HH:mm:ss');
    },
    getTimeDiff(startTime, endTime) {
      return endTime.diff(startTime);
    },
    convertToDateTime(time, tomorrow = false) {
      const nowDate = tomorrow
        ? this.$dayjs()
            .add(1, 'day')
            .format('YYYY-MM-DD')
        : this.$dayjs().format('YYYY-MM-DD');

      return this.$dayjs(`${nowDate} ${time}`, 'YYYY-MM-DD HH:mm:ss');
    },
    clear() {
      clearInterval(this.intervalTimer);
      clearTimeout(this.bannerTimeout);
      clearTimeout(this.textTimeout);
    },
    async updateMaintenance(settings) {
      this.isMaintenanceMode = settings.isMaintenanceMode;

      if (this.isMaintenanceMode) {
        this.setText(settings);

        if (settings.startTime === 'now') {
          this.clear();
        } else {
          if (settings.upcoming) {
            clearTimeout(this.textTimeout);
            const remainingTimeMilli = this.getTimeDiff(
              this.$dayjs(),
              this.convertToDateTime(settings.startTime)
            );

            this.textTimeout = setTimeout(async () => {
              await new Promise(r => setTimeout(r, 500));

              const updatedSettings = {
                ...settings,
                upcoming: false,
              };

              this.setText(updatedSettings);
            }, remainingTimeMilli);
          }

          clearTimeout(this.bannerTimeout);
          const endTime = this.computeEndTime(
            settings.startTime,
            settings.duration
          );
          const startDateTime = this.convertToDateTime(settings.startTime);
          const endDateTime = this.convertToDateTime(settings.startTime);
          const tomorrow = startDateTime.isAfter(endDateTime);

          const remainingTimeMilli = this.getTimeDiff(
            this.$dayjs(),
            this.convertToDateTime(endTime, tomorrow)
          );

          this.bannerTimeout = setTimeout(async () => {
            await new Promise(r => setTimeout(r, 500));
            await this.updatedMaintenanceSettings();
          }, remainingTimeMilli);
        }
      } else {
        this.clear();
      }

      await new Promise(r => setTimeout(r, 25));

      this.checkResize();
    },
  },
  watch: {
    getMaintenanceSettings: {
      async handler(settings) {
        await this.updateMaintenance(settings);
      },
    },
    $route() {
      this.checkResize();
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.checkResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkResize);
  },
};
