import Vue from 'vue';
import utils from '~/util/utils.erp';

export const api = {
  async getErpById($axios, id) {
    const PRODUCT_ID_URL = '/products-erp/id/';
    const url = PRODUCT_ID_URL + id;

    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      console.log('ERP AXIOS REQUEST CATCH');
      console.log(e);

      return undefined;
    }

    const data = response.data;
    if (!data) {
      return undefined;
    }

    const erp = data.data;
    if (!erp) {
      return undefined;
    }

    erp.isLengthValidating = true;
    return erp;
  },
};

export const state = () => ({
  erps: {},
});

export const mutations = {
  saveErp(state, data) {
    Vue.set(state.erps, data.id, data.erp);
  },
};

export const getters = {
  getErpById: state => id => {
    if (!id) {
      return undefined;
    }

    for (const erpId in state.erps) {
      if (erpId.toLowerCase() === id.toLowerCase()) {
        return state.erps[erpId];
      }
    }

    return undefined;
  },
  getErpIsTally: state => id => {
    if (!id) {
      return false;
    }

    let erp = undefined;
    for (const erpId in state.erps) {
      if (erpId.toLowerCase() === id.toLowerCase()) {
        erp = state.erps[erpId];
      }
    }

    if (!erp || !erp?.id) return false;
    return utils.isTimberTally(erp);
  },
};

export const actions = {
  async getErpById({ getters, commit }, { id }) {
    let erp = getters.getErpById(id);

    if (!erp) {
      erp = await api.getErpById(this.$axios, id);

      if (erp) {
        commit('saveErp', {
          id: erp.id,
          erp: erp,
        });
      }
    }

    return erp;
  },
  async getErpStockAvailability({ getters }, args) {
    if (!args.id) return 0;

    const erp = getters.getErpById(args.id);
    if (!erp || !erp?.id) return 0;

    // BOW-659 - Can Deliver Same Day if closest store has stock
    let branchIds = (args.closestStore) ? [...args.closestStore] : [];
    
    // 1. Check isTally
    //2. if isTally;run timber stock
    if (utils.isTimberTally(erp)) {
      return utils.getTotalTimberStock(erp, args.length, branchIds);
    }

    //3. else; run regular stock
    return utils.getTotalStock(erp, branchIds);
  },
  async calculateRequestedQty(store, { qty, erp, length, uom }) {
    if (!utils.isTimberTally(erp) || uom === 'STICK') return qty;
    if (!utils.isValidQtyType(uom)) return 0;
    const rawQty = parseFloat(qty) / parseFloat(length);
    if (!rawQty) return 0;
    return Math.ceil(rawQty);
  },
};
