import Persist from '~/util/persist';
import Storage from '~/util/storage';
import { version } from '~/package.json';
const storage = process.client ? new Storage(window.localStorage) : undefined;
const persist = new Persist(
  'newCheckout/reviewCart',
  storage,
  version,
  process.client,
  {
    isExpirable: false,
  }
);

export const state = () => ({
  displayBranches: false,
  canContinue: true,
  nationalDeliveryCheck: null,
});

export const getters = {
  getDisplayBranches: state => {
    return state.displayBranches;
  },
  getCanContinue: state => {
    return state.canContinue;
  },
  getNationalDeliveryCheck: state => {
    return state.nationalDeliveryCheck;
  }
};

export const mutations = {
  setDisplayBranches(state, payload) {
    state.displayBranches = payload;

    persist.setItem('newCheckout/reviewCart', '', state);
  },
  setCanContinue(state, payload) {
    state.canContinue = payload;
  },
  setNationalDeliveryCheck(state, payload) {
    state.nationalDeliveryCheck = payload;
  }
};
