// Handle shared payment method state
export const state = () => ({
  isPaying: false,
});

export const getters = {
  getIsPaying: state => {
    return state.isPaying;
  },
};

export const mutations = {
  setIsPaying(state, payload) {
    state.isPaying = payload;
  },
};
