//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ArrowRight from '~/static/svg/arrow-right.svg';
import AdhesivesSealantsFillers from '~/static/svg/adhesives-sealants-fillers.svg';
import BuildingEssentials from '~/static/svg/building-essentials.svg';
import Cladding from '~/static/svg/cladding.svg';
import Clearance from '~/static/svg/clearance.svg';
import Decking from '~/static/svg/decking.svg';
import DoorWindowHardware from '~/static/svg/door-window-hardware.svg';
import DoorsJambsFrames from '~/static/svg/doors-jambs-frames.svg';
import Fasteners from '~/static/svg/fasteners.svg';
import Flooring from '~/static/svg/flooring.svg';
import FoundationFormwork from '~/static/svg/foundation-formwork.svg';
import FramingTimber from '~/static/svg/framing-timber.svg';
import GeneralHardware from '~/static/svg/general-hardware.svg';
import IndoorTimber from '~/static/svg/indoor-timber.svg';
import InteriorLining from '~/static/svg/interior-lining.svg';
import OutdoorTimber from '~/static/svg/outdoor-timber.svg';
import PaintsStains from '~/static/svg/paints-stains.svg';
import PlumbingBathroom from '~/static/svg/plumbing-bathroom.svg';
import SafetyWorkwear from '~/static/svg/safety-workwear.svg';
import Sheeting from '~/static/svg/sheeting.svg';
import Tools from '~/static/svg/tools.svg';
import PlaceHolderIcon from '~/static/svg/placeholder-icon.svg';
import ExpoSpecialsIcon from '~/static/svg/expo-specials-icon.svg';
import HomeGardenProducts from '~/static/svg/home-garden-products.svg';
import Timber from '~/static/svg/timber.svg';
import Roofing from '~/static/svg/roofing.svg';
import BulkBuys from '~/static/svg/bulk_buy_new.svg';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

export default {
  components: {
    ArrowRight,
    'adhesives-sealants-fillers': AdhesivesSealantsFillers,
    'building-essentials': BuildingEssentials,
    cladding: Cladding,
    clearance: Clearance,
    decking: Decking,
    'door-window-hardware': DoorWindowHardware,
    'doors-jambs-frames': DoorsJambsFrames,
    fasteners: Fasteners,
    flooring: Flooring,
    'foundation-formwork': FoundationFormwork,
    'framing-timber': FramingTimber,
    'general-hardware': GeneralHardware,
    'indoor-timber': IndoorTimber,
    'interior-lining': InteriorLining,
    'outdoor-timber': OutdoorTimber,
    'paints-stains': PaintsStains,
    'plumbing-bathroom': PlumbingBathroom,
    'safety-workwear': SafetyWorkwear,
    sheeting: Sheeting,
    tools: Tools,
    'expo-specials': ExpoSpecialsIcon,
    'placeholder-icon': PlaceHolderIcon,
    'home-garden-products': HomeGardenProducts,
    timber: Timber,
    roofing: Roofing,
    'bulk-buys': BulkBuys,
  },
  props: {
    showMegaMenu: {
      default: false,
      type: true,
    },
    current: {
      type: Object,
    },
  },
  data() {
    return {
      activeCategoryId: false,
      activeSubCategoryId: false,
      activeFourthCategoryId: false,
      activeFourthCategory: undefined,
      elem: false,
      source: 'internal',
      thirdCategoryIsClicked: false,
      fourthCategoryIsClicked: false,
    };
  },
  watch: {
    showMegaMenu(val) {
      if (val) {
        disableBodyScroll(this.elem);
      } else {
        enableBodyScroll(this.elem);
      }
    },
    $route(val1, val2) {
      if (val1 !== val2) {
        this.activeCategoryId = this.getActiveSubCatePage();
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: 'category/getHierarchicalCategories',
      getCategoryById: 'category/getCategory',
    }),
    activeCategorySlug() {
      if (!this.current || this.current.type !== 'product-category') {
        return false;
      }
      if (!this.current.parentId) {
        return this.current.slug;
      }
      let parent = this.getCategoryById(this.current.parentId);
      return parent.slug;
    },
  },
  mounted() {
    this.activeCategoryId = this.getActiveSubCatePage();
    this.elem = this.$el.querySelector('.mega-menu__inner');
    clearAllBodyScrollLocks();
  },
  methods: {
    scrollToTop() {
      this.$el.querySelector('.mega-menu__inner').scrollTop = 0;
    },
    isSvgExist(slug) {
      if (this.$options.components[slug]) {
        return true;
      }
      return false;
    },
    getActiveSubCatePage() {
      if (this.activeCategorySlug && this.categories) {
        let activeObject = this.categories.find(category => {
          return category.slug === this.activeCategorySlug;
        });
        return activeObject ? activeObject.id : this.categories[0].id;
      }

      if (this.categories?.length > 0) {
        return this.categories[0].id;
      }

      return false;
    },
    getParent(parentId) {
      if (this.categories[parentId]) {
        return this.categories[parentId];
      }
      return {
        name: '',
        slug: '',
      };
    },
    // First level cat
    handleTopCategoryClick(category) {
      if (category.items?.length === 0) {
        this.$emit('dismiss');
        this.$router.push(category.url);
        return;
      }

      this.scrollToTop();
      this.activeCategoryId = category.id;
      this.activeSubCategoryId = false;
      this.thirdCategoryIsClicked = false;
      this.fourthCategoryIsClicked = false;
    },
    // Second level cat
    handleSubCategoryWithChildrenClick(category) {
      this.scrollToTop();
      this.activeSubCategoryId = category.id;
      this.activeSubCategory = category;
      this.thirdCategoryIsClicked = true;
      this.fourthCategoryIsClicked = false;
    },
    handleSubCategoryClick() {
      this.$emit('dismiss');
      this.thirdCategoryIsClicked = false;
      this.fourthCategoryIsClicked = false;
      this.activeCategoryId = false;
      this.activeSubCategoryId = false;
      this.activeFourthCategoryId = false;
    },
    handleFourthCategory(category) {
      this.scrollToTop();
      this.activeFourthCategoryId = category.id;
      this.activeFourthCategory = category;
      this.thirdCategoryIsClicked = true;
      this.fourthCategoryIsClicked = true;
    },
    isLeaving() {
      this.scrollToTop();
      this.$emit('dismiss');
      this.thirdCategoryIsClicked = false;
      this.fourthCategoryIsClicked = false;
      this.activeCategoryId = false;
      this.activeSubCategoryId = false;
      this.activeFourthCategoryId = false;
    },
  },
};
