//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowRight from '~/static/svg/arrow-right.svg';
import BbSvg from '~/static/svg/benefit page icons/bb-account.svg';
import TradeSvg from '~/static/svg/benefit page icons/trade-account.svg';
import InfoSvg from '~/static/svg/info.svg';
import DigitalCardInfoSvg from '~/static/svg/digital-card-info.svg';

export default {
  components: {
    ArrowRight,
    BbSvg,
    TradeSvg,
    InfoSvg,
    DigitalCardInfoSvg,
  },
  data() {
    return {
      isFlipped: false,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    isCustomerTypeA: {
      type: Boolean,
      default: false,
    },
    customerName: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
    closeOnBackgroundClick: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    backgroundClicked() {
      if (this.closeOnBackgroundClick) {
        this.close();
      }
    },
    handleRedirect() {
      return (location.href = '/my-account/');
    },
    handleClick() {
      this.isFlipped = true;
    },
    handleBack() {
      this.isFlipped = false;
    },
    close() {
      this.$emit('close');
    },
  },
  watch: {
    showModal: {
      handler() {
        if (this.showModal) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = '';
      },
    },
  },
};
