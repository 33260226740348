import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default ({ $dayjs }) => {
  $dayjs.extend(isBetween);
  $dayjs.extend(isSameOrBefore);
  $dayjs.extend(duration);
  $dayjs.extend(customParseFormat);
};