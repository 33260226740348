import Vue from 'vue';

export const state = () => ({
  forms: {},
  formsResponse: {}
});

export const mutations = {
  syncFromStorage(state, data) {
    if (!state.forms) {
      Vue.set(state, 'forms', {});
    }
    Vue.set(state.forms, data.id, data.form);
  },
  addForm(state, params) {
    Vue.set(state.forms, params.id, params.data);
  },
  setFormFieldValue(state, data) {
    if (!state.formsResponse[data.formID]) {
      Vue.set(state.formsResponse, data.formID, {});
    }
    Vue.set(state.formsResponse[data.formID], data.fieldID, {
      type: data.type,
      key: data.fieldID,
      value: data.value
    });
  },
  removeFormFieldValue(state, data) {
    if (state.formsResponse[data.formID]) {
      Vue.delete(state.formsResponse[data.formID], data.fieldID);
    }
  },
  clearForm(state, data) {
    if (state.formsResponse[data.formID]) {
      Vue.delete(state.formsResponse, data.formID);
    }
  }
};

export const getters = {
  getForm: state => id => {
    return state.forms[id];
  },
  getFormResponse: state => id => {
    return state.formsResponse[id];
  },
  getFormFieldsValue: state => (id, fieldId) => {
    if (state.formsResponse[id]) {
      return state.formsResponse[id][fieldId];
    }
    return null;
  },
  getCurrentFieldValue: state => (id, fieldId) => {
    if (state.formsResponse[id]) {
      if (state.formsResponse[id][fieldId]) {
        return state.formsResponse[id][fieldId].value;
      }
    }
    return null;
  }
};

export const actions = {
  syncToStore({ commit }, { key, data }) {
    commit('syncFromStorage', {
      id: key,
      form: data
    });
  },
  setFormFieldValue({ commit }, { fieldID, formID, value, type }) {
    commit('setFormFieldValue', {
      fieldID,
      formID,
      value,
      type
    });
  },
  removeFormFieldValue({ commit }, { fieldID, formID }) {
    commit('removeFormFieldValue', {
      fieldID,
      formID
    });
  },
  getFormResponse(state, { id }) {
    return state.getters.getFormResponse(id);
  },
  async getForm(state, id) {
    let form = state.getters.getForm(id);

    //To do also check for errors
    if (
      form !== undefined &&
      this.$dayjs().diff(this.$dayjs(form.time_cache), 'second') <
      parseInt(state.rootState.cacheTime)
    ) {
      return form;
    }

    // Handle any api errors gracefully
    try {
      let res = await this.$axios.get('/cms/gf/v2/forms/' + id);
      res.data.time_cache = this.$dayjs();
      state.commit('addForm', {
        id: id,
        data: res.data
      });
      return res.data;
    } catch (e) {
      if (e.response) {
        this.$sentry.captureException(e, {
          level: 'error',
          extra: {
            error: true,
            msg: e.message
          },
        });
        return;
      }
    }
 
  },
  async submitForm(state, { id, data }) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    let response = await this.$axios.$post(
      '/cms/gf/v2/forms/' + id + '/submissions',
      data,
      config
    );
    return response;
  },
  clearForm({ commit }, { formID }) {
    commit('clearForm', { formID });
  }
};
