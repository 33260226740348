const validTimberQuantityTypes = ['STICK', 'LM'];

const isTimberTally = erp => {
  if (!erp?.id) return false;
  return erp.isTally;
};

const isValidTimberLength = (erp, selectedLength) => {
  const timberLengths = Object.keys(erp.lengths);
  if (timberLengths?.length === 0) return false;

  let hasLength = false;
  timberLengths.forEach(length => {
    if (parseFloat(length) === parseFloat(selectedLength)) hasLength = true;
  });

  return hasLength;
};

const getTotalStock = (erp, branchIds = []) => {
  if (!erp?.id) return 0;

  const filterBranches = [...branchIds];

  let branches = Object.keys(erp.stock);
  if (branches?.length <= 0) return 0;

  let totalStock = 0;
  branches.forEach(ix => {
    if (
      !erp.stock[ix]?.availableStock ||
      (filterBranches.length > 0 && !filterBranches.includes(parseInt(ix)))
    ) {
      return;
    }

    const branchStock = erp.stock[ix].availableStock;
    totalStock += branchStock;
  });

  return totalStock;
};

const getTotalTimberStock = (erp, selectedLength, branchIds = []) => {
  if (!erp?.id) return 0;
  if (!isValidTimberLength(erp, selectedLength)) return 0;

  const filterBranches = [...branchIds];

  let branches = Object.keys(erp.lengthStock);
  if (branches?.length <= 0) return 0;

  let totalStock = 0;
  branches.forEach(ix => {
    if (
      !erp.lengthStock[ix] ||
      (filterBranches.length > 0 && !filterBranches.includes(parseInt(ix)))
    ) {
      return;
    }
    const lengths = Object.keys(erp.lengthStock[ix]);
    if (lengths?.length <= 0) return;

    lengths.forEach(length => {
      if (parseFloat(length) === parseFloat(selectedLength)) {
        if (!erp.lengthStock[ix][length]?.stock) return;
        const lengthStock = erp.lengthStock[ix][length]?.stock;
        totalStock += lengthStock;
      }
    });
  });

  return totalStock;
};

const isValidQtyType = (type = null) => {
  if (!type) return null;
  return validTimberQuantityTypes.indexOf(type) >= 0;
};

const calculateRemainingStock = (totalStock, length, uom, isTally) => {
  let remainingStock = totalStock;

  // Calculate remaining timber stick QTY into "total meters of length" value
  if (uom === 'LM' && isTally) {
    let total = parseFloat(totalStock) * parseFloat(length);

    // Round result to a number with 2 decimal points
    remainingStock = `${Math.round((total + Number.EPSILON) * 100) / 100}m`;
  }

  return remainingStock;
};

export default {
  isTimberTally,
  getTotalStock,
  getTotalTimberStock,
  isValidQtyType,
  calculateRemainingStock,
};
