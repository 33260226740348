export const ACCOUNTS_URL = '/accounts';

export const api = {
  async getAccountManager($axios) {
    let response;

    try {
      response = await $axios.get(ACCOUNTS_URL + '/account-manager');
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data.data;
  },
  async getCreditManager($axios, branchId) {
    let response;

    try {
      response = await $axios.get(ACCOUNTS_URL + '/credit-manager/' + branchId);
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data.data;
  }
};

export const actions = {
  async getAccountManager({ commit }) {
    let result = await api.getAccountManager(this.$axios);

    commit('setAccountManager', result);
    return result;
  },
  async getCreditManager({ rootState, commit }) {
    const { branchId } = rootState.users.customer;

    let result = await api.getCreditManager(this.$axios, branchId);

    commit('setCreditManager', result);
    return result;
  }
};

export const mutations = {
  setAccountManager(state, payload) {
    state.accountManager = payload;
  },
  setCreditManager(state, payload) {
    state.creditManager = payload;
  }
};

export const getters = {
  getAccountManager: state => {
    return state.accountManager;
  },
  getCreditManager: state => {
    return state.creditManager;
  }
};
export const state = () => ({
  accountManager: {},
  creditManager: {}
});
