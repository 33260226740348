import _ from 'lodash';

export const state = () => ({
  cms: {},
  uom: {},
  maintenance: {}
});

export const mutations = {
  setCmsSettings(state, data) {
    state.cms = data;
  },
  setUomSettings(state, data) {
    state.uom = data;
  },
  setMaintenanceSettings(state, data) {
    state.maintenance = data;
  }
};

export const getters = {
  cms: state => {
    return state.cms;
  },
  uom: state => {
    return state.uom;
  },
  maintenance: state => {
    return state.maintenance;
  }
};

export const api = {
  async getMaintenanceModeSettings($axios) {
    const url = '/settings/maintenance/';
    
    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      console.error(e);
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    const settings = data.data;

    if (!settings) {
      return undefined;
    }

    return settings;
  },
};

export const actions = {
  async getMaintenanceModeSettings({ commit, getters }) {
    const currentSettings = getters.maintenance;
    const settings = await api.getMaintenanceModeSettings(this.$axios);

    if (settings && !_.isEqual(settings, currentSettings)) {
      commit('setMaintenanceSettings', settings);
    }
  },

  async fetchCmsSettings({ commit }) {
    const cmsSettingsCall = await axios.get('/cms/general/v1/settings');
    let cmsSettings = cmsSettingsCall.data;
    if (cmsSettings) {
      commit('setCmsSettings', cmsSettings);
    }
  },
};
