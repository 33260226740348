export const api = {
  async getInvoiceSyncDetail($axios) {
    let response;

    try {
      response = await $axios.get('/users/invoice-sync/details', {
        validateStatus: function(status) {
          return (status >= 200 && status < 300) || 400;
        },
      });
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;
    if (!data) {
      return undefined;
    }

    if (data.error) {
      return data;
    }

    return data.data;
  },

  async getInvoiceSyncEnable($axios) {
    let response;

    try {
      response = await $axios.get('/users/invoice-sync/enable', {
        validateStatus: function(status) {
          return (status >= 200 && status < 300) || 400;
        },
      });
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;
    if (!data) {
      return undefined;
    }

    if (data.error) {
      return data;
    }

    return data.msg;
  },

  async getInvoiceSyncDisable($axios) {
    let response;

    try {
      response = await $axios.get('/users/invoice-sync/disable', {
        validateStatus: function(status) {
          return (status >= 200 && status < 300) || 400;
        },
      });
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;
    if (!data) {
      return undefined;
    }
    return data.msg;
  },
};

export const actions = {
  async getInvoiceSyncDetail() {
    let resp = await api.getInvoiceSyncDetail(this.$axios);

    return resp;
  },

  async getInvoiceSyncEnable() {
    let resp = await api.getInvoiceSyncEnable(this.$axios);

    return resp;
  },

  async getInvoiceSyncDisable() {
    let resp = await api.getInvoiceSyncDisable(this.$axios);

    return resp;
  },
};
