import Vue from 'vue';

export const api = {
  async getProductById($axios, id) {
    const PRODUCT_ID_URL = '/products/id/';
    const url = PRODUCT_ID_URL + id;

    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      console.log('Axios Error: getProductByID');
      console.error(e);
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    const product = data.data;

    if (!product) {
      return undefined;
    }

    return product;
  },
  async getProductBySlug($axios, slug, query) {
    let queryParams = '';
    if (query) {
      // Is Variant Set
      if (query.q) delete query.q;
      if (query.uom) delete query.uom;
      let params = [];
      Object.keys(query).forEach(attribute => {
        if (query[attribute] !== undefined) {
          params.push(`${attribute}=${query[attribute]}`);
        }
      });

      if (params.length > 0) queryParams = '?' + params.join('&');
    }

    const PRODUCT_SLUG_URL = '/products/slug/';
    const url = PRODUCT_SLUG_URL + slug + queryParams;

    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      return undefined;
    }
    const data = response.data;

    if (!data) {
      return undefined;
    }

    const product = data.data;

    if (!product) {
      return undefined;
    }

    return product;
  },
  async addEmailForAlert($axios, payload) {
    let response;
    try {
      response = await $axios.post('/products/restock-alert', payload);
    } catch (error) {
      console.log(error);
      return;
    }
    return response.data;
  },
};

export const state = () => ({
  products: {},
});

export const mutations = {
  saveProduct(state, data) {
    Vue.set(state.products, data.id, data.product);
  },
};

export const getters = {
  getProductById: state => id => {
    for (const productId in state.products) {
      if (productId === id) {
        return state.products[productId];
      }
    }

    return undefined;
  },
  getProductBySlug: state => slug => {
    for (const productId in state.products) {
      if (state.products[productId].slug === slug) {
        return state.products[productId];
      }
    }

    return undefined;
  },
};

export const actions = {
  async getProductById({ getters, commit }, { id }) {
    let product = getters.getProductById(id);

    if (!product) {
      product = await api.getProductById(this.$axios, id);

      if (product) {
        commit('saveProduct', {
          id: product.id,
          product: product,
        });
      }
    }

    return product;
  },
  async getProductBySlug({ getters, commit }, { slug, query }) {
    let product = getters.getProductBySlug(slug);

    if (!product) {
      product = await api.getProductBySlug(this.$axios, slug, query);
      if (product) {
        commit('saveProduct', {
          id: product.id,
          product: product,
        });
      }
    }

    return product;
  },
  async addEmailForAlert(context, payload) {
    //eslint-disable-line no-unused-vars
    let result = await api.addEmailForAlert(this.$axios, payload);

    return result;
  },
};
