import { api } from '../order';

/**
 * Request Stripe Payment Intent via API
 * @param {Object} $axios An instance of axios
 * @param {Object} payload Object containing the Order Total
 * @param {String} clientIp The IP address of the user
 * @returns {undefined|Object} undefined on error, object on success
 */
export async function createStripePaymentIntentApi($axios, payload, clientIp) {
  let config = {
    headers: {
      CLIENT_IP: clientIp,
    },
    timeout: 10_000, // 10 seconds
  };
  let response;

  try {
    response = await $axios.post('/orders/order/stripe/payment-intent', payload, config);
  } catch (e) {
    console.error(e);
  }

  if (!response) {
    return undefined;
  }

  const data = response.data;

  if (!data) {
    return undefined;
  }

  return data;
}

/**
 * Vuex Action for requesting a Stripe Payment Intent
 * @param {Object} _ Vuex Context
 * @param {Object} payload Request object for Payment Intent
 * @returns {undefined|Object} undefined on error, object on success
 */
export async function createStripePaymentIntentAction(_, payload) {
  const cIp = payload.clientIp;
  delete payload.clientIp;
  let result = await api.createStripePaymentIntentApi(this.$axios, payload, cIp);

  return result;
}
