import shareMutations from 'vuex-shared-mutations';

export default ({ store }) => {
  window.onNuxtReady(() => {
    shareMutations({
      predicate: [
        'cart/addItem',
        'cart/addQtyToItem',
        'cart/updateQtyForItem',
        'cart/updatePricingForItem',
        'cart/removeItem',
        'cart/updateItemsCount'
      ]
    })(store);
  });
};