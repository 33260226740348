import Vue from 'vue';

export const state = () => ({
  bulletins: {}
});

export const mutations = {
  addBulletin(state, data) {
    if (data.id) {
      Vue.set(state.bulletins, data.id, data);
    }
  }
};

export const getters = {
  getBulletin: (state) => (id) => {
    return state.bulletins[id] ? state.bulletins[id]: null;
  },
};

export const actions = {
  syncToStore({ commit }, {key, data}) {
    commit('addBulletin', {
      key,
      data
    });
  },
  async getBulletin({ getters, commit }, { id }) {
    if (getters.getBulletin(id)) {
      return getters.getBulletin(id);
    }
    let api_result = await this.$axios.get(`/cms/post/v1/${id}`);
    let data = api_result.data;
    if (data.error === true) {
      return null;
    }
    data.time_cache = this.$dayjs();
    commit('addBulletin', data);
    return getters.getBulletin(id);
  }
};
