//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    href: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      cssClasses: 'chr-button',
    };
  },
  methods: {
    emit(e) {
      this.$emit('click', e);
    },
  },
};
