//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import CartIcon from '~/static/svg/cart.svg';

export default {
  components: {
    CartIcon,
  },
  computed: {
    ...mapGetters({
      getItemsCount: 'cart/getItemsCount',
    }),
    getCount() {
      const count = this.getItemsCount();

      if (count > 9) {
        return '9+';
      }

      return count;
    },
  },
};
