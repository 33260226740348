const PRODUCTS_META_URL = '/products-meta/';

export const state = () => ({
  attributes: undefined
});

export const mutations = {
  saveAttributes(state, data) {
    state.attributes = data;
  }
};

export const getters = {
  getAllAttributes: state => () => {
    return state.attributes;
  },
  getAttributeBySlug: state => slug => {
    return state.attributes ? state.attributes[slug] : undefined;
  },
  getAttributeName: state => slug => {
    if (state.attributes && state.attributes[slug]!== undefined && state.attributes[slug].name.length) {
      return state.attributes[slug].name;
    }
    return slug;
  }
};

export const api = {
  async getAllAttributes($axios) {
    let response;
    const attributesUrl = PRODUCTS_META_URL + 'attributes';

    try {
      response = await $axios.get(attributesUrl);
    } catch (e) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    const attributes = data.data;

    if (!attributes) {
      return undefined;
    }

    return attributes;
  }
};

export const actions = {
  async getAllAttributes({ getters, commit }) {
    let attributes = getters.getAllAttributes();

    if (!attributes) {
      attributes = await api.getAllAttributes(
        this.$axios
      );

      if (attributes) {
        commit('saveAttributes', attributes);
      }
    }

    return attributes;
  }
};
