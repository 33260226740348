import Vue from 'vue';
import Storage from 'vue-ls';

const options = {
  namespace: 'bowens__',
  name: 'ls',
  storage: 'local',
};

Vue.use(Storage, options);
