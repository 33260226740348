import Vue from 'vue';

export const state = () => ({
  blogCategories: {},
  blogCategoriesTimeCache: {},
  blogs: {},
  blogsByCategory: {},
  blogList: {},
  paginations: {
    pages: 1,
    current_page: 1
  }
});

export const mutations = {
  addBlog(state, data) {
    if (data.id) {
      Vue.set(state.blogs, data.id, data);
    }
  },
  addToBlogList(state, data) {
    Vue.set(state.blogList, 'page_' + data.page, data.posts);
  },
  addToBlogCategory(state, data) {
    Vue.set(state.blogCategories, data.id, data.data);
  },
  addToBlogCategoriesTimeCache(state, data) {
    Vue.set(state.blogCategoriesTimeCache, data.time_cache);
  },
  addToBlogsByCategory(state, data) {
    if (!state.blogsByCategory[data.cat_id]) {
      Vue.set(state.blogsByCategory, data.cat_id, {});
    }
    Vue.set(state.blogsByCategory[data.cat_id], 'page_' + data.page, data.posts);
  },
  setCurrentPaginations(state, data) {
    Vue.set(state.paginations, 'pages', data.pages);
    Vue.set(state.paginations, 'current_page', data.current_page);
  }
};

export const getters = {
  getBlogs(state) {
    return state.blogs ? state.blogs : null;
  },
  getBlogList(state) {
    return state.blogList;
  },
  getBlog: (state) => (id) => {
    return state.blogs[id] ? state.blogs[id] : null;
  },
  getBlogCategories(state) {
    return state.blogCategories ? state.blogCategories : null;
  },
  getBlogCategoriesTimeCache(state) {
    return state.blogCategoriesTimeCache ? state.blogCategoriesTimeCache : null;
  },
  getBlogCategory: (state) => (id) => {
    return state.categories[id] ? state.categories[id]: null;
  },
  getBlogsByCategory: (state) => {
    return state.blogsByCategory;
  },
  getCurrentPaginations(state) {
    return state.paginations;
  }
};

export const actions = {
  getBlogs({getters}) {
    return getters.getBlogs();
  },
  async getBlogFromStore({getters, dispatch, rootState}, {id, page}) {
    let blog = getters.getBlog(id);

    if (blog !== null) {
      if ((this.$dayjs().diff(this.$dayjs(blog.time_cache), 'second') < parseInt(rootState.cacheTime))) {
        return blog;
      }
    }

    return await dispatch('fetchBlog', {
      id,
      page});
  },
  async fetchBlog({commit}, {id, page}) {
    let api_result = await this.$axios.get(`/cms/post/v1/${id}/`);
    let data = api_result.data;
    if (data.error === true) {
      return null;
    }

    data.time_cache = this.$dayjs();
    data.page = page;

    commit('addBlog', data);

    return data;
  },
  async getBlogCategoryFromStore({getters, dispatch, rootState}, {id}) {
    let category = getters.getCategory(id);
    if (category !== null) {
      if ((this.$dayjs().diff(this.$dayjs(category.time_cache), 'second') < parseInt(rootState.cacheTime))) {
        return category;
      }
    }

    return await dispatch('fetchBlogCategory', {id});
  },
  async fetchBlogCategory({getters, commit}, {id}) {
    let api_result = await this.$axios.get(`/cms/post/v1/${id}/`);
    let data = api_result.data;
    if (data.error === true) {
      return null;
    }

    data.time_cache = this.$dayjs();

    commit('addCategory', data);

    return getters.getCategory(id);
  },
  async fetchBlogCategoriesFromStore({state, commit, rootState}) {
    if (state.blogCategoriesTimeCache !== null) {
      if ((this.$dayjs().diff(this.$dayjs(state.blogCategoriesTimeCache), 'second') < parseInt(rootState.cacheTime))) {
        return state.blogCategories;
      }
    }

    let api_result = await this.$axios.get(`/cms/blog/v1/categories`);
    let data = api_result.data;
    if (data.error === true) {
      return state.blogCategories;
    }

    Object.keys(api_result.data).forEach(async index => {
      let catData = api_result.data[index];
      await commit('addToBlogCategory', {
        id: api_result.data[index].id,
        data: catData
      });
    });

    let timeCacheData = {};
    timeCacheData.time_cache = this.$dayjs();
    await commit('addToBlogCategoriesTimeCache', timeCacheData);

    return state.blogCategories;
  }
};
