const DEFAULT_ADDRESS = null;
const DEFAULT_POSTCODE = null;

export const state = () => ({
  deliveryAddress: DEFAULT_ADDRESS,
  stockAvailabilityCheckPostcode: DEFAULT_POSTCODE,
});

export const mutations = {
  saveDeliveryAddress(state, payload) {
    state.deliveryAddress = payload;
  },
  saveStockAvailabilityCheckPostcode(state, payload) {
    state.stockAvailabilityCheckPostcode = payload;
  }
}

export const getters = {
  deliveryAddress: state => {
    return state.deliveryAddress;
  },
  stockAvailabilityCheckPostcode: state => {
    return state.stockAvailabilityCheckPostcode;
  }
}

export const actions = {
  setDeliveryAddress: ({ commit }, address) => {
    commit('saveDeliveryAddress', address);
  },
  resetDeliveryAddresss: ({ commit }) => {
    commit('saveDeliveryAddress', DEFAULT_ADDRESS);
  },

  setStockAvailabilityCheckPostcode: ({ commit }, postcode) => {
    commit('saveStockAvailabilityCheckPostcode', postcode);
  },
  resetStockAvailabilityCheckPostcode: ({ commit }) => {
    commit('saveStockAvailabilityCheckPostcode', DEFAULT_POSTCODE);
  }
}
