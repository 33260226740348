import Vue from 'vue';

Vue.directive('fade-on-view', {
  inserted: function(el, binding) {
    el.classList.add('fade-content');
    el.classList.add(binding.value); // Binding fade in direction
    if (binding.modifiers.delay) {
      el.classList.add('has-delay');
    }
    const func = function() {
      const bounding = el.getBoundingClientRect();
      if (bounding.top - window.innerHeight + 300 <= 0) {
        if (!el.classList.contains('show')) {
          el.classList.add('show');
          window.removeEventListener('load', func);
          window.removeEventListener('scroll', func);
        }
      }
    };
    window.addEventListener('load', func);
    window.addEventListener('scroll', func);
  }
});
