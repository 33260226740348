import { STORE_DIVIDER, STORE_NAME } from './configSettings';
import utils from './persist-utils';

const PERSIST_KEY = 'persist-history';

export default class PersistHistory {
  application = STORE_NAME;
  name;

  constructor(name, storage, maxSize) {
    this.name = name;
    this.storage = storage;
    this.maxSize = maxSize;
  }

  clearHistory() {
    const history = this._getHistoryFromStorage();

    for (const id of history) {
      this.storage.removeItem(id);
    }

    this.storage.setItem(this._historyKey(), []);
  }

  getHistory() {
    const history = this._getHistoryFromStorage();
    return history;
  }

  removeDataFromKey(key) {
    return key.split(STORE_DIVIDER).pop();
  }

  remove(stateKey, key) {
    const storageKey = utils.generateStorageKey(
      this.application,
      this.name,
      stateKey,
      key
    );

    if (!this.storage) {
      return undefined;
    }

    let history = this._getHistoryFromStorage();

    if (this._isAlreadyInHistory(history, storageKey)) {
      history = this._removeFromHistory(history, storageKey);
    } else if (history.length >= this.maxSize) {
      const unshifted = history[0];
      history.shift();
      this.storage.removeItem(unshifted);
    }

    this.storage.setItem(this._historyKey(), history);

    return history;
  }

  removeAndPush(stateKey, key) {
    const storageKey = utils.generateStorageKey(
      this.application,
      this.name,
      stateKey,
      key
    );

    if (!this.storage) {
      return undefined;
    }

    let history = this._getHistoryFromStorage();

    if (this._isAlreadyInHistory(history, storageKey)) {
      history = this._removeFromHistory(history, storageKey);
    } else if (history.length >= this.maxSize) {
      const unshifted = history[0];
      history.shift();
      this.storage.removeItem(unshifted);
    }

    history.push(storageKey);
    this.storage.setItem(this._historyKey(), history);

    return history;
  }

  _historyKey() {
    return utils.generateStorageKey(this.application, PERSIST_KEY, this.name);
  }

  _getHistoryFromStorage() {
    const value = this.storage.getItem(this._historyKey());

    if (!value) {
      return [];
    }

    if (typeof value !== 'object') {
      return [];
    }

    if (value.length === 0) {
      return [];
    }

    return value;
  }

  _isAlreadyInHistory(history, storageKey) {
    return history.includes(storageKey);
  }

  _removeFromHistory(history, storageKey) {
    const index = history.indexOf(storageKey);

    if (index === -1) {
      return history;
    }

    history.splice(index, 1);

    return history;
  }
}
