/**
 * Create an order with Zip Payment
 * All our payment methods trigger the same order creation workflow,
 * but have individual endpoints.
 *
 * This method creates an Order before payment is captured, using
 * Zip as the chosen payment gateway.
 * @param {Object} $axios - axios instance
 * @param {Object} payload - data passed from store
 * @param {String} clientIp - ip of this users session
 * @returns {Object} - Zip specific data from order creation
 */
async function submitCreateOrderRequest($axios, payload, clientIp) {
  let response;
  try {
    let config = {
      headers: {
        CLIENT_IP: clientIp,
      },
      timeout: 60_000,
    };

    response = await $axios.post('/orders/order/zip', payload, config);
  } catch (e) {
    response = {
      error: true,
      data: {
        error: new Error(e),
      },
    };
    return response;
  }

  if (!response || !response?.data) {
    if (response.error) response.error = true;

    return response;
  }

  return response.data;
}

/**
 * Verify an order with Zip Payment
 * User has successfully gone to Zip Checkout portal and approved
 * a payment.
 *
 * This method retrieves the customers order and checkout session details,
 * verifies they are ready to receive payment, captures it, and
 * updates the status of the customers order.
 *
 * on success, the users are redirected to the thank you page
 * @param {Object} $axios - axios instance
 * @param {Object} payload - data passed from store
 * @param {String} clientIp - ip of this users session
 * @returns {Object} - Zip specific data from order creation
 */
async function submitConfirmOrderRequest($axios, payload, clientIp) {
  let response;
  try {
    let config = {
      headers: {
        CLIENT_IP: clientIp,
      },
      timeout: 60_000,
    };

    response = await $axios.post(
      '/orders/payments/zip/confirm',
      payload,
      config
    );
  } catch (e) {
    response = {
      error: true,
      data: {
        error: e,
        errorCode: 'server_error',
      },
    };

    return response;
  }

  if (!response || !response?.data) {
    if (response.error) response.error = true;

    return response;
  }

  return response.data;
}

/**
 * Determine if the response returned from the Zip
 * create order request is valid, and contains required values
 * @param {*} result - response object from backend create order request
 * @return {Boolean} - if valid response, return true
 */
function isValidCreateOrderResponse(result) {
  if (!result?.data?.zip) return false;

  // Required Fields
  if (!result.data.zip.checkoutUri) return false;
  if (!result.data.zip.checkoutId) return false;

  return true;
}

/**
 * Determine if the response returned from the Zip
 * confirm order request is valid, and contains required values
 * @param {*} result - response object from backend create order request
 * @return {Boolean} - if valid response, return true
 */
function isValidConfirmOrderResponse(result) {
  if (!result?.data) return false;

  // Required Fields
  if (result?.data?.id) return true;
  return false;
}

export default {
  submitCreateOrderRequest,
  isValidCreateOrderResponse,
  submitConfirmOrderRequest,
  isValidConfirmOrderResponse,
};
