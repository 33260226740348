//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import autoLoginMixins from '~/mixins/autoLogin';

export default {
  mixins: [autoLoginMixins],
  data() {
    return {
      title: 'Overview',
      showSidebarMobile: false,
      showChinbar: true,
      isLoggedIn: false,
      isLoaded: false,
    };
  },
  created() {
    // TODO: do these need to be unhooked or removed in any way?
    this.$nuxt.$on('title', data => {
      this.title = data;
    });
  },
  beforeMount() {
    this.updateIsSearchBarVisible(true);
    this.updateIsMyAccountVisible(true);
  },
  async mounted() {
    this.isLoaded = false;
    this.isLoggedIn = this.getIsLoggedIn;

    if (!this.isLoggedIn) {
      const success = await this.isRefreshAutoLoginSuccess();

      if (success) {
        this.isLoggedIn = success;
        this.isLoaded = true;
        return;
      } else {
        this.$router.push({
          path: '/my-account/link-login/',
          query: {
            redirect: this.$route.query.redirect,
          },
        });
      }
    }
    this.isLoaded = true;
  },
  methods: {
    ...mapActions({
      updateIsSearchBarVisible: 'layout/updateIsSearchBarVisible',
      updateIsMyAccountVisible: 'layout/updateIsMyAccountVisible',
    }),
  },
};
