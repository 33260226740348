//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
export default {
  components: {
    'inner-image-zoom': InnerImageZoom,
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    showPlaceholder: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
      default: undefined,
    },
    overrideAlt: {
      default: undefined,
      type: String,
    },
    role: {
      default: undefined,
      type: String,
    },
  },
  data() {
    return {
      isFullyLoaded: false,
    };
  },
  computed: {
    sources() {
      if (!this.media || this.media.type !== 'image') return [];
      let sources = this.media.formats.map(format => ({
        ext: format.ext,
        type: format.ext === 'webp' ? 'image/webp' : this.media.mimeType,
        srcSet: format.sizes.map(
          ([, imageWidth, url]) => `${url} ${imageWidth}w`
        ),
        sizes: format.sizes.map(([deviceWidth, imageWidth], idx) =>
          idx === format.sizes.length - 1
            ? `${imageWidth}px`
            : `(max-width: ${deviceWidth}px) ${imageWidth}px`
        ),
      }));

      return sources.slice().sort(format => (format.ext === 'webp' ? -1 : 1));
    },
  },
  methods: {
    imgLoaded() {
      this.isFullyLoaded = true;
    },
  },
};
