export const INVOICE_URL = '/users';
export const FETCH_BATCH_SZIE_IN_DAY = 90; // days

export const api = {
  async getInvoices($axios, params) {
    let response;
    try {
      response = await $axios.get(
        INVOICE_URL + '/details/invoice/list',
        params
      );
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      return undefined;
    }

    const data = response.data;

    if (!data) {
      return undefined;
    }

    return data.data;
  },
  async getInvoiceDetails($axios, params) {
    let response;

    try {
      response = await $axios.get(INVOICE_URL + '/details/invoice/details', {
        params,
      });
    } catch (error) {
      return {
        hasError: true,
        error,
      };
    }

    if (!response) {
      return {
        hasError: true,
        error: new Error('Unknown Error'),
      };
    }

    const data = response.data;
    if (data.error) {
      return {
        hasError: true,
        error: new Error(data.msg),
      };
    }

    return {
      hasError: false,
      data: data.data,
    };
  },
  async sendInvoiceEmail($axios, payload) {
    let response;
    try {
      response = await $axios.post(INVOICE_URL + '/invoice/email', payload);
    } catch (e) {
      console.error(e);
      return e;
    }

    return response;
  },
  async sendInvoiceDownload($axios, payload) {
    let response;
    try {
      response = await $axios.post(INVOICE_URL + '/invoice/stream', payload);
    } catch (e) {
      console.error(e);
      return e;
    }

    return response.data;
  },
};

export const actions = {
  async fetchNextBatchOfInvoices({ commit, state }) {
    let toDate = new Date();
    if (state.lastFetchFromDate) {
      toDate = new Date(
        state.lastFetchFromDate.getTime() - 24 * 60 * 60 * 1000
      );
    }

    const fromDate = new Date(
      toDate.getTime() - FETCH_BATCH_SZIE_IN_DAY * 24 * 60 * 60 * 1000
    );
    const toDateStr = `${toDate.getFullYear()}-${toDate.getMonth() +
      1}-${toDate.getDate()}`;
    const fromDateStr = `${fromDate.getFullYear()}-${fromDate.getMonth() +
      1}-${fromDate.getDate()}`;
    const params = {
      batchSize: 0,
      batchPage: 'last',
      fromDate: fromDateStr,
      toDate: toDateStr,
    };
    const invoices = await api.getInvoices(this.$axios, { params });
    if (invoices) {
      invoices.sort((a, b) => {
        let aDate = new Date(a.invoiceDate).getTime();
        let bDate = new Date(b.invoiceDate).getTime();
        return bDate - aDate;
      });
    }
    commit('addNextBatchOfInvoices', { invoices, fromDate });
  },
  async getInvoices({ commit }, payload) {
    const params = { params: payload };
    let result = await api.getInvoices(this.$axios, params);

    commit('setInvoices', result);
    return result;
  },
  async getInvoiceDetails({ commit }, params) {
    // Retrieve invoice details by custId and invoiceNo
    let result = await api.getInvoiceDetails(this.$axios, params);
    if (!result.hasError) {
      commit('addInvoiceDetails', result.data);
      return result;
    }
    return result;
  },
  addCustomerEmail({ commit }, params) {
    commit('setCustomerEmail', params);
  },
  async selectInvoiceForPayment({ commit, dispatch, state }, params) {
    commit('resetFetchingSelectedInvoiceStatus');
    commit('setFetchingSelectedInvoiceStatusToFetching');

    // only fetch the invoice details if it is not already fetched
    if (
      !state.invoiceDetails.find(
        invDetails =>
          invDetails.invoiceNumber === params.invoiceNo &&
          invDetails.invoiceSuffix === params.invoiceSuffix
      )
    ) {
      const result = await dispatch('getInvoiceDetails', params);
      if (result.hasError) {
        commit('setFetchingSelectedInvoiceStatusError', result.error);
      }
    }

    commit('addSelectedInvoicesForPayment', [
      {
        invoiceNo: params.invoiceNo,
        invoiceSuffix: params.invoiceSuffix,
      },
    ]);
    commit('setFetchingSelectedInvoiceStatusToDone');
  },
  async unselectOneInvoiceForPayment({ commit }, { invoiceNo, invoiceSuffix }) {
    commit('removeSelectedInvoicesForPayment', [{ invoiceNo, invoiceSuffix }]);
  },
  async sendInvoiceEmail(_, payload) {
    let result = await api.sendInvoiceEmail(this.$axios, payload);

    return result;
  },
  async sendInvoiceDownload(_, payload) {
    let result = await api.sendInvoiceDownload(this.$axios, payload);

    return result;
  },

  clearSelectedInvoiceForPayment({ commit }) {
    commit('clearSelectedInvoiceForPayment');
  },
};

export const mutations = {
  addNextBatchOfInvoices(state, { invoices, fromDate }) {
    if (invoices) {
      state.invoices.push(...invoices);
    }
    state.lastFetchFromDate = fromDate;
  },
  setInvoices(state, payload) {
    state.invoices = payload;
  },
  addInvoiceDetails(state, payload) {
    const index = state.invoiceDetails.findIndex(
      item =>
        item.invoiceNumber === payload.invoiceNumber &&
        item.invoiceSuffix === payload.invoiceSuffix
    );
    if (index === -1) {
      state.invoiceDetails.push(payload);
    } else {
      state.invoiceDetails[index] = payload;
    }
  },
  setCustomerEmail(state, payload) {
    state.customerEmail = payload;
  },
  addSelectedInvoicesForPayment(state, payload) {
    payload.forEach(({ invoiceNo, invoiceSuffix }) => {
      if (
        state.selectedInvoicesForPayment.find(
          invoice =>
            invoice.invoiceNo === invoiceNo &&
            invoice.invoiceSuffix === invoiceSuffix
        )
      ) {
        return;
      }

      state.selectedInvoicesForPayment.push({ invoiceNo, invoiceSuffix });
    });
  },
  // Remove deselected invoice from invoice payments and invoice details
  removeSelectedInvoicesForPayment(state, payload) {
    const filteredInvoiceNos = state.selectedInvoicesForPayment.filter(
      invoice =>
        !payload.find(
          item =>
            item.invoiceNo === invoice.invoiceNo &&
            item.invoiceSuffix === invoice.invoiceSuffix
        )
    );

    state.selectedInvoicesForPayment = filteredInvoiceNos;
  },
  setFetchingSelectedInvoiceStatusToFetching(state) {
    state.fetchingSelectedInvoiceStatus.isFetching = true;
  },

  setFetchingSelectedInvoiceStatusToDone(state) {
    state.fetchingSelectedInvoiceStatus.isFetching = false;
  },

  setFetchingSelectedInvoiceStatusError(state, error) {
    state.fetchingSelectedInvoiceStatus.hasError = true;
    state.fetchingSelectedInvoiceStatus.error = error;
  },

  resetFetchingSelectedInvoiceStatus(state) {
    state.fetchingSelectedInvoiceStatus.isFetching = false;
    state.fetchingSelectedInvoiceStatus.hasError = false;
    state.fetchingSelectedInvoiceStatus.error = null;
  },

  clearSelectedInvoiceForPayment(state) {
    state.selectedInvoicesForPayment = [];
  },
};

export const getters = {
  getLastFetchFromDate: state => {
    return state.lastFetchFromDate;
  },
  getInvoices: state => {
    return state.invoices;
  },
  getInvoiceDetail: state => {
    return state.invoiceDetails;
  },
  getSelectedInvoicesForPayment: state => {
    return state.invoiceDetails.filter(invoiceDetail =>
      state.selectedInvoicesForPayment.find(
        inv =>
          inv.invoiceNo === invoiceDetail.invoiceNumber &&
          inv.invoiceSuffix === invoiceDetail.invoiceSuffix
      )
    );
  },
  getFetchingSelectedInvoiceStatus: state => {
    return state.fetchingSelectedInvoiceStatus;
  },
  getCustomerEmail: state => {
    return state.customerEmail;
  },
};
export const state = () => ({
  // this, along with the FETCH_BATCH_SIZE_IN_DAY will be used
  // to calculate the fromDate and toDate of the next fetch
  // fromtDate = lastFetchFromDate - FETCH_BATCH_SIZE_IN_DAY
  // toDate = lastFetchFromDate - 1
  lastFetchFromDate: null,
  invoices: [],
  customerEmail: '',
  invoiceDetails: [],
  selectedInvoicesForPayment: [],
  fetchingSelectedInvoiceStatus: {
    isFetching: false,
    hasError: false,
    error: null,
  },
});
