import { render, staticRenderFns } from "./MegaMenu.vue?vue&type=template&id=0a462b8e&scoped=true&"
import script from "./MegaMenu.vue?vue&type=script&lang=js&"
export * from "./MegaMenu.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenu.vue?vue&type=style&index=0&id=0a462b8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a462b8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WordpressBanner: require('/home/node/frontend/components/WordpressBanner.vue').default,Media: require('/home/node/frontend/components/Media.vue').default})
