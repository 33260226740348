//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'XLink',
  props: {
    url: {
      required: true,
      type: String,
    },
    target: {
      default: '_self',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    callBack: {
      default: null,
      type: Function,
    },
  },
  data() {
    return {
      isSameDomain: undefined,
      trimmedUrl: undefined,
    };
  },
  mounted() {
    if (this.url) {
      this.isSameDomain = this.checkIsSameDomain(this.url);
      this.trimmedUrl = this.trimUrl(this.url);
    }
  },
  methods: {
    trimUrl(url) {
      if (!url) {
        return url;
      }
      if (!this.checkIsSameDomain(url)) {
        return url;
      }

      let hostname = this.$config.baseUrl.replace(/^https?:\/\//, '');

      // LOCAL DEV - Force remove the dev.gcp.bowens.com.au portion of the URL
      if (this.$config.appEnv === 'local') {
        hostname = 'dev.gcp.bowens.com.au';
      }

      const relativePath = url.split(hostname).slice(-1)[0];

      return relativePath;
    },
    checkIsSameDomain(url) {
      if (!url) {
        return false;
      }
      if (url.startsWith('/')) {
        return true;
      }
      const urlWithoutPrefix = url.replace(/^https?:\/\//, '');

      let hostname = this.$config.baseUrl.replace(/^https?:\/\//, '');

      // LOCAL DEV - Force remove the dev.gcp.bowens.com.au portion of the URL
      if (this.$config.appEnv === 'local') {
        hostname = 'dev.gcp.bowens.com.au';
      }

      if (urlWithoutPrefix.startsWith(hostname)) {
        return true;
      }
      if (urlWithoutPrefix.startsWith(`cms.${hostname}`)) {
        return true;
      }
      return false;
    },
    handleClick(e) {
      if (this.callBack) {
        this.callBack();
      }
      this.$emit('click', e);
    },
  },
};
