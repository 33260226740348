const StorageMixins = {
  methods: {
    getFromStorage(label, key) {
      const storage = this.$ls.get(label);
      if (storage && storage[key] !== undefined) {
        return storage[key];
      }
      return null;
    },
    addToStorage(label, key, data) {
      try {
        let storage = this.$ls.get(label);
        if (!storage) {
          storage = {};
        }
        let newData = {};
        newData[key] = data;
        storage = Object.assign(storage, newData); // Backwards compatiability
        this.$ls.set(label, storage, parseFloat(this.$config.cacheTime) * 1000);
        return true;
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    removeFromStorage(label, key) {
      try {
        let storage = this.$ls.get(label);
        delete storage[key];
        return key;
      } catch (err) {
        console.log(err);
      }
      return false;
    },
    removeStorage(label) {
      try {
        this.$ls.remove(label);
        return label;
      } catch (err) {
        console.log(err);
      }
      return false;
    },
    syncToStore(label, key, data) {
      if (label && key && data) {
        this.$store.dispatch(label + '/syncToStore', {
          key: key,
          data: data
        });
      }
    }
  }
};

export default StorageMixins;
