import Vue from 'vue';
import { mapActions } from 'vuex';

if (!Vue.__hydrate__) {
  Vue.__hydrate__ = true;
  Vue.mixin({
    methods: {
      ...mapActions({
        hydrateUsers: 'users/hydrate',
        hydrateCart: 'cart/hydrate',
        hydrateCheckout: 'checkout/hydrate',
        hydrateJoblist: 'joblist/hydrate',
        hydratePricing: 'pricing/hydrate',
      }),
    },
    async beforeMount() {
      await Promise.all([
        this.hydrateUsers(),
        this.hydrateCart(),
        this.hydrateJoblist(),
        this.hydrateCheckout(),
        this.hydratePricing(),
      ]);
    },
  });
}
