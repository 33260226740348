//
//
//

import { mapGetters, mapActions } from 'vuex';
import { autocomplete } from '@algolia/autocomplete-js';
import insightsClient from 'search-insights';
import algolia from '@/plugins/algolia-utilities';

export default {
  props: {
    placeholder: {
      type: String,
      default: 'Start typing to search our range',
    },
  },
  data() {
    return {
      appId: null,
      appKey: null,
      autocompleteInstance: undefined,
    };
  },
  computed: {
    ...mapGetters({
      getIsLoggedIn: 'users/getIsLoggedIn',
      getContact: 'users/getContact',
    }),
    query() {
      if (this.$router.currentRoute.query) {
        return this.$router.currentRoute.query;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      getAlgoliaIndex: 'search/getAlgoliaIndex',
      getAlgoliaSearchClient: 'search/getAlgoliaSearchClient',
    }),
  },
  async mounted() {
    let self = this;
    const appConfig = {
      applicationId: this.$config.algoliaApplicationId,
      key: this.$config.algoliaSearchKey,
    };

    const searchClient = await this.getAlgoliaSearchClient(appConfig);
    let token = 'user-0';
    if (this.getIsLoggedIn) token = `user-${this.getContact.userId}`;

    insightsClient('init', {
      appId: appConfig.applicationId,
      apiKey: appConfig.key,
    });
    insightsClient('setUserToken', token);

    const config = this.$config.autocompleteConfig;
    const algoliaInsightsPlugin = algolia.plugins.insights(insightsClient);
    const cmsPageSearchPlugin = algolia.plugins.cmsPages(searchClient, config);
    const querySuggestionsPlugin = algolia.plugins.querySuggestions(
      searchClient,
      config
    );
    const productSearchPlugin = algolia.plugins.products(searchClient, config);
    const recentSearchesPlugin = algolia.plugins.recentSearches(config);

    this.autocompleteInstance = autocomplete({
      container: '#header_autocomplete',
      classNames: {
        panel: 'aa-header-panel',
      },
      openOnFocus: true,
      placeholder: this.placeholder,
      debug: false,
      detachedMediaQuery: '(max-width: 1180px)',
      plugins: [
        algoliaInsightsPlugin,
        cmsPageSearchPlugin,
        productSearchPlugin,
        querySuggestionsPlugin,
        recentSearchesPlugin,
      ],
      reshape({ sourcesBySourceId }) {
        const { cmsPages, products, ...rest } = sourcesBySourceId;

        return [
          Object.values(rest),
          algolia.group({
            products,
            config,
          }),
          cmsPages,
        ];
      },
      onSubmit({ state }) {
        self.$router.push(`/search/?query=${state.query}`);
      },
    });
  },
  beforeUnmount() {
    this.autocompleteInstance = undefined;
  },
};
