import { api } from '../order';

/**
 * Update Stripe Payment Intent via API
 * @param {Object} $axios An instance of axios
 * @param {Object} payload Object containing the Stripe Payment Intent ID to update
 * @param {String} clientIp The IP address of the user
 * @returns {undefined|Object} undefined on error, object on success
 */
export async function updateStripePaymentIntentApi($axios, payload, clientIp) {
  let config = {
    headers: {
      CLIENT_IP: clientIp,
    },
    timeout: 60_000, // 60 seconds
  };
  let response;

  try {
    response = await $axios.patch('/orders/order/stripe/payment-intent', payload, config);
  } catch (e) {
    console.error(e);
  }

  if (!response) {
    return undefined;
  }

  const data = response.data;

  if (!data) {
    return undefined;
  }

  return data;
}

/**
 * Vuex Action for requesting a Stripe Payment Intent
 * @param {Object} _ Vuex Context
 * @param {Object} payload Object containing the Stripe Payment Intent ID to update
 * @returns {undefined|Object} undefined on error, object on success
 */
export async function updateStripePaymentIntentAction(_, payload) {
  const cIp = payload.clientIp;
  delete payload.clientIp;
  let result = await api.updateStripePaymentIntentApi(this.$axios, payload, cIp);

  return result;
}
