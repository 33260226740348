//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      cmsSettings: 'settings/cms',
    }),
  },
};
