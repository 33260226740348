import Vue from 'vue';

export const state = () => ({
  settings: {},
  branches: {},
  branchList: undefined,
  branchSlugs: {},
});

export const mutations = {
  setBranchList(state, data) {
    Vue.set(state, 'branchList', data);
  },
  addToBranches(state, data) {
    Vue.set(state.branches, data.id, data);
  },
  addSlugIdPairs(state, data) {
    Vue.set(state.branchSlugs, data.slug, data.id);
  },
  syncFromStorage(state, data) {
    Vue.set(state, data.id, data.data);
  },
};

export const getters = {
  getBranches: state => {
    return state.branches;
  },
  getBranchList: state => {
    return state.branchList;
  },
  getById: state => id => {
    if (typeof state.branchList === 'undefined') {
      return undefined;
    }

    for (const branch of state.branchList) {
      if (branch.id === id) return branch;
    }

    return undefined;
  },
  getByIdBranchList: state => id => {
    const result = state.branchList.filter(branch => {
      return branch.id === id;
    });

    return result[0];
  },
  getBySlug: state => slug => {
    const id = state.branchSlugs[slug];
    if (id) {
      return state.branches[id] ? state.branches[id] : null;
    }
    return null;
  },
  getIdBySlug: state => slug => {
    return state.branchSlugs[slug];
  },
  getBranchSettings: state => {
    return state.settings;
  },
};

export const actions = {
  syncToStore({ commit }, { key, data }) {
    commit('syncFromStorage', {
      id: key,
      data: data,
    });
  },
  async getBranchId({ getters, commit }, { slug }) {
    let id = getters.getIdBySlug(slug);
    if (!id) {
      const api_result = await this.$axios.get(`/sitemap/stores/${slug}`);
      let response = api_result.data;
      if (response.type === 'branch') {
        commit('addSlugIdPairs', {
          slug: slug,
          id: response.id,
        });
        id = response.id;
      } else {
        return null;
      }
    }
    return id;
  },
  async fetchBranchList({ getters, commit }) {
    let branchList = getters.getBranchList;

    if (branchList) {
      return branchList;
    }

    const api_result = await this.$axios.get(`/branches`);
    let response = api_result.data;
    if (response.error === true) {
      return null;
    }
    commit('setBranchList', response.data);
    return getters.getBranchList;
  },
  async fetchBranch({ dispatch, commit }, { slug }) {
    try {
      const id = await dispatch('getBranchId', { slug });
      const api_result = await this.$axios.get(`/branches/${id}`);
      let response = api_result.data;
      if (response.error) {
        return null;
      }

      commit('addToBranches', response.data);
      commit('addSlugIdPairs', {
        slug: slug,
        id: response.data.id,
      });

      return response.data.id;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  },
  async getCurrentBranch({ getters, dispatch }, { id }) {
    if (getters.getById(id) === undefined) {
      await dispatch('fetchBranch', { slug: id });
    }
    return getters.getById(id);
  },
  async fetchBranchById({ getters, dispatch }, { id }) {
    await dispatch('fetchBranchList');
    return getters.getById(id);
  },
};
