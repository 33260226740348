//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StorageMixins from '~/mixins/storage';
import { mapGetters, mapActions } from 'vuex';

export default {
  mixins: [StorageMixins],
  props: {
    id: {
      type: String,
      required: true,
    },
    format: {
      default: 'default',
    },
    source: {
      default: 'cms',
    },
    showPlaceholder: {
      default: false,
      type: Boolean,
    },
    lazyLoad: {
      default: true,
      type: Boolean,
    },
    routerChanged: {
      type: Boolean,
      default: false,
    },
    debug: {
      default: false,
      type: Boolean,
    },
    overrideAlt: {
      default: undefined,
      type: String,
    },
    role: {
      default: undefined,
      type: String,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    isMainProductImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: !this.lazyLoad,
    };
  },
  watch: {
    routerChanged(val1, val2) {
      if (val1 !== val2) {
        this.handleScroll();
      }
    },
  },
  computed: {
    ...mapGetters({
      getMedia: 'media/getMedia',
    }),
    media() {
      return this.getMedia({
        source: this.source,
        id: this.id,
        format: this.format,
      });
    },
  },
  methods: {
    ...mapActions({
      fetchMedia: 'media/getMedia',
    }),
    handleScroll() {
      const offsetHeight = 300;
      if (!this.loaded && this.isInViewport(this.$el, offsetHeight)) {
        window.removeEventListener('scroll', this.handleScroll);
        this.loaded = true;
        this.$emit('loaded');
      }
    },
    isInViewport(elem, offset = 0) {
      if (elem) {
        var bounding = elem.getBoundingClientRect();
        return bounding.top - offset < window.innerHeight;
      }
      return false;
    },
    async getMediaResource() {
      if (!this.id) return;
      await this.fetchMedia({
        source: this.source,
        id: this.id,
        format: this.format,
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  serverPrefetch() {
    if (!this.lazyLoad) {
      return this.getMediaResource();
    }
  },
  async mounted() {
    if (!this.media) {
      await this.getMediaResource();
    }
    this.handleScroll();
  },
};
