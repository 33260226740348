import { openDB } from 'idb';

export const DB_NAME = 'users';
export const OBJECT_STORE = 'token_expirations';

export const HYDRATION_STATUS = {
  pending: 'pending',
  inProgress: 'in-progress',
  completed: 'completed',
};

export const TOKEN_STATUS = {
  needs_refresh: 'needs_refresh',
  invalid: 'invalid',
  valid: 'valid',
};

export default {
  async checkDbExists() {
    try {
      const databases = await indexedDB.databases();
      if (databases?.length <= 0) return false;
      return databases.map(db => db.name).includes(DB_NAME);
    } catch (e) {
      return false;
    }
  },
  async getTokenExpirations() {
    try {
      if (!(await this.checkDbExists())) {
        return;
      }

      const db = await openDB(DB_NAME);

      let refreshTokenExpires = await db.get(
        OBJECT_STORE,
        'refreshTokenExpires'
      );
      let accessTokenExpires = await db.get(OBJECT_STORE, 'accessTokenExpires');

      if (!refreshTokenExpires || !accessTokenExpires) {
        return;
      }

      refreshTokenExpires = refreshTokenExpires.value;
      accessTokenExpires = accessTokenExpires.value;

      return {
        refreshTokenExpires,
        accessTokenExpires,
      };
    } catch (e) {
      console.log(e);
      return;
    }
  },
  async clearTokenExpirations() {
    try {
      if (!(await this.checkDbExists())) {
        return undefined;
      }

      const db = await openDB(DB_NAME);

      await db.clear(OBJECT_STORE);
    } catch (e) {
      console.log(e);
      return;
    }
  },
  getTokenStatus(dayjs, tokenExpirations) {
    const now = dayjs();
    const { accessTokenExpires, refreshTokenExpires } = tokenExpirations;

    if (dayjs(accessTokenExpires).isAfter(now.add('1', 'minute'))) {
      return TOKEN_STATUS.valid;
    }

    if (dayjs(refreshTokenExpires).isBefore(now)) {
      return TOKEN_STATUS.invalid;
    }

    return TOKEN_STATUS.needs_refresh;
  },
};
