import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=21239f0f&scoped=true&"
import script from "./ErrorPage.vue?vue&type=script&lang=js&"
export * from "./ErrorPage.vue?vue&type=script&lang=js&"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=21239f0f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21239f0f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WordpressBanner: require('/home/node/frontend/components/WordpressBanner.vue').default,TrendingProductsCarousel: require('/home/node/frontend/components/cms-modules/TrendingProductsCarousel.vue').default,BenefitsAndTradeComponent: require('/home/node/frontend/components/pages/BenefitsAndTradeComponent.vue').default})
