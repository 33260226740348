import api from './api';
import utils from './utils';
import { TOKEN_STATUS, HYDRATION_STATUS } from './utils';

export default {
  async rememberMeLogin({ commit }, payload) {
    const response = await api.checkRememberMe(this.$axios, payload);

    if (response && !response.error) {
      const userDetails = {
        email: payload.email,
        ...response,
      };

      commit('setLoginReturn', userDetails);

      return userDetails;
    }

    return response;
  },
  async magicLinkLogin({ commit }, payload) {
    const response = await api.magicLinkAuthenticateLogin(this.$axios, payload);

    if (response && !response.error) {
      const userDetails = {
        email: payload.email,
        ...response,
      };

      commit('setLoginReturn', userDetails);

      return userDetails;
    }

    return response;
  },
  async authenticateLoginCredentials({ commit }, payload) {
    const response = await api.authenticate(this.$axios, payload);

    if (response && !response.error) {
      const userDetails = {
        email: payload.email,
        ...response,
      };

      // Once authenticated, we now store the user email prior to logging in
      localStorage.setItem('user_remember_me_email', payload.email);
      commit('setLoginReturn', userDetails);

      return userDetails;
    }

    return response;
  },
  async validateCompany({ getters, dispatch, commit }, payload) {
    const updatedPayload = {
      ...payload,
      token: getters.getTempCompanyToken.id,
    };

    const response = await api.validateCompany(this.$axios, updatedPayload);

    if (response && !response.error) {
      // const savedCompanyCookie = window.$cookies.get('selectedSessionCompany');

      // if (!savedCompanyCookie) {
      // Instead of storing in session cookies, we swap it to a more solid take
      localStorage.setItem('user_remember_me_company', payload.customerId);
      // window.$cookies.set('selectedSessionCompany', payload.customerId, '0');
      // }

      // Removed forced log out when get details fails, we are betting on FW to always be up
      // In the case of fw failing and we are unable to get user details, we do not want to log
      // them out either as we only want them to be logged out if they manually do so.
      const getDetailsResp = await dispatch('getUserDetails');
      await dispatch('getUserStatementDetails');

      commit('setUserFrameworkDetails', payload);
      await dispatch('updateStoreTokenExpirations');
      return getDetailsResp;
    }

    return response;
  },
  async validateTwoFaCode({ commit, getters }, payload) {
    const updatedPayload = {
      ...payload,
      token: getters.getTempTwoFactorToken.id,
    };

    const response = await api.validateTwoFaCode(this.$axios, updatedPayload);

    if (response && !response.error) {
      commit('setTfaReturn', response);

      return response;
    }

    return response;
  },
  async resendTwoFaCode({ getters }) {
    const response = await api.resendTwoFaCode(
      this.$axios,
      getters.getTempTwoFactorToken.id
    );
    return response;
  },
  async maybeRefeshAccessToken({ commit, getters, dispatch }) {
    if (getters.getIsRefreshing) {
      return;
    }

    commit('setIsRefreshing', true);
    const tokenExpirations = await utils.getTokenExpirations();

    if (!tokenExpirations) {
      commit('setIsRefreshing', false);
      return;
    }

    const tokenStatus = utils.getTokenStatus(this.$dayjs, tokenExpirations);
    if (
      tokenStatus === TOKEN_STATUS.valid ||
      tokenStatus === TOKEN_STATUS.invalid
    ) {
      // Removed forced logout even when token is invalid, we do not want to
      // log a user out unless they manually wished to do so. Refresh login
      // should reissue a new token if a user is refreshed.
      commit('setIsRefreshing', false);
      return;
    }

    // Removed forced logout even when token refresh failed, we do not want to
    // log a user out unless they manually wished to do so. Refresh login
    // should reissue a new token if a user is refreshed.
    const response = await api.refreshToken(this.$axios);
    if (response && response.error && response.error === 'unauthorised') {
      console.log('user cannot be refreshed, generate new token');
    }

    await dispatch('updateStoreTokenExpirations');
    commit('setIsRefreshing', false);
    return response;
  },
  async hydrate({ getters, commit, dispatch }) {
    if (getters.getHydrationStatus !== HYDRATION_STATUS.pending) {
      return;
    }

    commit('updateHydrationStatus', HYDRATION_STATUS.inProgress);

    const tokenExpirations = await utils.getTokenExpirations();

    if (!tokenExpirations) {
      commit('updateHydrationStatus', HYDRATION_STATUS.completed);
      return;
    }

    const tokenStatus = utils.getTokenStatus(this.$dayjs, tokenExpirations);

    if (tokenStatus === TOKEN_STATUS.invalid) {
      // Removed forced logout even when token is invalid, we do not want to
      // log a user out unless they manually wished to do so. Refresh login
      // should reissue a new token if a user is refreshed.
      commit('updateHydrationStatus', HYDRATION_STATUS.completed);
      return;
    }

    if (tokenStatus === TOKEN_STATUS.needs_refresh) {
      const refRes = await api.refreshToken(this.$axios);
      if (!refRes) {
        // Removed forced logout even when token refresh failed, we do not want to
        // log a user out unless they manually wished to do so. Refresh login
        // should reissue a new token if a user is refreshed.
        commit('updateHydrationStatus', HYDRATION_STATUS.completed);
        return;
      }
    }

    // Removed forced log out when get details fails, we are betting on FW to always be up
    // In the case of fw failing and we are unable to get user details, we do not want to log
    // them out either as we only want them to be logged out if they manually do so.
    await dispatch('getUserDetails');
    await dispatch('getUserStatementDetails');

    await dispatch('updateStoreTokenExpirations');
    commit('updateHydrationStatus', HYDRATION_STATUS.completed);
    return;
  },
  async getUserDetails({ commit }) {
    const response = await api.getUserDetails(this.$axios);

    if (response && !response.error) {
      commit('setUserDetails', response);
      return response;
    }

    return response;
  },
  async getUserByEmail(_, params) {
    const response = await api.getUserByEmail(this.$axios, params);

    return response;
  },
  async createNewUser(_, params) {
    const response = await api.createNewGuestUser(this.$axios, params);

    return response;
  },
  async createNewUserContact(_, params) {
    const response = await api.createNewGuestUserContact(this.$axios, params);

    return response;
  },
  async createNewEcommUser(_, params) {
    const response = await api.createNewEcommUser(this.$axios, params);
    return response;
  },
  async getUserStatementDetails({ commit }) {
    const response = await api.getStatementDetails(this.$axios);

    if (response && !response.error) {
      commit('setStatementDetails', response);
      return response;
    }

    return response;
  },
  async getUserBalanceDetails({ commit }) {
    const response = await api.getBalanceDetails(this.$axios);
    if (response && !response.error) {
      commit('setBalanceDetails', response);
      return response;
    }

    return response;
  },
  async logoutUser({ commit, dispatch }) {
    await api.logout(this.$axios);
    localStorage.removeItem('user_remember_me_email');
    localStorage.removeItem('user_remember_me_company');
    window.$cookies.remove('selectedSessionCompany');
    await utils.clearTokenExpirations();
    await dispatch('pricing/bustPriceCache', null, { root: true });
    await dispatch('checkout/clearOrderContactDetails', null, { root: true });
    commit('clear');
  },
  async updateStoreTokenExpirations({ commit }) {
    const tokenExpirations = await utils.getTokenExpirations();

    if (!tokenExpirations) {
      commit('clearTokenExpirations');
      return;
    }

    commit('setTokenExpirations', tokenExpirations);
  },
  async getUserCustomerContacts() {
    // run api call "getCustomerContacts"
    const response = await api.getCustomerContacts(this.$axios);

    // commit customer contacts to store
    if (response && !response.error && response.contacts) {
      return response.contacts;
    }

    return [];
  },
  async updateCustomerContactPermissions(_, payload) {
    const response = await api.updateCustomerContactPermissions(
      this.$axios,
      payload
    );

    return response;
  },
  async createCustomerContactPermissions(_, payload) {
    const response = await api.createCustomerContactPermissions(
      this.$axios,
      payload
    );

    return response;
  },
  async removeCustomerContactPermissions(_, payload) {
    const response = await api.removeCustomerContactPermissions(
      this.$axios,
      payload
    );

    return response;
  },
};
