import { AVAILABILITY } from '~/util/checkout';
import Persist from '~/util/persist';
import Storage from '~/util/storage';
import { version } from '~/package.json';
const storage = process.client ? new Storage(window.localStorage) : undefined;
const persist = new Persist(
  'newCheckout/deliveryPreferences',
  storage,
  version,
  process.client,
  {
    isExpirable: false,
  }
);

export const state = () => ({
  leadTime: {
    item: null,
    itemErp: null,
    days: AVAILABILITY.DEFAULT,
  },
  deliveryBranch: null,
  deliveryCalculations: undefined,
  isCalculatingDelivery: false,
});

export const getters = {
  getLeadTime: state => {
    return state.leadTime;
  },
  getDeliveryBranch: state => {
    return state.deliveryBranch;
  },
  getDeliveryCalculations: state => {
    return state.deliveryCalculations;
  },
  isCalculatingDelivery: state => {
    return state.isCalculatingDelivery;
  }
};

export const mutations = {
  setLeadTime(state, payload) {
    state.leadTime = payload;
    persist.setItem('newCheckout/deliveryPreferences', '', state);
  },
  setDeliveryBranch(state, payload) {
    state.deliveryBranch = payload;

    persist.setItem('newCheckout/deliveryPreferences', '', state);
  },
  setDeliveryCalculations(state, payload) {
    state.deliveryCalculations = payload;
  },
  setIsCalculatingDelivery(state, payload) {
    state.isCalculatingDelivery = payload;
  }
};
