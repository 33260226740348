import { render, staticRenderFns } from "./account.vue?vue&type=template&id=7abc085a&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"
import style0 from "./account.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageBanner: require('/home/node/frontend/components/includes/PageBanner.vue').default,PageHeader: require('/home/node/frontend/components/includes/PageHeader.vue').default,PageHeaderSpacing: require('/home/node/frontend/components/includes/PageHeaderSpacing.vue').default,MyAccountHeader: require('/home/node/frontend/components/my-account/shared/MyAccountHeader.vue').default,BdAnimatedLoadingIcon: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/BdAnimatedLoadingIcon.vue').default,Fourofour: require('/home/node/frontend/components/pages/Fourofour.vue').default,PageFooter: require('/home/node/frontend/components/includes/PageFooter.vue').default,LazyChinBar: function () { return import('/home/node/frontend/components/includes/ChinBar.vue' /* webpackChunkName: "components/includes/ChinBar" */).then(function(m) { return m['default'] || m }) }})
