//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UspDeliverySvg from '~/static/svg/usp-delivery.svg';
import UspEstablishedSvg from '~/static/svg/usp-established.svg';
import UspMoreProductsSvg from '~/static/svg/usp-more-products.svg';
import UspSiteSvg from '~/static/svg/usp-site.svg';

export default {
  components: {
    UspDeliverySvg,
    UspEstablishedSvg,
    UspMoreProductsSvg,
    UspSiteSvg,
  },
};
