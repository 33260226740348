/* eslint-disable */
export default function({ $stackdriver, $axios, $config }) {
  $axios.defaults.timeout = parseInt($config.defaultTimeout);

  // $axios.onRequest(config => {
  //   if (config.url.includes('/cms/banner')) {
  //     config.baseURL = 'http://localhost:3080';
  //   }
  //   return config;
  // });
  // This is used when developing locally to intercept all axios requests and reroute any that are going to the firestore database to go to the dev environment
  // $axios.onRequest(config => {
  //   if (
  //     config.url.includes('/sitemap/') ||
  //     config.url.includes('/product-categories') ||
  //    config.url.includes('/settings') ||
  //    config.url.includes('/cms/') ||
  //    config.url.includes('/media') ||
  //    config.url.includes('/branches') ||
  //    config.url.includes('/products-meta') ||
  //    config.url.includes('/products-erp') ||
  //    config.url.includes('/products-pricing') ||
  //    config.url.includes('/products')
  //   ) {
  //     config.baseURL = 'https://api.dev.gcp.bowens.com.au';
  //   }
  //   return config;
  // });

  // $axios.onRequest(config => {
  //   if (config.url.includes('/users/')) {
  //     config.baseURL = 'http://local.api.dev.gcp.bowens.com.au:3080';
  //   }
  //   return config;
  // });
  // Local dev
  // $axios.onRequest(config => {
  //   if (config.url.includes('/orders/')) {
  //     config.baseURL = 'http://local.api.dev.gcp.bowens.com.au:3080';
  //   }
  //   return config;
  // });

  // $axios.onRequest(config => {
  //   if (config.url.includes('/products-erp/')) {
  //     config.baseURL = 'http://local.api.dev.gcp.bowens.com.au:3080';
  //   }
  //   return config;
  // });

  //   $axios.onRequest(config => {
  //   if (config.url.includes('/branches/')) {
  //     config.baseURL = 'http://local.api.dev.gcp.bowens.com.au:3080';
  //   }
  //   return config;
  // });

  // Uncomment this line to log out all Axios errors
  // $axios.onError(error => {
  //   console.log('Axios: Error Object', {error: error});
  // });

  $axios.interceptors.response.use(
    config => config,
    error => {
      try {
        if ($stackdriver) {
          let code = '';
          if (error?.statusCode) code = error.statusCode;
          else if (error?.response?.data?.errorCode)
            code = error.response.data.errorCode;

          let msg = '';
          if (error?.message) msg = error.message;
          else if (error?.response?.data?.msg) msg = error.response.data.msg;

          let url = '';
          if (error?.config?.url) url = error.config.url;

          $stackdriver.report(`Axios Request ${code} Error: ${msg} (${url})`);
        }
      } catch (e) {
        if ($stackdriver) $stackdriver.report(e);
      }

      return Promise.reject(error);
    }
  );
}
